import {
  SET_PENDING
} from './Login'
import {
  api
} from '../../API/api'
import {
  print_info,
  print_error,
  update_toast,
  print_loading
} from '../../Functions/Toast'
import {
  get_id
} from '../../Functions/Functions'

export const SIGNIN_USER = (value) => { // sauvegarde des éléments de la première page d'inscription (nom, prénom, mail, etc...)
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'SIGNIN_USER'
    })
  }
}

export const SIGNIN_ABONNEMENT = (value) => { // sauvegarde de l'abonnement choisi
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'SIGNIN_ABONNEMENT'
    })
  }
}

export const SIGNIN_UPDATE = (input, value) => { // sauvegarde d'un champ input de la page d'inscription
  return (dispatch) => {
    dispatch({
      input: input,
      value: value,
      type: 'SIGNIN_UPDATE'
    })
  }
}

export const CREATE_COMPANIE = (value, success, error) => { // création d'une nouvelle entreprise (inscription)
  return () => {
    api('create-companie', {
      value: value
    }, false)
      .then(data => {
        if (data.status === 'OK') {
          success()
        } else {
          error(data.response, data.data)
        }
      })
      .catch(() => {
        error()
      })
  }
}

export const CHECK_PASSWORD_TOKEN = (mail, code, success) => { // vérification du token
  return (dispatch) => {
    api('check-password-token', {
      mail: mail,
      token: code
    }, false)
      .then(data => {
        dispatch(SET_PENDING(false))
        if (data.status === 'OK') {
          print_info(data.response)
          success()
        } else {
          print_error(data.response)
        }
      })
      .catch(() => {
        dispatch(SET_PENDING(false))
        print_error()
      })
  }
}

export const SEND_PASSWORD_TOKEN = (mail, success) => { // envoi du token par mail pour vérifier l'identité du demandeur
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    api('send-password-token', {
      mail: mail
    }, false)
      .then(data => {
        dispatch(SET_PENDING(false))
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'info')
          success()
        } else {
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        dispatch(SET_PENDING(false))
        update_toast(toast, undefined, 'error')
      })
  }
}

export const RESET_USER_PASSWORD = (mail, code, password, success) => { // mise à jour du nouveau mot de passe
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    api('reset-user-password', {
      mail: mail,
      token: code,
      password: password
    }, false)
      .then(data => {
        dispatch(SET_PENDING(false))
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'info')
          success()
        } else {
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        dispatch(SET_PENDING(false))
        update_toast(toast, undefined, 'error')
      })
  }
}
