import React from 'react'
import FilterReset from '../Includes/Filter/FilterReset'
import EquipeToolbarContent from './EquipeToolbarContent'
import ButtonFilter from '../Includes/Button/ButtonFilter'
import {
  useSelector
} from 'react-redux'
import {
  isDifferent
} from '../../Functions/Compare'
import {
  filtres
} from '../../Variables/Variables'

const EquipeToolbarMobile = ({
  setPage,
  setFilter
}) => {
  const equipe = useSelector(state => state.equipe.equipe)
  const filters = useSelector(state => state.filters.pages)
  return (
    <div className="toolbar-actions-mobile">
      <EquipeToolbarContent/>
      {
        (equipe.length > 0)
          ? <ButtonFilter
              onClick={() => setFilter(true)}
            />
          : null
      }
      {
        (isDifferent([filters.equipe], [filtres.equipe]))
          ? <FilterReset
              target="equipe"
              callback={() => setPage(0)}
            />
          : null
      }
    </div>
  )
}

export default EquipeToolbarMobile
