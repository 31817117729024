const initial = {
  equipe: [],
  modal: {
    id: '',
    name: '',
    open: ''
  }
}

function equipe(state = initial, action) {
  switch (action.type) {
    case 'INIT_EQUIPE':
      return {
        ...state,
        equipe: action.value
      }
    case 'DELETE_USER':
      return {
        ...state,
        equipe: state.equipe.filter(item => item.id !== action.value)
      }
    case 'CREATE_USER':
      return {
        ...state,
        equipe: [
          ...state.equipe,
          action.value
        ]
      }
    case 'EDIT_USER':
      return {
        ...state,
        equipe: state.equipe.map(item => {
          return (item.id === action.value.id)
            ? action.value
            : item
        })
      }
    case 'MODAL_EQUIPE':
      return {
        ...state,
        modal: {
          id: action.id,
          name: action.name,
          open: action.open
        }
      }
    case 'ARCHIVE_USER':
      return {
        ...state,
        equipe: state.equipe.map(item => {
          return (item.id === action.id)
            ? {
                ...item,
                archive: action.value
              }
            : item
        })
      }
    case 'UPDATE_USER_MAIL':
      return {
        ...state,
        equipe: state.equipe.map(item => {
          return (item.id === action.user)
            ? {
                ...item,
                mail: action.value
              }
            : item
        })
      }
    case 'UPDATE_USER_CONGES':
      return {
        ...state,
        equipe: state.equipe.map(item => {
          return (item.id === action.value.user)
            ? {
                ...item,
                conges_done: (action.value.nombre <= item.conges_done)
                  ? item.conges_done - action.value.nombre
                  : 0,
                conges_current: (action.value.nombre <= item.conges_done)
                  ? item.conges_current
                  : item.conges_current + item.conges_done - action.value.nombre
              }
            : item
        })
      }
    default:
      return state
  }
}

export default equipe
