import React from 'react'
import LegendeSettingsItem from './LegendeSettingsItem'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  Paper,
  Table,
  TableBody,
  TableContainer
} from '@mui/material'
import {
  isDifferent
} from '../../../Functions/Compare'
import {
  get_ouverture
} from '../../../Functions/Ouverture'
import {
  SHOW_PLANNING_HORAIRES
} from '../../../Redux/Actions/Planning'

const LegendeSettings = () => {
  const dispatch = useDispatch()
  const show = useSelector(state => state.planning.ouverture)
  const ouverture = useSelector(state => state.ouverture.ouverture)
  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableBody>
            {
              (isDifferent([ouverture], [get_ouverture()]))
                ? <LegendeSettingsItem
                    checked={show}
                    label="Afficher les horaires d'ouverture"
                    onChange={() => dispatch(SHOW_PLANNING_HORAIRES(!show))}
                  />
                : null
            }
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

export default LegendeSettings
