import React from 'react'
import SelectInput from './SelectInput'

const SelectArchive = (props) => {
  return (
    <SelectInput
      label="Archivé"
      options={[
        {
          value: 1,
          label: 'Oui'
        },
        {
          value: 0,
          label: 'Non'
        }
      ]}
      {...props}
    />
  )
}

export default SelectArchive
