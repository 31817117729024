import React from 'react'
import InputMail from '../Includes/Input/InputMail'
import LoginButton from '../Includes/Login/LoginButton'
import {
  useDispatch
} from 'react-redux'
import {
  CheckMail
} from '../../Functions/Check'
import {
  SET_PENDING
} from '../../Redux/Actions/Login'
import {
  SEND_PASSWORD_TOKEN
} from '../../Redux/Actions/Signin'

const ForgotMail = ({
  error,
  state,
  Update,
  UpdateError
}) => {
  const dispatch = useDispatch()
  const Mail = (value) => {
    Update('mail', value)
    UpdateError('mail', (
      (value !== '') &&
      (!CheckMail(value))
    ))
  }
  const Commit = () => {
    if (CheckMail(state.mail)) {
      dispatch(SET_PENDING(true))
      UpdateError('mail', false)
      dispatch(SEND_PASSWORD_TOKEN(
        state.mail,
        () => Update('step', 2)
      ))
    } else {
      UpdateError('mail', !CheckMail(state.mail))
    }
  }
  return (
    <>
      <InputMail
        setValue={Mail}
        error={error.mail}
        value={state.mail}
        onKeyUp={(event) => {
          if (event.key === 'Enter') {
            Commit()
          }
        }}
      />
      <LoginButton
        label="Réinitialiser"
        onClick={() => Commit()}
      />
    </>
  )
}

export default ForgotMail
