import React from 'react'
import ButtonGestion from './ButtonGestion'

const ButtonGestionAdd = (props) => {
  return (
    <ButtonGestion
      icon="Add"
      color="blue"
      title="Créer"
      {...props}
    />
  )
}

export default ButtonGestionAdd
