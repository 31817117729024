import React from 'react'
import Button from './Button'

const ButtonSend = (props) => {
  return (
    <Button
      icon="Send"
      title="Comptable"
      {...props}
    />
  )
}

export default ButtonSend
