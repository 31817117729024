import clsx from 'clsx'
import React from 'react'
import styles from './Event.module.css'
import EventAppointmentBase from './EventAppointmentBase'
import AbsencesPlanningAppointment from '../../AbsencesPlanning/AbsencesPlanningAppointment'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  SELECTED_ABSENCE
} from '../../../Redux/Actions/Absences'

const EventAppointmentComponentAbsence = (props) => {
  const { data } = props
  const dispatch = useDispatch()
  const device = useSelector(state => state.device)
  const absence = useSelector(state => state.absences.absence)
  return (
    <EventAppointmentBase
      onClick={() => {
        dispatch(SELECTED_ABSENCE(
          (data.id === absence)
            ? ''
            : data.id
        ))
      }}
      className={
        clsx(
          styles.absence,
          styles.appointment,
          {
            [styles.mobile]: device.mobile,
            [styles.desktop]: device.desktop
          }
        )
      }
      {...props}
    >
      <AbsencesPlanningAppointment data={data}/>
    </EventAppointmentBase>
  )
}

export default EventAppointmentComponentAbsence
