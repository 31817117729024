import {
  USER_LOGOUT
} from './Login'
import {
  api
} from '../../API/api'
import {
  print_error
} from '../../Functions/Toast'

export const MODAL_NOTIFICATIONS = (value) => { // modale de confirmation pour la suppression de notifications
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'MODAL_NOTIFICATIONS'
    })
  }
}

export const SELECTED_NOTIFICATION = (value) => { // sélection de la notification à afficher
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'SELECTED_NOTIFICATION'
    })
  }
}

export const SELECTED_NOTIFICATIONS = (value) => { // sélection des notifications dans la liste
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'SELECTED_NOTIFICATIONS'
    })
  }
}

export const GET_NOTIFICATIONS = () => { // récupération des notifications en BDD
  return (dispatch) => {
    api('get-notifications')
      .then(data => {
        if (data.status === 'OK') {
          dispatch({
            value: data.response,
            type: 'GET_NOTIFICATIONS'
          })
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          print_error(data.response)
        }
      })
      .catch(() => {
        print_error()
      })
  }
}

export const DELETE_NOTIFICATION = (value) => { // suppression des notifications sélectionnées
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'DELETE_NOTIFICATION'
    })
    api('delete-notification', {
      value: value
    })
      .then(data => {
        if (data.status !== 'OK') {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          print_error(data.response)
        }
      })
      .catch(() => {
        print_error()
      })
  }
}

export const READ_NOTIFICATION = (value, user, read) => { // marquer comme lues/non lues les notifications sélectionnées
  return (dispatch) => {
    dispatch({
      read: read,
      user: user,
      value: value,
      type: 'READ_NOTIFICATION'
    })
    if (value.length > 0) {
      api('read-notification', {
        read: read,
        value: value
      })
        .then(data => {
          if (data.status !== 'OK') {
            if (data.data === 'die') {
              dispatch(USER_LOGOUT(false))
            }
            print_error(data.response)
          }
        })
        .catch(() => {
          print_error()
        })
    }
  }
}
