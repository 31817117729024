import React from 'react'
import ComptabilitePrimes from './ComptabilitePrimes'
import InputMotif from '../Includes/Input/InputMotif'
import InputMontant from '../Includes/Input/InputMontant'
import ButtonAddCircle from '../Includes/Button/ButtonAddCircle'
import InputAutocomplete from '../Includes/Input/InputAutocomplete'
import {
  useSelector
} from 'react-redux'
import {
  Paper,
  Toolbar
} from '@mui/material'
import {
  CheckLong,
  CheckFloat
} from '../../Functions/Check'
import {
  get_id
} from '../../Functions/Functions'

const ComptabiliteModalPrimes = ({
  error,
  state,
  Update,
  UpdateError
}) => {
  const equipe = useSelector(state => state.equipe.equipe)
  const team = equipe.filter(item => item.archive === 0)
  const Motif = (value) => {
    Update('motif', value)
    UpdateError('motif', !CheckLong(value))
  }
  const Montant = (value) => {
    Update('montant', value)
    UpdateError('montant', (
      (
        (parseFloat(value) < 0) ||
        (!CheckFloat(value))
      )
    ))
  }
  const Add = () => {
    if (
      (state.users.length > 0) &&
      (state.montant !== '') &&
      CheckLong(state.motif) &&
      (!error.montant)
    ) {
      Update('primes', [
        ...state.primes,
        {
          id: get_id(),
          motif: state.motif,
          users: state.users,
          montant: state.montant
        }
      ])
      Update('salaries', state.salaries.filter(item => state.users.indexOf(item.id) < 0))
      Update('montant', '')
      Update('motif', '')
      Update('users', [])
    } else {
      UpdateError('user', (state.users.length === 0))
      UpdateError('motif', !CheckLong(state.motif))
      UpdateError('montant', (
        (
          (parseFloat(state.montant) < 0) ||
          (!CheckFloat(state.montant))
        )
      ))
    }
  }
  const Edit = (id) => {
    const prime = state.primes.find(item => item.id === id)
    Update('montant', prime.montant)
    Update('users', prime.users)
    Update('motif', prime.motif)
    UpdateError('montant', false)
    UpdateError('motif', false)
    UpdateError('user', false)
    Del(id)
  }
  const Del = (id) => {
    Update('salaries', [
      ...state.salaries,
      ...team.filter(item => state.primes.find(item => item.id === id).users.includes(item.id))
    ])
    Update('primes', state.primes.filter(item => item.id !== id))
  }
  return (
    <>
      <div className="inline-block-description">Voulez-vous ajouter des primes pour vos salariés ?</div>
      <div className="form-comptabilite">
        <Paper className="form-comptabilite-container">
          {
            (equipe.length > 0)
              ? <Toolbar className="form-comptabilite-content">
                  <div>
                    <InputAutocomplete
                      label="Salariés"
                      error={error.user}
                      options={state.salaries}
                      className="form-comptabilite-input"
                      value={team.filter(item => state.users.includes(item.id))}
                      onChange={(event, value) => {
                        if (value.length > 0) {
                          UpdateError('user', false)
                        }
                        Update('users', value.map(item => item.id))
                      }}
                    />
                  </div>
                  <div>
                    <InputMontant
                      setValue={Montant}
                      error={error.montant}
                      value={state.montant}
                      className="form-comptabilite-montant"
                    />
                    <InputMotif
                      helperText=""
                      setValue={Motif}
                      error={error.motif}
                      value={state.motif}
                      className="form-comptabilite-motif"
                      onKeyUp={(event) => {
                        if (event.key === 'Enter') {
                          Add()
                        }
                      }}
                    />
                    <ButtonAddCircle
                      color="blue"
                      onClick={() => Add()}
                      className="form-comptabilite-button"
                    />
                    {
                      (error.user)
                        ? <div className="error-comptabilite">Merci de sélectionner au moins un salarié</div>
                        : (error.montant)
                          ? <div className="error-comptabilite">Ce montant est invalide</div>
                          : (error.motif)
                            ? <div className="error-comptabilite">Ce motif est invalide</div>
                            : null
                    }
                  </div>
                </Toolbar>
              : null
          }
          <ComptabilitePrimes
            primes={state.primes}
            Edit={(id) => Edit(id)}
            Delete={(id) => Del(id)}
          />
        </Paper>
      </div>
    </>
  )
}

export default ComptabiliteModalPrimes
