import React from 'react'
import ButtonGestion from './ButtonGestion'

const ButtonGestionProduction = (props) => {
  return (
    <ButtonGestion
      color="green"
      icon="Language"
      title="Production"
      {...props}
    />
  )
}

export default ButtonGestionProduction
