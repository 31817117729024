import React from 'react'
import FilterReset from './FilterReset'
import styles from './FilterDesktop.module.css'
import SelectProfil from '../Select/SelectProfil'
import SelectArchive from '../Select/SelectArchive'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  Toolbar
} from '@mui/material'
import {
  isDifferent
} from '../../../Functions/Compare'
import {
  filtres
} from '../../../Variables/Variables'
import {
  SET_FILTERS_PAGE_VALUE
} from '../../../Redux/Actions/Filters'

const FilterEquipeDesktop = ({ setPage }) => {
  const dispatch = useDispatch()
  const filters = useSelector(state => state.filters.pages)
  return (
    <Toolbar className={styles.toolbar}>
      <div className={styles.content}>
        <SelectProfil
          filter={true}
          className={styles.filter}
          value={filters.equipe.profil}
          setValue={(value) => {
            setPage(0)
            dispatch(SET_FILTERS_PAGE_VALUE('equipe', 'profil', value))
          }}
        />
        <SelectArchive
          className={styles.filter}
          value={filters.equipe.archive}
          setValue={(value) => {
            setPage(0)
            dispatch(SET_FILTERS_PAGE_VALUE('equipe', 'archive', value))
          }}
        />
      </div>
      {
        (isDifferent([filters.equipe], [filtres.equipe]))
          ? <FilterReset
              target="equipe"
              callback={() => setPage(0)}
            />
          : null
      }
    </Toolbar>
  )
}

export default FilterEquipeDesktop
