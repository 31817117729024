import Alert from '../Dialog/Alert'
import dayjs from '../../../Libraries/dayjs'
import ToolbarWizardContent from './ToolbarWizardContent'
import React, {
  useEffect
} from 'react'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from '../Dialog/Dialog'
import {
  get_weeks
} from '../../../Functions/Dates'
import {
  get_data_week
} from '../../../Functions/Planning'
import {
  get_id
} from '../../../Functions/Functions'
import {
  MODAL_PLANNING,
  ADD_CRENEAU_PLANNING_WIZARD
} from '../../../Redux/Actions/Planning'

const ToolbarWizard = () => {
  const dispatch = useDispatch()
  const [popup, setPopup] = React.useState(false)
  const [commit, setCommit] = React.useState(false)
  const open = useSelector(state => state.planning.open)
  const planning = useSelector(state => state.planning.planning)
  const semaines = useSelector(state => state.semaines.semaines)
  const [error, setError] = React.useState({
    array: [],
    dates: false,
    weeks: false
  })
  const [state, setState] = React.useState({
    data: [],
    weeks: [],
    dates: [null, null]
  })
  const Update = (item, value) => {
    setState(current => ({
      ...current,
      [item]: value
    }))
  }
  const UpdateError = (item, value) => {
    setError(current => ({
      ...current,
      [item]: value
    }))
  }
  const Commit = () => {
    if (state.weeks.length > 0) {
      if (
        (state.dates[0] !== null) &&
        (state.dates[1] !== null) &&
        (dayjs(state.dates[1]).isAfter(dayjs(state.dates[0])))
      ) {
        let data = []
        const weeks = get_weeks(
          state.dates[0],
          state.dates[1],
          'YYYYMMDD'
        )
        weeks.forEach((item, index) => {
          let existe
          let nombre = 0
          let hour = false
          const id = state.weeks[index % state.weeks.length]
          const array = planning.find(object => object.week === item)
          if (array !== undefined) {
            nombre = array.creneaux.length
            hour = (array.creneaux.filter(object => object.heure === true).length > 0)
            existe = (array.creneaux.filter(object => object.heure !== true).length > 0)
          }
          data.push({
            hour: hour,
            week: item,
            id: get_id(),
            existe: existe,
            nombre: nombre,
            creneaux: get_data_week(semaines.find(object => object.id === id), dayjs(item, 'YYYYMMDD'))
          })
        })
        Update('data', data)
      } else {
        UpdateError('dates', true)
      }
    } else {
      UpdateError('weeks', true)
    }
  }
  useEffect(() => {
    if (state.data.length > 0) {
      UpdateError('array', state.data.filter(item => item.nombre > 0).map(item => dayjs(item.week, 'YYYYMMDD').format('DD/MM/YYYY')))
    }
    // eslint-disable-next-line
  }, [state.data])
  useEffect(() => {
    if (state.data.length > 0) {
      if (error.array.length === 0) {
        setCommit(true)
      } else {
        setPopup(true)
      }
    }
    // eslint-disable-next-line
  }, [error.array])
  useEffect(() => {
    if (open.wizard) {
      Update('data', [])
      Update('weeks', [])
      UpdateError('array', [])
      UpdateError('dates', false)
      UpdateError('weeks', false)
      Update('dates', [null, null])
    }
    // eslint-disable-next-line
  }, [open.wizard])
  useEffect(() => {
    if (commit) {
      let value
      let data = []
      setPopup(false)
      setCommit(false)
      state.data.forEach(item => {
        if (item.existe) {
          value = item.creneaux
        } else {
          const { hour, existe, nombre, ...object } = item
          value = object
        }
        data.push({
          value: value,
          date: item.week,
          hour: item.hour,
          existe: item.existe
        })
      })
      dispatch(ADD_CRENEAU_PLANNING_WIZARD(data))
      dispatch(MODAL_PLANNING('wizard', false))
    }
    // eslint-disable-next-line
  }, [commit])
  return (
    <Dialog
      maxWidth="md"
      fullWidth={true}
      open={open.wizard}
    >
      <DialogTitle>Remplir le planning</DialogTitle>
      <DialogContent>
        <Alert
          open={popup}
          titre="Confirmation"
          Cancel={() => setPopup(false)}
          Commit={() => {
            setPopup(false)
            setCommit(true)
          }}
          texte={
            (error.array.length === 1)
              ? <div>La semaine du <b>{error.array[0]}</b> n'est pas vide, voulez-vous vraiment continuer ?</div>
              : <div>
                  <span>Les semaines du </span>
                  {
                    error.array.map((item, index) => {
                      const virgule = (index !== (error.array.length - 1)) ? ', ' : ''
                      return (
                        <b key={index}>{item}{virgule}</b>
                      )
                    })
                  }
                  <span> ne sont pas vides, voulez-vous vraiment continuer ?</span>
                </div>
          }
        />
        <ToolbarWizardContent
          error={error}
          state={state}
          Update={Update}
          UpdateError={UpdateError}
        />
      </DialogContent>
      <DialogActions
        buttons={[
          {
            onClick: () => dispatch(MODAL_PLANNING('wizard', false))
          },
          {
            onClick: () => Commit()
          }
        ]}
      />
    </Dialog>
  )
}

export default ToolbarWizard
