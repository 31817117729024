import React from 'react'
import Abonnements from '../Abonnements/Abonnements'
import styles from './AbonnementModalContent.module.css'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  SIGNIN_ABONNEMENT
} from '../../Redux/Actions/Signin'

const AbonnementModalContent = ({
  error,
  setError
}) => {
  const dispatch = useDispatch()
  const device = useSelector(state => state.device)
  const stripe = useSelector(state => state.stripe.stripe)
  const current = useSelector(state => state.stripe.abonnement)
  const abonnement = useSelector(state => state.signin.abonnement)
  const abonnements = useSelector(state => state.landing.abonnements)
  return (
    <div className={styles.container}>
      <Abonnements
        modal={true}
        setError={setError}
        current={current.id}
        mobile={device.mobile}
        abonnements={abonnements}
        selected={abonnement.unique_id_abonnement}
        discount={
          (stripe.percent > 0)
            ? (price) => price * stripe.percent
            : (stripe.reduction > 0)
              ? (price) => price - stripe.reduction
              : undefined
        }
        Select={(item) => {
          setError(false)
          dispatch(SIGNIN_ABONNEMENT(
            (item.unique_id_abonnement !== abonnement.unique_id_abonnement)
              ? item
              : ''
          ))
        }}
      />
      {
        (error)
          ? <div className="error-abonnement error-compte-abonnement">Merci de choisir un abonnement</div>
          : null
      }
    </div>
  )
}

export default AbonnementModalContent
