import React from 'react'
import ButtonGestion from './ButtonGestion'

const ButtonGestionBlock = (props) => {
  return (
    <ButtonGestion
      color="red"
      icon="Block"
      title="Bloquer"
      {...props}
    />
  )
}

export default ButtonGestionBlock
