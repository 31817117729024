import React from 'react'
import Form from '../Wrapper/Form'
import Slider from '../Slider/Slider'
import DatePicker from '../Picker/DatePicker'
import InputAutocomplete from '../Input/InputAutocomplete'
import {
  useSelector
} from 'react-redux'
import {
  Chip
} from '@mui/material'
import {
  get_user_long
} from '../../../Functions/Strings'

const LegendeCreneauModalContent = ({
  state,
  Update
}) => {
  const [picker, setPicker] = React.useState(false)
  const equipe = useSelector(state => state.equipe.equipe)
  const archives = equipe.filter(item => item.archive === 1).map(item => item.id)
  return (
    <Form full={true}>
      {
        (equipe.length > 0)
          ? <InputAutocomplete
              label="Salariés"
              options={equipe}
              value={equipe.filter(item => state.users.includes(item.id))}
              filterOptions={(options) => options.filter(item => item.archive === 0)}
              disableClearable={(
                (state.users.length > 0) &&
                (state.users.every(item => archives.includes(item)))
              )}
              onChange={(event, value, reason) => {
                const users = (reason !== 'clear')
                  ? value.map(item => item.id)
                  : state.users.filter(item => archives.includes(item))
                Update('users', users.sort())
              }}
              renderTags={(value, getProps) => (
                value.map((item, index) => {
                  const { onDelete, ...props } = getProps({ index })
                  return (
                    <Chip
                      key={item.id}
                      label={get_user_long(item)}
                      {...props}
                      onDelete={
                        (item.archive === 1)
                          ? undefined
                          : onDelete
                      }
                    />
                  )
                })
              )}
            />
          : null
      }
      <div className="datepicker-planning">
        <DatePicker
          open={picker}
          value={state.day}
          onClose={() => setPicker(false)}
          onChange={(value) => Update('day', value)}
          slotProps={{
            textField: {
              onClick: () => setPicker(true)
            }
          }}
        />
      </div>
      <Slider
        input={true}
        wrong={false}
        value={state.heures}
        setValue={(value) => Update('heures', value)}
      />
    </Form>
  )
}

export default LegendeCreneauModalContent
