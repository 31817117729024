import React from 'react'
import styles from './Bulle.module.css'
import {
  useSelector
} from 'react-redux'
import {
  Tooltip
} from '@mui/material'

const Bulle = ({
  title,
  children,
  disabled,
  ...others
}) => {
  const device = useSelector(state => state.device)
  return (disabled)
    ? children
    : <Tooltip
        classes={{
          tooltip: styles.tooltip
        }}
        title={
          (device.mobile)
            ? ''
            : title
        }
        {...others}
      >
        {children}
      </Tooltip>
}

export default Bulle
