import React from 'react'
import ButtonGestionAdd from '../../Includes/Button/ButtonGestionAdd'
import ButtonGestionDelete from '../../Includes/Button/ButtonGestionDelete'
import ButtonGestionDownload from '../../Includes/Button/ButtonGestionDownload'
import {
  saveAs
} from 'file-saver'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  MODAL_CODES,
  SELECTED_CODES
} from '../../../Redux/Actions/Codes'

const CodesToolbarButtons = () => {
  const dispatch = useDispatch()
  const codes = useSelector(state => state.codes.codes)
  const selected = useSelector(state => state.codes.selected)
  const Download = () => {
    let string = 'Code;Pourcentage;Réduction;Jours\n'
    selected.forEach(item => {
      const code = codes.find(object => object.id === item)
      string += item + ';' + code.percent + ';' + code.reduction + ';' + code.free + '\n'
    })
    var bom = "\uFEFF";
    const data = new Blob(
      [bom + string],
      {
        type: 'text/csv;charset=utf-8'
      }
    )
    saveAs(data, 'codes.csv')
    dispatch(SELECTED_CODES([]))
  }
  return (
    <>
      <ButtonGestionAdd
        onClick={() => dispatch(MODAL_CODES('create'))}
      />
      {
        (codes.length > 0)
          ? <>
              <ButtonGestionDownload
                onClick={() => Download()}
                disabled={(selected.length === 0)}
              />
              <ButtonGestionDelete
                disabled={(selected.length === 0)}
                onClick={() => dispatch(MODAL_CODES('delete'))}
              />
            </>
          : null
      }
    </>
  )
}

export default CodesToolbarButtons
