import clsx from 'clsx'
import React from 'react'
import Bulle from '../Wrapper/Bulle'
import ButtonIcon from './ButtonIcon'
import styles from './Button.module.css'
import {
  useSelector
} from 'react-redux'
import {
  Button,
  IconButton
} from '@mui/material'

const ButtonGestion = ({
  icon,
  color,
  empty,
  title,
  disabled,
  ...others
}) => {
  const device = useSelector(state => state.device)
  return (device.mobile)
    ? <Button
        color="primary"
        variant="contained"
        disabled={disabled}
        className={styles.mobile}
        {...others}
      >
        <ButtonIcon icon={icon}/>
      </Button>
    : <Bulle
        arrow={true}
        title={title}
        placement="top"
        disabled={disabled}
      >
        <span>
          <IconButton
            disabled={disabled}
            className={
              (disabled)
                ? styles.disabled
                : clsx({
                    [styles[color]]: true,
                    [styles.desktop]: empty
                  })
            }
            {...others}
          >
            <ButtonIcon icon={icon}/>
          </IconButton>
        </span>
      </Bulle>
}

export default ButtonGestion
