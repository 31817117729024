import React from 'react'
import styles from '../Styles/Mail.module.css'
import InputSujet from '../../Includes/Input/InputSujet'
import InputMessage from '../../Includes/Input/InputMessage'
import ButtonDelete from '../../Includes/Button/ButtonDelete'
import Destinataires from '../../Includes/Misc/Destinataires'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  Paper
} from '@mui/material'
import {
  CheckTexte,
  CheckMessage
} from '../../../Functions/Check'
import {
  DEL_MAIL,
  SET_MAIL,
  MAIL_ERROR
} from '../../../Redux/Actions/Mail'

const MailForm = () => {
  const dispatch = useDispatch()
  const error = useSelector(state => state.mail.error)
  const mails = useSelector(state => state.mail.mails)
  const sujet = useSelector(state => state.mail.sujet)
  const texte = useSelector(state => state.mail.texte)
  const Sujet = (value) => {
    dispatch(SET_MAIL('sujet', value))
    dispatch(MAIL_ERROR('sujet', (
      (value !== '') &&
      (!CheckTexte(value))
    )))
  }
  const Texte = (value) => {
    dispatch(SET_MAIL('texte', value))
    dispatch(MAIL_ERROR('texte', (
      (value !== '') &&
      (!CheckMessage(value))
    )))
  }
  return (
    <Paper className={styles.paper}>
      <div className={styles.container}>
        <Destinataires
          mails={mails}
          Delete={(item) => dispatch(DEL_MAIL(item))}
        />
        <ButtonDelete
          title=""
          className={styles.action}
          disabled={(mails.length === 0)}
          onClick={() => dispatch(SET_MAIL('mails', []))}
          color={
            (mails.length === 0)
              ? "grey"
              : "red"
          }
        />
      </div>
      <InputSujet
        value={sujet}
        setValue={Sujet}
        error={error.sujet}
      />
      <InputMessage
        value={texte}
        setValue={Texte}
        error={error.texte}
      />
    </Paper>
  )
}

export default MailForm
