import {
  USER_LOGOUT
} from './Login'
import {
  api
} from '../../API/api'
import {
  update_toast,
  print_loading
} from '../../Functions/Toast'
import {
  get_id
} from '../../Functions/Functions'

export const DELETE_COMPANIE = (texte, callback, success) => { // suppression de l'entreprise
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    api('delete-companie', {
      texte: texte
    })
      .then(data => {
        callback()
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          dispatch(USER_LOGOUT(false))
          success()
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        callback()
        update_toast(toast, undefined, 'error')
      })
  }
}

export const UPDATE_USER_PASSWORD = (value, callback, success) => { // mise à jour du mot de passe utilisateur
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    api('update-user-password', {
      password: value
    })
      .then(data => {
        callback()
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          dispatch(USER_LOGOUT(false))
          success()
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        callback()
        update_toast(toast, undefined, 'error')
      })
  }
}

export const UPDATE_USER_MAIL = (value, callback, success) => { // mise à jour de l'adresse mail utilisateur
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch, state) => {
    api('update-user-mail', {
      mail: value
    })
      .then(data => {
        callback()
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          const user = state().login.user.unique_id_user
          dispatch({
            value: value,
            type: 'USER_MAIL'
          })
          dispatch({
            user: user,
            value: value,
            type: 'UPDATE_USER_MAIL'
          })
          success()
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        callback()
        update_toast(toast, undefined, 'error')
      })
  }
}
