import {
  USER_LOGOUT
} from './Login'
import {
  api
} from '../../API/api'
import {
  update_toast,
  print_loading
} from '../../Functions/Toast'
import {
  get_id
} from '../../Functions/Functions'

export const MODAL_FONCTIONS = (id, open, name) => { // modale pour créer, modifier ou supprimer une fonction
  return (dispatch) => {
    dispatch({
      id: id,
      name: name,
      open: open,
      type: 'MODAL_FONCTIONS'
    })
  }
}

export const DELETE_FONCTION = (id) => { // suppression d'une fonction
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    api('delete-fonction', {
      id: id
    })
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          dispatch({
            value: id,
            type: 'DELETE_FONCTION'
          })
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}

export const MANAGE_FONCTION = (value, action) => { // création/modification d'une fonction
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    api(action, {
      value: value
    })
      .then(data => {
        if (data.status === 'OK') {
          if (action === 'create-fonction') {
            dispatch({
              value: value,
              type: 'CREATE_FONCTION'
            })
          } else {
            dispatch({
              value: value,
              type: 'EDIT_FONCTION'
            })
          }
          update_toast(toast, data.response, 'success')
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}
