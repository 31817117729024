import React from 'react'
import Titre from '../Includes/Misc/Titre'
import FilterReset from '../Includes/Filter/FilterReset'
import IndemnitesToolbarContent from './IndemnitesToolbarContent'
import {
  useSelector
} from 'react-redux'
import {
  Toolbar
} from '@mui/material'

const IndemnitesToolbarDesktop = () => {
  const notification = useSelector(state => state.notifications.notification)
  return (
    <Toolbar>
      <Titre>Indemnités kilométriques</Titre>
      {
        (notification === '')
          ? <IndemnitesToolbarContent/>
          : <FilterReset notification={true}/>
      }
    </Toolbar>
  )
}

export default IndemnitesToolbarDesktop
