import {
  USER_LOGOUT
} from './Login'
import {
  api
} from '../../API/api'
import {
  print_error,
  update_toast,
  print_loading
} from '../../Functions/Toast'
import {
  get_id
} from '../../Functions/Functions'

export const SEARCH_COMPANIE = (value) => { // recherche d'une entreprise
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'SEARCH_COMPANIE'
    })
  }
}

export const SELECTED_COMPANIES = (value) => { // sélection des entreprises
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'SELECTED_COMPANIES'
    })
  }
}

export const MODAL_COMPANIES = (open, texte, action) => { // modale pour bloquer/débloquer ou modifier l'envrionnement des entreprises (beta/prod)
  return (dispatch) => {
    dispatch({
      open: open,
      texte: texte,
      action: action,
      type: 'MODAL_COMPANIES'
    })
  }
}

export const GET_COMPANIES = () => { // récupération des entreprises en BDD
  return (dispatch) => {
    api('get-companies')
      .then(data => {
        if (data.status === 'OK') {
          dispatch({
            value: data.response,
            type: 'GET_COMPANIES'
          })
          dispatch({
            type: 'LOADING_COMPANIES'
          })
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          print_error(data.response)
        }
      })
      .catch(() => {
        print_error()
      })
  }
}

export const BLOCK_COMPANIE = (companies, value) => { // bloquer/débloquer des entreprises
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    api('block-companie', {
      value: value,
      companies: companies
    })
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          dispatch({
            value: value,
            companies: companies,
            type: 'BLOCK_COMPANIE'
          })
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}

export const ENVIRONMENT_COMPANIE = (companies, value) => { // modifier l'environnement des entreprises (beta/prod)
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    api('environment-companie', {
      value: value,
      companies: companies
    })
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          dispatch({
            value: value,
            companies: companies,
            type: 'ENVIRONMENT_COMPANIE'
          })
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}
