import React from 'react'
import Planning from '../Hooks/Planning'
import ButtonIconDay from '../Button/ButtonIconDay'
import ButtonIconWeek from '../Button/ButtonIconWeek'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  UPDATE_PLANNING,
  SET_DATE_PLANNING,
  SET_PLANNING_VIEW
} from '../../../Redux/Actions/Planning'
import {
  SET_DATE_SEMAINES
} from '../../../Redux/Actions/Semaines'

const EventSwitcherComponent = () => {
  const planning = Planning()
  const dispatch = useDispatch()
  const view = useSelector(state => state.planning.view)
  const date = {
    planning: useSelector(state => state.planning.date),
    semaines: useSelector(state => state.semaines.date)
  }
  return (view === 'Day')
    ? <ButtonIconWeek
        title="Affichage par semaine"
        onClick={() => {
          dispatch(SET_PLANNING_VIEW('Week'))
          if (planning) {
            dispatch(UPDATE_PLANNING())
            dispatch(SET_DATE_PLANNING(date.planning.startOf('week')))
          } else {
            dispatch(SET_DATE_SEMAINES(date.semaines.startOf('week')))
          }
        }}
      />
    : <ButtonIconDay
        title="Affichage par jour"
        onClick={() => {
          dispatch(SET_PLANNING_VIEW('Day'))
          if (planning) {
            dispatch(UPDATE_PLANNING())
          }
        }}
      />
}

export default EventSwitcherComponent
