import React from 'react'
import Form from '../Wrapper/Form'
import SelectProfil from '../Select/SelectProfil'
import SelectArchive from '../Select/SelectArchive'

const FilterEquipeMobileContent = ({
  state,
  Update
}) => {
  return (
    <Form>
      <SelectProfil
        filter={true}
        value={state.profil}
        setValue={(value) => Update('profil', value)}
      />
      <SelectArchive
        value={state.archive}
        setValue={(value) => Update('archive', value)}
      />
    </Form>
  )
}

export default FilterEquipeMobileContent
