import React from 'react'
import FonctionModal from './FonctionModal'
import Root from '../Includes/Wrapper/Root'
import Alert from '../Includes/Dialog/Alert'
import FonctionsContent from './FonctionsContent'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  DELETE_FONCTION,
  MODAL_FONCTIONS
} from '../../Redux/Actions/Fonctions'

const Fonctions = () => {
  const dispatch = useDispatch()
  const modal = useSelector(state => state.fonctions.modal)
  const fonctions = useSelector(state => state.fonctions.fonctions)
  const maximum = useSelector(state => state.compte.maximum.fonctions)
  return (
    <Root container={true}>
      {
        (maximum !== -1)
          ? <FonctionModal/>
          : null
      }
      {
        (fonctions.length > 0)
          ? <Alert
              titre="Confirmation"
              open={(modal.open === 'delete')}
              Cancel={() => dispatch(MODAL_FONCTIONS('', ''))}
              texte={
                <span>
                  <span className="block">Voulez-vous vraiment supprimer la fonction <span className="delete-name">{modal.name}</span> ?</span>
                  <span className="block">Cette action est irréversible...</span>
                </span>
              }
              Commit={() => {
                dispatch(MODAL_FONCTIONS('', ''))
                dispatch(DELETE_FONCTION(modal.id))
              }}
            />
          : null
      }
      <FonctionsContent/>
    </Root>
  )
}

export default Fonctions
