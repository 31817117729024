import dayjs from '../../Libraries/dayjs'
import {
  USER_LOGOUT
} from './Login'
import {
  base64StringToBlob
} from 'blob-util'
import {
  saveAs
} from 'file-saver'
import {
  api
} from '../../API/api'
import {
  print_error,
  update_toast,
  print_loading
} from '../../Functions/Toast'
import {
  get_id
} from '../../Functions/Functions'

export const SET_PDF = (value) => { // stockage des informations du pdf
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'SET_PDF'
    })
  }
}

export const SELECTED_COMPTABILITE = (value) => { // sélection des salariés pour l'export comptable
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'SELECTED_COMPTABILITE'
    })
  }
}

export const SET_DATES_COMPTABILITE = (value) => { // sélection des dates de l'export comptable
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'SET_DATES_COMPTABILITE'
    })
  }
}

export const DOWNLOAD_EXPORT = (id, name) => { // téléchargement du fichier pdf
  return (dispatch) => {
    api('download-export', {
      id: id
    })
      .then(data => {
        if (data.status === 'OK') {
          const blob = base64StringToBlob(data.response, 'application/pdf')
          saveAs(blob, name + '.pdf')
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          print_error(data.response)
        }
      })
      .catch(() => {
        print_error()
      })
  }
}

export const SEND_EXPORT = (id) => { // envoi du mail au comptable avec le pdf en pièce jointe
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    api('send-export', {
      id: id
    })
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}

export const SAVE_PRIMES = (value) => { // sauvegarde des primes des salariés
  return (dispatch) => {
    api('save-primes', {
      value: value
    })
      .then(data => {
        if (data.status === 'OK') {
          dispatch({
            value: value,
            type: 'INIT_PRIMES'
          })
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          print_error(data.response)
        }
      })
      .catch(() => {
        print_error()
      })
  }
}

export const SEND_MAIL_COMPTABLE = (value) => { // envoi du mail au comptable avec le pdf en pièce jointe
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    api('send-mail-comptable', {
      value: value
    })
      .then(data => {
        if (data.status === 'OK') {
          dispatch({
            type: 'ADD_EXPORT',
            value: {
              id: value.id,
              date: value.date,
              fin: dayjs(value.fin, 'DD/MM/YYYY').unix(),
              debut: dayjs(value.debut, 'DD/MM/YYYY').unix()
            }
          })
          update_toast(toast, data.response, 'success')
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}

export const UPDATE_CLOSE = (type, value, equipe) => { // clôture de toutes les demandes comprises dans les dates de l'export
  return (dispatch) => {
    if (value.length > 0) {
      api('close-' + type, {
        ids: value
      })
        .then(data => {
          if (data.status === 'OK') {
            switch (type) {
              case 'heure':
                value.forEach(item => {
                  dispatch({
                    id: item,
                    value: 3,
                    type: 'UPDATE_HEURE'
                  })
                })
              break
              case 'absence':
                value.forEach(item => {
                  dispatch({
                    id: item,
                    value: 3,
                    type: 'UPDATE_ABSENCE'
                  })
                })
              break
              case 'indemnite':
                value.forEach(item => {
                  dispatch({
                    id: item,
                    value: 3,
                    type: 'UPDATE_INDEMNITE'
                  })
                })
              break
              default:
              break
            }
            if (equipe !== undefined) {
              dispatch({
                value: equipe,
                type: 'EDIT_USER'
              })
            }
          } else {
            if (data.data === 'die') {
              dispatch(USER_LOGOUT(false))
            }
            print_error(data.response)
          }
        })
        .catch(() => {
          print_error()
        })
    } else if (equipe !== undefined) {
      dispatch({
        value: equipe,
        type: 'EDIT_USER'
      })
    }
  }
}
