import React from 'react'
import styles from './Link.module.css'
import {
  open_link
} from '../../../Functions/Functions'
import {
  variables
} from '../../../Variables/Variables'

const PDC = () => (
  <span
    className={styles.link}
    onClick={() => open_link(variables.url.server + variables.path.pdf + 'politique_confidentialite.pdf')}
  >
    Politique de confidentialité
  </span>
)

export default PDC
