import React from 'react'
import history from '../Includes/History'
import styles from './BarMobile.module.css'
import BarTitleMobile from './BarTitleMobile'
import Badge from '../../Components/Includes/Wrapper/Badge'
import ButtonMenu from '../../Components/Includes/Button/ButtonMenu'
import ButtonLogout from '../../Components/Includes/Button/ButtonLogout'
import ButtonAccount from '../../Components/Includes/Button/ButtonAccount'
import ButtonNotifications from '../../Components/Includes/Button/ButtonNotifications'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  Toolbar
} from '@mui/material'
import {
  MENU_OPEN
} from '../../Redux/Actions/Menu'
import {
  USER_LOGOUT
} from '../../Redux/Actions/Login'
import {
  SELECTED_NOTIFICATION
} from '../../Redux/Actions/Notifications'

const BarMobile = () => {
  const dispatch = useDispatch()
  const menu = useSelector(state => state.menu.menu)
  const user = useSelector(state => state.login.user)
  const notifications = useSelector(state => state.notifications.notifications)
  const badge = notifications.filter(item => item.read.indexOf(user.unique_id_user) < 0).length
  return (
    <Toolbar className="bar-menu">
      <ButtonMenu
        edge="start"
        className={
          (user.profil !== 'office')
            ? styles.hamburger
            : styles.disabled
        }
        onClick={() => {
          if (user.profil !== 'office') {
            dispatch(MENU_OPEN(!menu))
          }
        }}
      />
      <BarTitleMobile/>
      {
        (
          (user.profil === 'administrateur') ||
          (user.profil === 'utilisateur')
        )
          ? <>
              <Badge
                content={badge}
                className={styles.badge}
                style={{
                  top: 13,
                  right: 10,
                  padding: '0px 4px'
                }}
              >
                <ButtonNotifications
                  edge="start"
                  className={styles.button}
                  onClick={() => {
                    dispatch(SELECTED_NOTIFICATION(''))
                    dispatch(MENU_OPEN(false))
                    history.push('/notifications')
                  }}
                />
              </Badge>
              <ButtonAccount
                edge="start"
                className="account-icon"
                onClick={() => {
                  dispatch(SELECTED_NOTIFICATION(''))
                  dispatch(MENU_OPEN(false))
                  history.push('/compte')
                }}
              />
            </>
          : null
      }
      {
        (user.profil === 'office')
          ? <ButtonLogout
              edge="start"
              className="logout-icon"
              onClick={() => {
                dispatch(SELECTED_NOTIFICATION(''))
                dispatch(USER_LOGOUT(true))
              }}
            />
          : null
      }
      {
        (user.profil === 'gestionnaire')
          ? <ButtonMenu
              edge="start"
              className={styles.hidden}
            />
          : null
      }
    </Toolbar>
  )
}

export default BarMobile
