import SemainesBar from './SemainesBar'
import dayjs from '../../Libraries/dayjs'
import Page from '../Includes/Wrapper/Page'
import SemainesTable from './SemainesTable'
import SemainesScheduler from './SemainesScheduler'
import Planning from '../Includes/Wrapper/Planning'
import SemainesToolbarMobile from './SemainesToolbarMobile'
import SemainesToolbarDesktop from './SemainesToolbarDesktop'
import LegendePlanning from '../Includes/Legende/LegendePlanning'
import React, {
  useEffect
} from 'react'
import {
  DndProvider
} from 'react-dnd'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  CheckTexte
} from '../../Functions/Check'
import {
  HTML5Backend
} from 'react-dnd-html5-backend'
import {
  get_planning_erreurs
} from '../../Functions/Planning'
import {
  get_id
} from '../../Functions/Functions'
import {
  SELECTED_CRENEAU
} from '../../Redux/Actions/Planning'
import {
  SEMAINE_NOM,
  EDIT_SEMAINE,
  SAVE_SEMAINE,
  CREATE_SEMAINE,
  SET_SEMAINE_DATA,
  SET_SEMAINE_ERREURS,
  SET_SEMAINE_WORKING
} from '../../Redux/Actions/Semaines'

const SemainesContent = () => {
  const dispatch = useDispatch()
  const device = useSelector(state => state.device)
  const nom = useSelector(state => state.semaines.nom)
  const data = useSelector(state => state.semaines.data)
  const event = useSelector(state => state.planning.event)
  const equipe = useSelector(state => state.equipe.equipe)
  const commit = useSelector(state => state.semaines.commit)
  const filter = useSelector(state => state.filters.salaries)
  const current = useSelector(state => state.semaines.current)
  const working = useSelector(state => state.semaines.working)
  const checked = equipe.map(item => item.id).filter(item => filter.indexOf(item) < 0)
  useEffect(() => {
    dispatch(SET_SEMAINE_ERREURS(get_planning_erreurs(current, false)))
    // eslint-disable-next-line
  }, [current])
  useEffect(() => {
    dispatch(SET_SEMAINE_DATA(current.map(item => {
      return {
        ...item,
        empty: (item.equipe.length === 0),
        endDate: dayjs(item.endDate).format(),
        startDate: dayjs(item.startDate).format(),
        equipe: item.equipe.filter(item => checked.includes(item))
      }
    }).filter(item =>
      (item.empty) ||
      (item.equipe.length > 0)
    )))
    // eslint-disable-next-line
  }, [filter, current])
  useEffect(() => {
    if (
      (commit) &&
      (
        (working.edit) ||
        (
          (working.new) &&
          CheckTexte(nom.value)
        )
      )
    ) {
      dispatch(SAVE_SEMAINE(false))
      dispatch(SELECTED_CRENEAU({
        id: '',
        read: false
      }))
      const value = {
        creneaux: current,
        nom: (working.new)
          ? nom.value
          : working.nom,
        id: (working.new)
          ? get_id()
          : working.id
      }
      if (working.new) {
        dispatch(CREATE_SEMAINE(value))
      } else {
        dispatch(EDIT_SEMAINE(value))
      }
      dispatch(SEMAINE_NOM({
        value: '',
        error: false
      }))
      dispatch(SET_SEMAINE_WORKING({
        id: '',
        nom: '',
        new: false,
        edit: false,
        open: false,
        read: false
      }))
    } else if (
      (commit) &&
      (working.new)
    ) {
      dispatch(SEMAINE_NOM({
        ...nom,
        error: (!CheckTexte(nom.value))
      }))
      dispatch(SAVE_SEMAINE(false))
    }
    // eslint-disable-next-line
  }, [commit])
  return (
    <DndProvider backend={HTML5Backend}>
      <Page column={device.mobile}>
        {
          (
            (device.mobile) &&
            (!working.open)
          )
            ? <SemainesToolbarMobile/>
            : null
        }
        <Planning>
          {
            (working.open)
              ? <>
                  <SemainesBar/>
                  <SemainesScheduler/>
                </>
              : <>
                  {
                    (device.desktop)
                      ? <SemainesToolbarDesktop/>
                      : null
                  }
                  <SemainesTable/>
                </>
          }
        </Planning>
        {
          (
            (working.open) &&
            (device.desktop) &&
            (
              (equipe.length > 0) ||
              (event.id !== '')
            )
          )
            ? <LegendePlanning vides={data.filter(item => item.empty)}/>
            : null
        }
      </Page>
    </DndProvider>
  )
}

export default SemainesContent
