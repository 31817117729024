import clsx from 'clsx'
import React from 'react'
import styles from './TableCell.module.css'

const TableCellPaiementStatus = ({
  color,
  texte
}) => {
  return (
    <span
      className={
        clsx(
          styles.bold,
          styles[color]
        )
      }
    >
      {texte}
    </span>
  )
}

export default TableCellPaiementStatus
