import history from '../../Navigation/Includes/History'
import {
  GET_USER_DATA
} from './Data'
import {
  READY_PAGE
} from './Menu'
import {
  GET_CODES
} from './Codes'
import {
  GET_USERS
} from './Users'
import {
  GET_LANDING
} from './Landing'
import {
  GET_PLANNINGS
} from './Planning'
import {
  GET_SEMAINES
} from './Semaines'
import {
  GET_COMPANIES
} from './Companies'
import {
  api
} from '../../API/api'
import {
  save_token,
  delete_token
} from '../../API/token'
import {
  GET_NOTIFICATIONS
} from './Notifications'
import {
  print_info,
  print_error,
  update_toast,
  print_loading
} from '../../Functions/Toast'
import {
  get_id,
  redirection
} from '../../Functions/Functions'

export const FIRST_LOGIN = () => { // première connexion de l'utilisateur (affichage du message de bienvenue)
  return (dispatch) => {
    dispatch({
      type: 'FIRST_LOGIN'
    })
  }
}

export const SET_PENDING = (value) => { // attente de la réponse du serveur (on grise les boutons en attendant)
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'SET_PENDING'
    })
  }
}

export const SUCCESS_LOGIN = (profil) => { // récupération des données utilisateur
  return (dispatch) => {
    if (
      (profil === 'administrateur') ||
      (profil === 'utilisateur')
    ) {
      dispatch(GET_NOTIFICATIONS())
    }
    if (profil === 'administrateur') {
      dispatch(GET_SEMAINES())
    }
    if (profil !== 'gestionnaire') {
      dispatch(GET_USER_DATA())
      dispatch(GET_PLANNINGS())
    } else {
      dispatch(GET_COMPANIES())
      dispatch(GET_LANDING())
      dispatch(GET_CODES())
      dispatch(GET_USERS())
    }
  }
}

export const USER_LOGOUT = (info) => { // déconnexion de l'utilisateur
  return (dispatch) => {
    dispatch({
      type: 'LOGOUT'
    })
    dispatch(READY_PAGE(true))
    history.push('/login')
    api('user-logout')
      .then(data => {
        if (data.status === 'OK') {
          delete_token()
          if (info) {
            print_info(data.response)
          }
        } else {
          print_error(data.response)
        }
      })
      .catch(() => {
        print_error()
      })
  }
}

export const USER_LOGIN = (value, success) => { // connexion d'un utilisateur
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    api('user-login', {
      login: value.login,
      password: value.password
    }, false)
      .then(data => {
        if (data.status === 'OK') {
          dispatch(SET_PENDING(false))
          save_token('token', data.response.jwt)
          save_token('refresh', data.response.refresh)
          dispatch({
            type: 'SET_USER',
            value: data.data
          })
          dispatch({
            type: 'SET_ABONNEMENT',
            value: data.data.abonnement
          })
          dispatch({
            type: 'SET_VARIABLES',
            value: data.data.settings
          })
          dispatch({
            type: 'SET_COMPANIE',
            value: data.data.companie
          })
          dispatch({
            type: 'SET_FACTURES',
            value: data.data.factures
          })
          dispatch({
            type: 'SET_STRIPE',
            value: data.data.stripe
          })
          dispatch({
            type: 'SET_CARD',
            value: data.data.card
          })
          dispatch({
            type: 'SET_DIE',
            value: data.data.die
          })
          if (data.data.die === false) {
            dispatch(SUCCESS_LOGIN(data.data.profil))
          }
          success(data.data.die, redirection(data.data.beta, false))
          update_toast(toast, data.response.message, 'info')
        } else {
          dispatch(SET_PENDING(false))
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        dispatch(SET_PENDING(false))
        update_toast(toast, undefined, 'error')
      })
  }
}
