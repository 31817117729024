import React from 'react'
import ButtonGestion from './ButtonGestion'

const ButtonGestionDisconnect = (props) => {
  return (
    <ButtonGestion
      color="blue"
      icon="ExitToApp"
      title="Déconnecter"
      {...props}
    />
  )
}

export default ButtonGestionDisconnect
