import {
  USER_LOGOUT
} from './Login'
import {
  api
} from '../../API/api'
import {
  update_toast,
  print_loading
} from '../../Functions/Toast'
import {
  get_id
} from '../../Functions/Functions'

export const ADD_MAIL = (value) => { // ajout d'une adresse mail dans la liste des destinataires
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'ADD_MAIL'
    })
  }
}

export const DEL_MAIL = (value) => { // suppression d'une adresse mail dans la liste des destinataires
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'DEL_MAIL'
    })
  }
}

export const SET_MAIL = (item, value) => { // mise à jour d'une valeur pour l'envoi du mail
  return (dispatch) => {
    dispatch({
      item: item,
      value: value,
      type: 'SET_MAIL'
    })
  }
}

export const MAIL_ERROR = (item, value) => { // vérification des erreurs (sujet, texte, destinataires)
  return (dispatch) => {
    dispatch({
      item: item,
      value: value,
      type: 'MAIL_ERROR'
    })
  }
}

export const SEND_MAIL_ADMIN = (mails, sujet, texte, callback) => { // envoi du mail administrateur
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    api('send-mail-admin', {
      mails: mails,
      sujet: sujet,
      texte: texte
    })
      .then(data => {
        callback()
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        callback()
        update_toast(toast, undefined, 'error')
      })
  }
}
