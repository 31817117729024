import React from 'react'
import Titre from '../Misc/Titre'
import ButtonGestion from '../Button/ButtonGestion'
import ButtonMailSend from '../Button/ButtonMailSend'
import {
  useSelector
} from 'react-redux'
import {
  Paper,
  Toolbar
} from '@mui/material'
import {
  variables
} from '../../../Variables/Variables'

const ToolbarMail = ({
  mails,
  titre,
  ...others
}) => {
  const device = useSelector(state => state.device)
  return (device.mobile)
    ? <ButtonGestion
        icon="mail"
        {...others}
      />
    : <Paper>
        <Toolbar>
          <Titre>
            {titre}
            {
              (mails.length > 0)
                ? ' (' + mails.length + '/' + variables.mail.maximum + ')'
                : null
            }
          </Titre>
          <ButtonMailSend {...others}/>
        </Toolbar>
      </Paper>
}

export default ToolbarMail
