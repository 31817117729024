import {
  USER_LOGOUT
} from './Login'
import {
  UPDATE_PLANNING
} from './Planning'
import {
  api
} from '../../API/api'
import {
  update_toast,
  print_loading
} from '../../Functions/Toast'
import {
  get_id
} from '../../Functions/Functions'

export const MODAL_HEURES = (id, open, nombre, data) => { // modale pour créer, modifier ou supprimer une heure
  return (dispatch) => {
    dispatch({
      id: id,
      data: data,
      open: open,
      nombre: nombre,
      type: 'MODAL_HEURES'
    })
  }
}

export const DELETE_HEURE = (id) => { // suppression d'une heure
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch, state) => {
    api('delete-heure', {
      id: id
    })
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          const heures = state().heures.heures
          const ancien = heures.find(item => item.id === id)
          dispatch({
            value: id,
            type: 'DELETE_HEURE'
          })
          dispatch({
            value: id,
            ancien: ancien,
            type: 'DELETE_HEURE_PLANNING'
          })
          if (ancien.status === 1) {
            dispatch(UPDATE_PLANNING())
          }
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}

export const EDIT_HEURE = (value) => { // modification d'une heure
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch, state) => {
    api('edit-heure', {
      value: value
    })
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          const heures = state().heures.heures
          const ancien = heures.find(item => item.id === value.id)
          dispatch({
            value: value,
            type: 'EDIT_HEURE'
          })
          dispatch({
            value: value,
            ancien: ancien,
            type: 'EDIT_HEURE_PLANNING'
          })
          if (value.status === 1) {
            dispatch(UPDATE_PLANNING())
          }
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}

export const CREATE_HEURE = (value) => { // création d'une heure
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    api('create-heure', {
      value: value
    })
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          value.forEach(item => {
            dispatch({
              value: item,
              type: 'CREATE_HEURE'
            })
            if (item.status === 1) {
              dispatch({
                value: item,
                type: 'CREATE_HEURE_PLANNING'
              })
            }
          })
          if (value[0].status === 1) {
            dispatch(UPDATE_PLANNING())
          }
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}

export const UPDATE_HEURE = (id, value) => { // mise à jour du status d'une heure (0 => refusée, 1 => acceptée)
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch, state) => {
    api('update-heure', {
      id: id,
      value: value
    })
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          dispatch({
            id: id,
            value: value,
            type: 'UPDATE_HEURE'
          })
          if (value === 1) {
            const heures = state().heures.heures
            const heure = heures.find(item => item.id === id)
            dispatch({
              value: heure,
              type: 'CREATE_HEURE_PLANNING'
            })
            dispatch(UPDATE_PLANNING())
          }
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}
