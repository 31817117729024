import React from 'react'

const UserDelete = ({
  name,
  equipe
}) => {
  return (
    <span>
      <span className="block">Voulez-vous vraiment supprimer <span className="delete-name">{name}</span> ?</span>
      {
        (equipe)
          ? <span className="block">Cela effacera toutes les données relatives à cet utilisateur.</span>
          : null
      }
      <span className="block">Cette action est irréversible...</span>
    </span>
  )
}

export default UserDelete
