import React from 'react'
import ButtonArrow from '../Includes/Button/ButtonArrow'
import TableCellField from '../Includes/Table/TableCellField'
import TableCellTitre from '../Includes/Table/TableCellTitre'
import TableCellTitreUser from '../Includes/Table/TableCellTitreUser'
import TableMobileRowTitre from '../Includes/Table/TableMobileRowTitre'
import TableCellActionsUsers from '../Includes/Table/TableCellActionsUsers'
import {
  MODAL_EQUIPE
} from '../../Redux/Actions/Equipe'

const EquipeMobileTitre = ({
  item,
  type,
  expanded,
  setExpanded
}) => {
  return (
    <TableMobileRowTitre
      item={item}
      type={type}
    >
      <TableCellField>
        <ButtonArrow
          expanded={expanded}
          setExpanded={setExpanded}
        />
      </TableCellField>
      <TableCellTitre
        onClick={() => setExpanded(!expanded)}
      >
        <TableCellTitreUser
          item={item}
          type={type}
          long={true}
        />
      </TableCellTitre>
      <TableCellActionsUsers
        item={item}
        type={type}
        modal={MODAL_EQUIPE}
      />
    </TableMobileRowTitre>
  )
}

export default EquipeMobileTitre
