import Planning from '../Hooks/Planning'
import CreneauModalContent from './CreneauModalContent'
import React, {
  useEffect
} from 'react'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from '../Dialog/Dialog'
import {
  format_date,
  first_day_format,
  format_date_creneau
} from '../../../Functions/Dates'
import {
  get_heure_object
} from '../../../Functions/Heures'
import {
  get_id
} from '../../../Functions/Functions'
import {
  initial
} from '../../../Variables/Variables'
import {
  MODAL_PLANNING,
  ADD_CRENEAU_PLANNING
} from '../../../Redux/Actions/Planning'
import {
  ADD_CRENEAU_SEMAINE
} from '../../../Redux/Actions/Semaines'

const CreneauModal = () => {
  const planning = Planning()
  const dispatch = useDispatch()
  const device = useSelector(state => state.device)
  const day = useSelector(state => state.semaines.day)
  const date = useSelector(state => state.planning.date)
  const open = useSelector(state => state.planning.open)
  const view = useSelector(state => state.planning.view)
  const creneaux = useSelector(state => state.planning.planning)
  const [error, setError] = React.useState({
    jours: false,
    picker: false,
    slider: false
  })
  const [state, setState] = React.useState({
    total: 2,
    jours: [],
    users: [],
    am: [
      initial.creneau.am.debut.heure + (initial.creneau.am.debut.minute / 60),
      initial.creneau.am.fin.heure + (initial.creneau.am.fin.minute / 60)
    ],
    pm: [
      initial.creneau.pm.debut.heure + (initial.creneau.pm.debut.minute / 60),
      initial.creneau.pm.fin.heure + (initial.creneau.pm.fin.minute / 60)
    ]
  })
  const Update = (item, value) => {
    setState(current => ({
      ...current,
      [item]: value
    }))
  }
  const UpdateError = (item, value) => {
    setError(current => ({
      ...current,
      [item]: value
    }))
  }
  const Commit = () => {
    if (
      (state.jours.length > 0) &&
      (!error.slider) &&
      (
        (device.desktop) ||
        (
          (device.mobile) &&
          (!error.picker)
        )
      )
    ) {
      let existe
      let data = []
      let hour = false
      UpdateError('jours', false)
      if (planning) {
        state.jours.forEach(item => {
          data.push({
            id: get_id(),
            equipe: state.users,
            ...format_date_creneau(item, state.am)
          })
        })
        if (state.total === 2) {
          state.jours.forEach(item => {
            data.push({
              id: get_id(),
              equipe: state.users,
              ...format_date_creneau(item, state.pm)
            })
          })
        }
        const week = first_day_format(date)
        const semaine = creneaux.find(item => item.week === week)
        if (semaine !== undefined) {
          hour = (semaine.creneaux.filter(object => object.heure === true).length > 0)
          existe = (semaine.creneaux.filter(object => object.heure !== true).length > 0)
        }
        const value = (existe)
          ? data
          : {
              week: week,
              id: get_id(),
              creneaux: data
            }
        dispatch(ADD_CRENEAU_PLANNING(value, existe, week, hour))
      } else {
        state.jours.forEach(item => {
          data.push({
            id: get_id(),
            equipe: state.users,
            ...format_date({
              jour: item,
              fin: get_heure_object(state.am[1]),
              debut: get_heure_object(state.am[0])
            })
          })
        })
        if (state.total === 2) {
          state.jours.forEach(item => {
            data.push({
              id: get_id(),
              equipe: state.users,
              ...format_date({
                jour: item,
                fin: get_heure_object(state.pm[1]),
                debut: get_heure_object(state.pm[0])
              })
            })
          })
        }
        dispatch(ADD_CRENEAU_SEMAINE(data))
      }
      dispatch(MODAL_PLANNING('planning', false))
    } else {
      UpdateError('jours', (state.jours.length === 0))
    }
  }
  useEffect(() => {
    if (open.planning) {
      Update('total', 2)
      Update('users', [])
      UpdateError('jours', false)
      UpdateError('picker', false)
      Update('jours', (
        (view === 'Week')
          ? []
          : [day]
      ))
      Update('am', [
        initial.creneau.am.debut.heure + (initial.creneau.am.debut.minute / 60),
        initial.creneau.am.fin.heure + (initial.creneau.am.fin.minute / 60)
      ])
      Update('pm', [
        initial.creneau.pm.debut.heure + (initial.creneau.pm.debut.minute / 60),
        initial.creneau.pm.fin.heure + (initial.creneau.pm.fin.minute / 60)
      ])
    }
    // eslint-disable-next-line
  }, [open.planning])
  return (
    <Dialog
      maxWidth="md"
      fullWidth={true}
      open={open.planning}
    >
      <DialogTitle>Nouveau créneau</DialogTitle>
      <DialogContent>
        <CreneauModalContent
          error={error}
          state={state}
          Update={Update}
          UpdateError={UpdateError}
        />
      </DialogContent>
      <DialogActions
        buttons={[
          {
            onClick: () => dispatch(MODAL_PLANNING('planning', false))
          },
          {
            onClick: () => Commit()
          }
        ]}
      />
    </Dialog>
  )
}

export default CreneauModal
