import React from 'react'
import styles from './Input.module.css'
import InfoBulle from '../Wrapper/InfoBulle'
import InputTextField from './InputTextField'
import {
  useSelector
} from 'react-redux'
import {
  InputAdornment
} from '@mui/material'
import {
  CheckInt
} from '../../../Functions/Check'
import {
  convert_heure
} from '../../../Functions/Heures'

const InputTemps = ({
  error,
  value,
  ...others
}) => {
  const device = useSelector(state => state.device)
  const modal = useSelector(state => state.equipe.modal)
  const settings = useSelector(state => state.settings.variables)
  return (
    <InputTextField
      error={error}
      value={value}
      label="Compteur temps"
      InputProps={
        (error)
          ? null
          : {
              endAdornment: <>
                <InputAdornment position="end">{convert_heure(value, (value < 0))}</InputAdornment>
                {
                  (
                    (device.desktop) &&
                    (modal.open === 'edit')
                  )
                    ? <InfoBulle
                        placement="left"
                        style={styles.info}
                        title="Si vous modifiez le compteur temps, cela clôturera toutes les heures supplémentaires acceptées de l'utilisateur"
                        slotProps={{
                          tooltip: {
                            sx: {
                              width: 195
                            }
                          }
                        }}
                      />
                    : null
                }
              </>
            }
      }
      helperText={
        (error)
          ? (!CheckInt(value, false))
            ? "Le compteur temps doit être un entier de la forme -123 ou 123"
            : (parseInt(value) < settings.minimum.temps)
              ? "Le compteur temps doit être supérieur à " + settings.minimum.temps
              : (parseInt(value) > settings.maximum.temps)
                ? "Le compteur temps doit être inférieur à " + settings.maximum.temps
                : null
          : null
      }
      {...others}
    />
  )
}

export default InputTemps
