export const MENU_OPEN = (value) => { // ouvrir/fermer le menu hamburger
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'MENU_OPEN'
    })
  }
}

export const READY_PAGE = (value) => { // attendre que les éléments soient chargés avant d'afficher la page
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'READY_PAGE'
    })
  }
}
