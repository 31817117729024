import {
  USER_BACK
} from './User'
import {
  USER_LOGOUT
} from './Login'
import {
  api
} from '../../API/api'
import {
  update_toast,
  print_loading
} from '../../Functions/Toast'
import {
  get_id
} from '../../Functions/Functions'

export const CHECK_CODE = (code, abonnement, success, error) => { // vérification de l'existence du code en BDD
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    api('check-code', {
      code: code,
      abonnement: abonnement
    }, false)
      .then(data => {
        if (data.status === 'OK') {
          success(data.data)
          update_toast(toast, data.response, 'success')
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
          error()
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
        error()
      })
  }
}

export const UPDATE_COMPANIE_CARD = (payment) => { // mise à jour du moyen de paiement de l'entreprise
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    api('update-companie-card', {
      payment: payment
    })
      .then(data => {
        if (data.status === 'OK') {
          dispatch({
            value: false,
            type: 'SET_DIE'
          })
          dispatch({
            value: data.data,
            type: 'SET_CARD'
          })
          dispatch(USER_BACK())
          update_toast(toast, data.response, 'success')
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}

export const UPDATE_COMPANIE_ABONNEMENT = (abonnement, callback) => { // modification de l'abonnement de l'entreprise
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    api('update-companie-abonnement', {
      abonnement: abonnement
    })
      .then(data => {
        callback()
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          dispatch({
            type: 'SET_STRIPE',
            value: data.data.stripe
          })
          dispatch({
            type: 'SET_MAXIMUM',
            value: data.data.maximum
          })
          dispatch({
            type: 'SET_ABONNEMENT',
            value: data.data.abonnement
          })
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        callback()
        update_toast(toast, undefined, 'error')
      })
  }
}
