import dayjs from '../Libraries/dayjs'
import store from '../Redux/Store/Store'
import {
  get_id
} from './Functions'
import {
  variables
} from '../Variables/Variables'

export function get_creneau_object(object, format) {
  const value = Object.entries(object)[0]
  return {
    id: value[0],
    ...get_creneau_dates(value[1], format)
  }
}

export function format_creneau_date(date, format) {
  switch (format) {
    case 'iso':
      return date.toISOString()
    case 'date':
      return date.toDate()
    case 'unix':
      return date.unix()
    default:
      return date
  }
}

export function get_creneau_dates(dates, format) {
  const start = dayjs(dates.startDate)
  const end = dayjs(dates.endDate)
  const day = start.startOf('day')
  const maximum = day
    .add(variables.date.fin.heure, 'hours')
    .add(variables.date.fin.minute, 'minutes')
  const minimum = day
    .add(variables.date.debut.heure, 'hours')
    .add(variables.date.debut.minute, 'minutes')
  const fin = (end.isAfter(maximum)) ? maximum : end
  const debut = (start.isBefore(minimum)) ? minimum : start
  return {
    endDate: format_creneau_date(fin, format),
    startDate: format_creneau_date(debut, format)
  }
}

export function get_data_week(semaine, date) {
  return semaine.creneaux.map(item => {
    const fin = {
      heure: dayjs(item.endDate).hour(),
      minute: dayjs(item.endDate).minute(),
      jour: dayjs(item.endDate).weekday()
    }
    const debut = {
      heure: dayjs(item.startDate).hour(),
      minute: dayjs(item.startDate).minute(),
      jour: dayjs(item.startDate).weekday()
    }
    return {
      ...item,
      id: get_id(),
      startDate: dayjs(date)
        .add(debut.jour, 'days')
        .add(debut.heure, 'hours')
        .add(debut.minute, 'minutes')
        .toDate(),
      endDate: dayjs(date)
        .add(fin.jour, 'days')
        .add(fin.heure, 'hours')
        .add(fin.minute, 'minutes')
        .toDate()
    }
  })
}

export function get_planning_erreurs(data, planning) {
  let users = []
  let erreurs = []
  const equipe = store.getState().equipe.equipe
  equipe.forEach(item => {
    users[item.id] = []
  })
  data.forEach(week => {
    if (
      (
        (planning === true) &&
        (
          (week.heure !== true) ||
          (
            (week.heure === true) &&
            (week.sens === 0)
          )
        )
      ) || (planning !== true)
    ) {
      equipe.filter(item => week.equipe.includes(item.id)).forEach(item => {
        users[item.id].push({
          end: dayjs(week.endDate).unix(),
          start: dayjs(week.startDate).unix()
        })
      })
    }
  })
  Object.entries(users).forEach(item => {
    const user = item[0]
    const range = item[1].sort((a, b) => a.start - b.start)
    for (let i = 0; i < (range.length - 1); i++) {
      if (
        (range[i].start < range[i+1].end) &&
        (range[i].end > range[i+1].start)
      ) {
        erreurs.push({
          user: user,
          creneaux: [
            {
              end: dayjs.unix(range[i].end),
              start: dayjs.unix(range[i].start)
            },
            {
              end: dayjs.unix(range[i+1].end),
              start: dayjs.unix(range[i+1].start)
            }
          ]
        })
      }
    }
  })
  return erreurs
}
