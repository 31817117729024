import React from 'react'
import styles from './Compte.module.css'
import Titre from '../Includes/Misc/Titre'
import InputMail from '../Includes/Input/InputMail'
import ButtonCompteEdit from '../Includes/Button/ButtonCompteEdit'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  Paper,
  Toolbar
} from '@mui/material'
import {
  CheckMail
} from '../../Functions/Check'
import {
  UPDATE_USER_MAIL
} from '../../Redux/Actions/Compte'

const CompteMail = () => {
  const dispatch = useDispatch()
  const [error, setError] = React.useState(false)
  const device = useSelector(state => state.device)
  const user = useSelector(state => state.login.user)
  const [disabled, setDisabled] = React.useState(false)
  const [mail, setMail] = React.useState(
    (device.mobile)
      ? user.mail
      : ''
  )
  const Mail = (value) => {
    setMail(value)
    setError(
      (value !== '') &&
      (!CheckMail(value))
    )
  }
  const Commit = () => {
    if (CheckMail(mail)) {
      setError(false)
      setDisabled(true)
      dispatch(UPDATE_USER_MAIL(
        mail,
        () => setDisabled(false),
        () => {
          if (device.desktop) {
            setMail('')
          }
        }
      ))
    } else {
      setError(!CheckMail(mail))
    }
  }
  return (
    <Paper className={styles.mail}>
      <Toolbar>
        <Titre>Mon adresse mail</Titre>
        <ButtonCompteEdit
          disabled={disabled}
          onClick={() => Commit()}
        />
      </Toolbar>
      <div className="form-mail">
        <InputMail
          value={mail}
          error={error}
          setValue={Mail}
          className="input-mail"
          onKeyUp={(event) => {
            if (event.key === 'Enter') {
              Commit()
            }
          }}
        />
        {
          (device.desktop)
            ? <div className="compte-mail">{user.mail}</div>
            : null
        }
      </div>
    </Paper>
  )
}

export default CompteMail
