import React from 'react'
import Alert from '../Includes/Dialog/Alert'
import Planning from '../Includes/Hooks/Planning'
import ButtonDelete from '../Includes/Button/ButtonDelete'
import styles from './PlanningModalCreneauDelete.module.css'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  SELECTED_CRENEAU,
  DEL_CRENEAU_PLANNING
} from '../../Redux/Actions/Planning'
import {
  DEL_CRENEAU_SEMAINE
} from '../../Redux/Actions/Semaines'

const PlanningModalCreneauDelete = () => {
  const planning = Planning()
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false)
  const event = useSelector(state => state.planning.event)
  return (!event.read)
    ? <>
        <Alert
          open={open}
          titre="Confirmation"
          Cancel={() => setOpen(false)}
          texte="Voulez-vous vraiment supprimer ce créneau ?"
          Commit={() => {
            setOpen(false)
            dispatch(SELECTED_CRENEAU({
              id: '',
              read: false
            }))
            if (planning) {
              dispatch(DEL_CRENEAU_PLANNING(event.id))
            } else {
              dispatch(DEL_CRENEAU_SEMAINE(event.id))
            }
          }}
        />
        <ButtonDelete
          className={styles.delete}
          onClick={() => setOpen(true)}
        />
      </>
    : null
}

export default PlanningModalCreneauDelete
