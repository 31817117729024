export const SORT_SALARIES = (value) => { // ordre de tri des salariés
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'SORT_SALARIES'
    })
  }
}

export const SORT_FONCTIONS = (value) => { // ordre de tri des fonctions
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'SORT_FONCTIONS'
    })
  }
}
