import Alert from '../Dialog/Alert'
import TableData from '../Table/TableData'
import LegendeAccordion from './LegendeAccordion'
import React, {
  useEffect
} from 'react'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  sort_semaine
} from '../../../Functions/Sort'
import {
  first_day_format
} from '../../../Functions/Dates'
import {
  get_data_week
} from '../../../Functions/Planning'
import {
  get_id
} from '../../../Functions/Functions'
import {
  ADD_CRENEAU_PLANNING
} from '../../../Redux/Actions/Planning'
import {
  properties
} from '../../../Settings/Legende/Semaines'

const LegendeSemaines = () => {
  const dispatch = useDispatch()
  const [id, setId] = React.useState('')
  const [open, setOpen] = React.useState(false)
  const [commit, setCommit] = React.useState(false)
  const date = useSelector(state => state.planning.date)
  const planning = useSelector(state => state.planning.planning)
  const semaines = useSelector(state => state.semaines.semaines)
  const week = planning.find(item => item.week === first_day_format(date))
  const creneaux = (week !== undefined) ? week.creneaux.length : 0
  useEffect(() => {
    if (commit) {
      let existe
      let hour = false
      setCommit(false)
      const day = first_day_format(date)
      existe = planning.find(object => object.week === day)
      const semaine = semaines.find(object => object.id === id)
      if (existe !== undefined) {
        hour = (existe.creneaux.filter(object => object.heure === true).length > 0)
        existe = (existe.creneaux.filter(object => object.heure !== true).length > 0)
      }
      const data = get_data_week(semaine, date)
      const value = (existe)
        ? data
        : {
            week: day,
            id: get_id(),
            creneaux: data
          }
      dispatch(ADD_CRENEAU_PLANNING(value, existe, day, hour))
    }
    // eslint-disable-next-line
  }, [commit])
  return (
    <LegendeAccordion titre="Semaines">
      <Alert
        open={open}
        titre="Confirmation"
        Cancel={() => setOpen(false)}
        texte="Le planning n'est pas vide, voulez-vous vraiment ajouter une semaine supplémentaire ?"
        Commit={() => {
          setOpen(false)
          setCommit(true)
        }}
      />
      <TableData
        all={true}
        size="small"
        head={false}
        border={true}
        bottom={false}
        pointer={true}
        pagination={false}
        data={sort_semaine(semaines)}
        functions={{
          click: (id) => {
            setId(id)
            if (creneaux > 0) {
              setOpen(true)
            } else {
              setCommit(true)
            }
          }
        }}
        {...properties}
      />
    </LegendeAccordion>
  )
}

export default LegendeSemaines
