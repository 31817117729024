import Alert from '../Includes/Dialog/Alert'
import PlanningModalSemaineContent from './PlanningModalSemaineContent'
import React, {
  useEffect
} from 'react'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  first_day_format
} from '../../Functions/Dates'
import {
  get_data_week
} from '../../Functions/Planning'
import {
  get_id
} from '../../Functions/Functions'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from '../Includes/Dialog/Dialog'
import {
  ADD_CRENEAU_PLANNING
} from '../../Redux/Actions/Planning'

const PlanningModalSemaine = ({
  open,
  setOpen
}) => {
  const dispatch = useDispatch()
  const [id, setId] = React.useState('')
  const [popup, setPopup] = React.useState(false)
  const [commit, setCommit] = React.useState(false)
  const date = useSelector(state => state.planning.date)
  const planning = useSelector(state => state.planning.planning)
  const semaines = useSelector(state => state.semaines.semaines)
  const week = planning.find(item => item.week === first_day_format(date))
  const creneaux = (week !== undefined) ? week.creneaux.length : 0
  useEffect(() => {
    if (open) {
      setId('')
    }
    // eslint-disable-next-line
  }, [open])
  useEffect(() => {
    if (commit) {
      let existe
      let hour = false
      setCommit(false)
      const day = first_day_format(date)
      existe = planning.find(object => object.week === day)
      const semaine = semaines.find(object => object.id === id)
      if (existe !== undefined) {
        hour = (existe.creneaux.filter(object => object.heure === true).length > 0)
        existe = (existe.creneaux.filter(object => object.heure !== true).length > 0)
      }
      const data = get_data_week(semaine, date)
      const value = (existe)
        ? data
        : {
            week: day,
            id: get_id(),
            creneaux: data
          }
      dispatch(ADD_CRENEAU_PLANNING(value, existe, day, hour))
    }
    // eslint-disable-next-line
  }, [commit])
  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth={true}
    >
      <DialogTitle>Semaines</DialogTitle>
      <DialogContent>
        <Alert
          open={popup}
          titre="Confirmation"
          Cancel={() => setPopup(false)}
          texte="Le planning n'est pas vide, voulez-vous vraiment ajouter une semaine supplémentaire ?"
          Commit={() => {
            setOpen(false)
            setPopup(false)
            setCommit(true)
          }}
        />
        <PlanningModalSemaineContent
          id={id}
          setId={setId}
        />
      </DialogContent>
      <DialogActions
        buttons={[
          {
            onClick: () => setOpen(false)
          },
          {
            onClick: () => {
              if (id === '') {
                setOpen(false)
              } else {
                if (creneaux > 0) {
                  setPopup(true)
                } else {
                  setOpen(false)
                  setCommit(true)
                }
              }
            }
          }
        ]}
      />
    </Dialog>
  )
}

export default PlanningModalSemaine
