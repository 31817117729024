import React from 'react'
import Absences from '../Hooks/Absences'
import styles from './Toolbar.module.css'
import dayjs from '../../../Libraries/dayjs'
import ButtonToday from '../Button/ButtonToday'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  first_day_format
} from '../../../Functions/Dates'
import {
  SELECTED_ABSENCE,
  SET_DATE_ABSENCES
} from '../../../Redux/Actions/Absences'
import {
  SELECTED_CRENEAU,
  SET_DATE_PLANNING
} from '../../../Redux/Actions/Planning'

const ToolbarToday = () => {
  const absences = Absences()
  const dispatch = useDispatch()
  const view = useSelector(state => state.planning.view)
  const date = {
    current: dayjs(),
    absences: useSelector(state => state.absences.date),
    planning: useSelector(state => state.planning.date)
  }
  const current = (absences)
    ? (date.absences.format('YYYYMM') === dayjs().format('YYYYMM'))
    : (date.planning.format('YYYYMMDD') === first_day_format(date.current, view))
  return (
    <ButtonToday
      className={
        (current)
          ? styles.color
          : undefined
      }
      onClick={() => {
        if (!current) {
          if (absences) {
            dispatch(SELECTED_ABSENCE(''))
            dispatch(SET_DATE_ABSENCES(date.current))
          } else {
            dispatch(SELECTED_CRENEAU({
              id: '',
              read: false
            }))
            dispatch(SET_DATE_PLANNING(date.current))
          }
        }
      }}
    />
  )
}

export default ToolbarToday
