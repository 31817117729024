import dayjs from '../../Libraries/dayjs'
import ComptabiliteModalSave from './ComptabiliteModalSave'
import ComptabiliteModalPrimes from './ComptabiliteModalPrimes'
import ComptabiliteModalCommentaire from './ComptabiliteModalCommentaire'
import React, {
  useEffect
} from 'react'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  PDF
} from '../../Functions/PDF'
import {
  sort_name_by_id
} from '../../Functions/Sort'
import {
  CheckComment
} from '../../Functions/Check'
import {
  get_id
} from '../../Functions/Functions'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from '../Includes/Dialog/Dialog'
import {
  CUT_ABSENCE,
  CLOSE_ABSENCE_COMPTABILITE
} from '../../Redux/Actions/Absences'
import {
  UPDATE_CRENEAU_PLANNING_STATUS
} from '../../Redux/Actions/Planning'
import {
  SAVE_PRIMES,
  UPDATE_CLOSE,
  SEND_MAIL_COMPTABLE
} from '../../Redux/Actions/Comptabilite'

const ComptabiliteModal = ({
  ik,
  open,
  hours,
  cutter,
  setOpen,
  holidays
}) => {
  const dispatch = useDispatch()
  const equipe = useSelector(state => state.equipe.equipe)
  const heures = useSelector(state => state.heures.heures)
  const pdf = useSelector(state => state.comptabilite.pdf)
  const dates = useSelector(state => state.comptabilite.dates)
  const liste = useSelector(state => state.comptabilite.primes)
  const settings = useSelector(state => state.settings.settings)
  const selected = useSelector(state => state.comptabilite.selected)
  const [error, setError] = React.useState({
    user: false,
    motif: false,
    montant: false,
    commentaire: false
  })
  const [state, setState] = React.useState({
    step: 1,
    motif: '',
    users: [],
    erreur: [],
    primes: [],
    montant: '',
    salaries: [],
    checked: false,
    commentaire: ''
  })
  const Update = (item, value) => {
    setState(current => ({
      ...current,
      [item]: value
    }))
  }
  const UpdateError = (item, value) => {
    setError(current => ({
      ...current,
      [item]: value
    }))
  }
  const Commit = () => {
    if (CheckComment(state.commentaire)) {
      UpdateError('commentaire', false)
      const object = {
        id: get_id(),
        date: dayjs().unix(),
        texte: state.commentaire,
        fin: dayjs(dates[1]).format('DD/MM/YYYY'),
        debut: dayjs(dates[0]).format('DD/MM/YYYY'),
        pdf: PDF(
          pdf,
          dayjs(dates[0]),
          dayjs(dates[1]),
          true,
          settings.find(item => item.id === 'detail').value,
          state.primes.map(item => {
            return {
              ...item,
              users: sort_name_by_id(item.users).map(id => {
                const user = equipe.find(object => object.id === id)
                return {
                  id: id,
                  nom: user.nom,
                  prenom: user.prenom
                }
              })
            }
          })
        )
      }
      setOpen(false)
      dispatch(SEND_MAIL_COMPTABLE(object))
      if (cutter.length > 0) {
        dispatch(CUT_ABSENCE(cutter))
      }
      dispatch(CLOSE_ABSENCE_COMPTABILITE(holidays))
      dispatch(UPDATE_CLOSE('heure', hours.map(item => item.id)))
      dispatch(UPDATE_CLOSE('indemnite', ik.map(item => item.id)))
      hours.forEach(item => {
        const heure = heures.find(object => object.id === item.id)
        dispatch(UPDATE_CRENEAU_PLANNING_STATUS(heure))
      })
      dispatch(SAVE_PRIMES(
        (state.checked)
          ? state.primes
          : []
      ))
      Reset(true)
    } else {
      UpdateError('commentaire', !CheckComment(state.commentaire))
    }
  }
  const Reset = (commit = false) => {
    UpdateError('commentaire', false)
    UpdateError('montant', false)
    UpdateError('motif', false)
    UpdateError('user', false)
    Update('checked', false)
    Update('commentaire', '')
    Update('montant', '')
    Update('motif', '')
    Update('users', [])
    Update('step', 1)
    Update('primes', (
      (commit)
        ? (state.checked)
          ? state.primes
          : []
        : liste
    ))
  }
  useEffect(() => {
    if (state.erreur.length === 0) {
      Update('primes', liste)
    } else {
      Update('primes', (
        liste.map(item => {
          return {
            ...item,
            users: item.users.filter(object => state.erreur.indexOf(object) < 0)
          }
        }).filter(item => item.users.length > 0)
      ))
    }
    // eslint-disable-next-line
  }, [state.erreur])
  useEffect(() => {
    let array = []
    liste.forEach(item => array.push(...item.users))
    Update('erreur', array.filter(item => selected.indexOf(item) < 0))
    // eslint-disable-next-line
  }, [open, selected])
  useEffect(() => {
    let array = []
    state.primes.forEach(item => array.push(...item.users))
    Update('salaries', equipe.filter(item => selected.filter(item => array.indexOf(item) < 0).includes(item.id)))
    // eslint-disable-next-line
  }, [selected, state.primes])
  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth={true}
      className="dialog-comptabilite"
    >
      <DialogTitle>
        Mail au comptable ({state.step}/2)
      </DialogTitle>
      <DialogContent>
        {
          (state.step === 1)
            ? <ComptabiliteModalPrimes
                error={error}
                state={state}
                Update={Update}
                UpdateError={UpdateError}
              />
            : <ComptabiliteModalCommentaire
                error={error}
                state={state}
                Update={Update}
                UpdateError={UpdateError}
              />
        }
      </DialogContent>
      <DialogActions
        buttons={[
          {
            text: (state.step === 1)
              ? 'Annuler'
              : 'Retour',
            onClick: () => {
              if (state.step === 1) {
                setOpen(false)
                Reset()
              } else {
                Update('step', 1)
              }
            }
          },
          {
            text: (state.step === 1)
              ? 'Suivant'
              : 'Envoyer',
            onClick: () => {
              if (state.step === 1) {
                Update('step', 2)
              } else {
                Commit()
              }
            }
          }
        ]}
      >
        {
          (state.step === 1)
            ? <ComptabiliteModalSave
                checked={state.checked}
                setChecked={(value) => Update('checked', value)}
              />
            : null
        }
      </DialogActions>
    </Dialog>
  )
}

export default ComptabiliteModal
