import {
  api
} from '../../API/api'
import {
  print_error
} from '../../Functions/Toast'

export const GET_NEWSLETTER = (token, success, error) => { // récupération de la valeur de newsletter
  return () => {
    api('get-newsletter', {
      token: token
    }, false)
      .then(data => {
        if (data.status === 'OK') {
          success(data.response)
        } else {
          error()
        }
      })
      .catch(() => {
        error()
      })
  }
}

export const UPDATE_NEWSLETTER = (token, value, pending, success) => { // mise à jour de la valeur de newsletter
  return () => {
    pending(true)
    api('update-newsletter', {
      token: token,
      value: value
    }, false)
      .then(data => {
        if (data.status === 'OK') {
          success()
        } else {
          print_error(data.response)
        }
        pending(false)
      })
      .catch(() => {
        print_error()
        pending(false)
      })
  }
}
