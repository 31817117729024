import dayjs from '../../Libraries/dayjs'
import {
  USER_LOGOUT
} from './Login'
import {
  SET_FILTERS_PAGE_RESET_SALARIE
} from './Filters'
import {
  UPDATE_PLANNING
} from './Planning'
import {
  api
} from '../../API/api'
import {
  UPDATE_CLOSE
} from './Comptabilite'
import {
  update_toast,
  print_loading
} from '../../Functions/Toast'
import {
  get_id
} from '../../Functions/Functions'

export const MODAL_EQUIPE = (id, open, name) => { // modale pour créer, modifier, archiver, supprimer ou réinitialiser un utilisateur
  return (dispatch) => {
    dispatch({
      id: id,
      name: name,
      open: open,
      type: 'MODAL_EQUIPE'
    })
  }
}

export const RESET_USER = (id) => { // réinitialisation du mot de passe de l'utilisateur (envoi du mail)
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    api('reset-user', {
      id: id
    })
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}

export const ARCHIVE_USER = (id, value) => { // archiver/désarchiver un utilisateur
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    api('archive-user', {
      id: id,
      value: value
    })
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          dispatch(SET_FILTERS_PAGE_RESET_SALARIE(id))
          dispatch({
            id: id,
            value: value,
            type: 'ARCHIVE_USER'
          })
          dispatch({
            id: id,
            type: 'ARCHIVE_USER_COMPTABILITE'
          })
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}

export const DELETE_USER = (id) => { // suppression d'un utilisateur
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch, state) => {
    api('delete-user', {
      id: id
    })
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          dispatch({
            value: id,
            type: 'DELETE_USER'
          })
          dispatch({
            value: id,
            type: 'DELETE_USER_HEURES'
          })
          dispatch({
            value: id,
            type: 'DELETE_USER_PRIMES'
          })
          dispatch({
            value: id,
            type: 'DELETE_USER_ABSENCES'
          })
          dispatch({
            value: id,
            type: 'DELETE_USER_PLANNINGS'
          })
          dispatch({
            value: id,
            type: 'DELETE_USER_INDEMNITES'
          })
          dispatch({
            value: id,
            type: 'DELETE_USER_NOTIFICATIONS'
          })
          dispatch(UPDATE_PLANNING())
          dispatch(SET_FILTERS_PAGE_RESET_SALARIE(id))
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}

export const MANAGE_USER = (value, action) => { // création/modification d'un utilisateur
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch, state) => {
    api(action, {
      value: value
    })
      .then(data => {
        if (data.status === 'OK') {
          if (action === 'create-user') {
            dispatch({
              value: value,
              type: 'CREATE_USER'
            })
          } else {
            let array = []
            if (value.commit === 1) {
              const heures = state().heures.heures
              array = heures.filter(item =>
                (item.recuperation === 1) &&
                (item.user === value.id) &&
                (item.status === 1)
              ).map(item => item.id)
              dispatch(UPDATE_CLOSE('heure', array, value))
            } else if (value.conges === 1) {
              const absences = state().absences.absences
              array = absences.filter(item =>
                (dayjs.unix(item.fin).isSameOrBefore(dayjs())) &&
                (item.user === value.id) &&
                (item.motif === 'conge') &&
                (item.status === 1)
              ).map(item => item.id)
              dispatch(UPDATE_CLOSE('absence', array, value))
            } else {
              dispatch({
                value: value,
                type: 'EDIT_USER'
              })
            }
          }
          update_toast(toast, data.response, 'success')
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}
