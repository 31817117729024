const initial = {
  open: false,
  selected: [],
  notification: '',
  notifications: []
}

function notifications(state = initial, action) {
  switch (action.type) {
    case 'GET_NOTIFICATIONS':
      return {
        ...state,
        notifications: action.value
      }
    case 'MODAL_NOTIFICATIONS':
      return {
        ...state,
        open: action.value
      }
    case 'SELECTED_NOTIFICATION':
      return {
        ...state,
        notification: action.value
      }
    case 'SELECTED_NOTIFICATIONS':
      return {
        ...state,
        selected: action.value
      }
    case 'DELETE_NOTIFICATION':
      return {
        ...state,
        selected: state.selected.filter(item => action.value.indexOf(item) < 0),
        notifications: state.notifications.filter(item => action.value.indexOf(item.id) < 0)
      }
    case 'DELETE_USER_NOTIFICATIONS':
      return {
        ...state,
        notifications: state.notifications.filter(item =>
          (item.admin !== action.value) &&
          (item.user !== action.value)
        )
      }
    case 'READ_NOTIFICATION':
      return {
        ...state,
        notifications: state.notifications.map(item => {
          return (action.value.includes(item.id))
            ? {
                ...item,
                read: (action.read)
                  ? [
                      ...item.read,
                      action.user
                    ]
                  : item.read.filter(item => item !== action.user)
              }
            : item
        })
      }
    default:
      return state
  }
}

export default notifications
