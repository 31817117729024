import dayjs from '../../../Libraries/dayjs'
import LegendeCreneauModalContent from './LegendeCreneauModalContent'
import React, {
  useEffect
} from 'react'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from '../Dialog/Dialog'
import {
  first_day_format
} from '../../../Functions/Dates'
import {
  isDifferent
} from '../../../Functions/Compare'
import {
  get_id
} from '../../../Functions/Functions'
import {
  EDIT_CRENEAU_PLANNING
} from '../../../Redux/Actions/Planning'

const LegendeCreneauModal = ({
  id,
  open,
  setOpen
}) => {
  const dispatch = useDispatch()
  const date = useSelector(state => state.planning.date)
  const planning = useSelector(state => state.planning.planning)
  const creneau = planning.filter(item => item.week === first_day_format(date)).map(item => item.creneaux).flat().find(item => item.id === id)
  const initial = {
    users: creneau.equipe.sort(),
    day: dayjs(creneau.startDate).startOf('day'),
    heures: [
      dayjs(creneau.startDate).hour() + (dayjs(creneau.startDate).minute() / 60),
      dayjs(creneau.endDate).hour() + (dayjs(creneau.endDate).minute() / 60)
    ]
  }
  const [state, setState] = React.useState({
    users: [],
    heures: [],
    day: dayjs()
  })
  const Update = (item, value) => {
    setState(current => ({
      ...current,
      [item]: value
    }))
  }
  const Commit = () => {
    setOpen(false)
    if (isDifferent([state], [initial])) {
      const value = {
        id: creneau.id,
        semaine: get_id(),
        equipe: state.users,
        endDate: state.day.add(state.heures[1], 'hours').toDate(),
        startDate: state.day.add(state.heures[0], 'hours').toDate()
      }
      dispatch(EDIT_CRENEAU_PLANNING({
        ...value,
        week: first_day_format(value.startDate)
      }))
    }
  }
  useEffect(() => {
    if (open) {
      Update('day', initial.day)
      Update('users', initial.users)
      Update('heures', initial.heures)
    }
    // eslint-disable-next-line
  }, [open])
  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth={true}
    >
      <DialogTitle>Modifier le créneau</DialogTitle>
      <DialogContent>
        <LegendeCreneauModalContent
          state={state}
          Update={Update}
        />
      </DialogContent>
      <DialogActions
        buttons={[
          {
            onClick: () => setOpen(false)
          },
          {
            onClick: () => Commit()
          }
        ]}
      />
    </Dialog>
  )
}

export default LegendeCreneauModal
