import {
  USER_LOGOUT
} from './Login'
import {
  api
} from '../../API/api'
import {
  update_toast,
  print_loading
} from '../../Functions/Toast'
import {
  get_id
} from '../../Functions/Functions'

export const MODAL_INDEMNITES = (id, open) => { // modale pour créer, modifier ou supprimer une indemnité
  return (dispatch) => {
    dispatch({
      id: id,
      open: open,
      type: 'MODAL_INDEMNITES'
    })
  }
}

export const DELETE_INDEMNITE = (id) => { // suppression d'une indemnité
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    api('delete-indemnite', {
      id: id
    })
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          dispatch({
            value: id,
            type: 'DELETE_INDEMNITE'
          })
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}

export const EDIT_INDEMNITE = (value) => { // modification d'une indemnité
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    api('edit-indemnite', {
      value: value
    })
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          dispatch({
            value: value,
            type: 'EDIT_INDEMNITE'
          })
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}

export const CREATE_INDEMNITE = (value) => { // création d'une indemnité
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    api('create-indemnite', {
      value: [value]
    })
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          dispatch({
            value: value,
            type: 'CREATE_INDEMNITE'
          })
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}

export const UPDATE_INDEMNITE = (id, value) => { // mise à jour du status d'une indemnité (0 => refusée, 1 => acceptée)
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    api('update-indemnite', {
      id: id,
      value: value
    })
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          dispatch({
            id: id,
            value: value,
            type: 'UPDATE_INDEMNITE'
          })
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}
