import React from 'react'
import PlanningSelect from './PlanningSelect'
import PlanningUserMobile from './PlanningUserMobile'
import {
  useSelector
} from 'react-redux'
import {
  sort_name
} from '../../Functions/Sort'

const PlanningModalCreneauUsers = ({
  data,
  state,
  Update
}) => {
  const event = useSelector(state => state.planning.event)
  const equipe = useSelector(state => state.equipe.equipe)
  const team = equipe.filter(item => item.archive === 0)
  const users = equipe.filter(item =>
    (item.archive === 1) &&
    (data.equipe.includes(item.id))
  )
  return (
    <>
      {
        (
          (!data.heure) &&
          (!event.read) &&
          (equipe.length > 0)
        )
          ? <PlanningSelect
              planning={true}
              empty={(state.excludes.length === 0)}
              None={() => {
                Update('users', users)
                Update('excludes', team)
              }}
              All={() => {
                Update('excludes', [])
                Update('users', [ ...team, ...users ])
              }}
            />
          : null
      }
      {
        (
          (!data.heure) &&
          (!event.read) &&
          (state.excludes.length > 0)
        )
          ? <div className="semaines-form-equipe">
              {
                sort_name(state.excludes).map(item => {
                  return (
                    <PlanningUserMobile
                      data={data}
                      item={item}
                      inner={false}
                      state={state}
                      key={item.id}
                      Update={Update}
                    />
                  )
                })
              }
            </div>
          : null
      }
    </>
  )
}

export default PlanningModalCreneauUsers
