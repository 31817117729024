import React from 'react'
import ButtonFilter from '../Button/ButtonFilter'
import FilterGestionComponent from './FilterGestionComponent'
import {
  isDifferent
} from '../../../Functions/Compare'
import {
  filtres
} from '../../../Variables/Variables'

const FilterGestion = ({
  data,
  label,
  state,
  target,
  setPage,
  setState
}) => {
  const [open, setOpen] = React.useState(false)
  return (data.length > 0)
    ? <>
        <FilterGestionComponent
          open={open}
          label={label}
          values={state}
          target={target}
          setOpen={setOpen}
          Commit={(value) => {
            setPage(0)
            setState(value)
          }}
        />
        <ButtonFilter
          onClick={() => setOpen(true)}
        />
        {
          (isDifferent([state], [filtres[target]]))
            ? <ButtonFilter
                off={true}
                onClick={() => {
                  setPage(0)
                  setState(filtres[target])
                }}
              />
            : null
        }
      </>
    : null
}

export default FilterGestion
