import TableCellDateFull from '../Components/Includes/Table/TableCellDateFull'
import TableCellActionsExports from '../Components/Includes/Table/TableCellActionsExports'
import {
  fin
} from './Includes/Fin'
import {
  date
} from './Includes/Date'
import {
  debut
} from './Includes/Debut'
import {
  actions
} from './Includes/Actions'

export const properties = {
  type: {
    id: "exports",
    groupe: "comptabilite"
  },
  sort: {
    by: "date",
    order: "desc"
  },
  columns: [
    {
      ...date,
      component: TableCellDateFull
    },
    {
      ...debut
    },
    {
      ...fin
    },
    {
      ...actions,
      component: TableCellActionsExports
    }
  ]
}
