import React from 'react'
import TableCellCheckbox from './TableCellCheckbox'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  SORT_SALARIES,
  SORT_FONCTIONS
} from '../../../Redux/Actions/Sort'
import {
  ADD_FILTER_SALARIE,
  DEL_FILTER_SALARIE
} from '../../../Redux/Actions/Filters'

const TableCellCheckboxSalaries = ({ item }) => {
  const dispatch = useDispatch()
  const equipe = useSelector(state => state.equipe.equipe)
  const filter = useSelector(state => state.filters.salaries)
  const selected = equipe.map(item => item.id).filter(item => filter.indexOf(item) < 0)
  const Change = () => {
    dispatch(
      (selected.includes(item.id))
        ? ADD_FILTER_SALARIE(item.id)
        : DEL_FILTER_SALARIE(item.id)
    )
    dispatch(SORT_SALARIES({
      by: 'selected',
      order: 'desc'
    }))
    dispatch(SORT_FONCTIONS({
      by: 'selected',
      order: 'desc'
    }))
  }
  return (
    <TableCellCheckbox
      checked={selected.includes(item.id)}
      onClick={(event) => {
        event.stopPropagation()
        Change()
      }}
    />
  )
}

export default TableCellCheckboxSalaries
