import '../../Variables/Types.js'
import dayjs from '../../Libraries/dayjs'
import {
  USER_LOGOUT
} from './Login'
import {
  base64StringToBlob
} from 'blob-util'
import {
  saveAs
} from 'file-saver'
import {
  api
} from '../../API/api'
import {
  first_day_format
} from '../../Functions/Dates'
import {
  print_error,
  update_toast,
  print_loading
} from '../../Functions/Toast'
import {
  get_creneau_object,
  get_planning_erreurs
} from '../../Functions/Planning'
import {
  get_id
} from '../../Functions/Functions'

/**
 * Affiche le créneau sélectionné par l'utilisateur dans la légende du scheduler.
 * 
 * @param {Object} value Détails de l'objet.
 * @param {string} value.id ID du créneau.
 * @param {boolean} value.read Est-ce que le créneau est en lecture seule (soit parce qu'on visionne un planning, soit parce que l'utilisateur n'a pas les droits de modification).
 * @param {boolean} value.heure Est-ce que le créneau est une heure.
 * @param {boolean} value.mobile Est-ce que l'utilisateur utilise un mobile.
 * @returns {Function} Une fonction redux thunk.
 */
export const SELECTED_CRENEAU = (value) => {
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'SELECTED_CRENEAU'
    })
  }
}

/**
 * Sauvegarde les informations pour l'envoi du planning par mail.
 * 
 * @param {PDF} value Détails de l'objet.
 * @returns {Function} Une fonction redux thunk.
 */
export const SET_PDF_PLANNING = (value) => {
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'SET_PDF_PLANNING'
    })
  }
}

/**
 * Définit l'affichage du scheduler (jour/semaine).
 * 
 * @param {string} value Valeur du paramètre d'affichage (Day/Week).
 * @returns {Function} Une fonction redux thunk.
 */
export const SET_PLANNING_VIEW = (value) => {
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'SET_PLANNING_VIEW'
    })
  }
}

/**
 * L'utilisateur clique sur un jour de la semaine dans le planning.
 * 
 * @param {Date} value Date du jour sélectionné.
 * @returns {Function} Une fonction redux thunk.
 */
export const SELECT_PLANNING_DAY = (value) => {
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'SELECT_PLANNING_DAY'
    })
  }
}

/**
 * Affiche/masque les horaires d'ouverture sur le planning.
 * 
 * @param {boolean} value Valeur de l'affichage (true/false).
 * @returns {Function} Une fonction redux thunk.
 */
export const SHOW_PLANNING_HORAIRES = (value) => {
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'SHOW_PLANNING_HORAIRES'
    })
  }
}

/**
 * Définit la liste des salariés à afficher au survol dans la légende si le créneau ne le permet pas.
 * 
 * @param {User} value Les informations de l'utilisateur.
 * @returns {Function} Une fonction redux thunk.
 */
export const SET_CRENEAU_SURVOL_EQUIPE = (value) => {
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'SET_CRENEAU_SURVOL_EQUIPE'
    })
  }
}

/**
 * Met le statut d'un créneau (heure) à jour afin de le figer dans le planning (status = 3).
 * 
 * @param {Heure} value Les informations de l'heure.
 * @returns {Function} Une fonction redux thunk.
 */
export const UPDATE_CRENEAU_PLANNING_STATUS = (value) => {
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'UPDATE_CRENEAU_PLANNING_STATUS'
    })
  }
}

/**
 * Date sélectionnée dans le planning.
 * 
 * @param {Date|dayjs} value Date sélectionnée.
 * @returns {Function} Une fonction redux thunk.
 */
export const SET_DATE_PLANNING = (value) => {
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'SET_DATE_PLANNING'
    })
    dispatch(UPDATE_PLANNING())
  }
}

/**
 * Modale de planning.
 * 
 * @param {string} item La modale à ouvrir/fermer (share, wizard, planning)
 * @param {boolean} value Valeur de l'affichage (true/false).
 * @returns {Function} Une fonction redux thunk.
 */
export const MODAL_PLANNING = (item, value) => {
  return (dispatch) => {
    dispatch({
      item: item,
      value: value,
      type: 'MODAL_PLANNING'
    })
  }
}

/**
 * Récupération des plannings en BDD.
 * 
 * @returns {Function} Une fonction redux thunk ou un message d'erreur.
 */
export const GET_PLANNINGS = () => {
  return (dispatch) => {
    api('get-plannings')
      .then(data => {
        if (data.status === 'OK') {
          dispatch({
            value: data.response,
            type: 'GET_PLANNINGS'
          })
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          print_error(data.response)
        }
      })
      .catch(() => {
        print_error()
      })
  }
}

/**
 * Suppression d'un créneau dans le planning.
 * 
 * @param {string} value ID du créneau.
 * @returns {Function} Une fonction redux thunk ou un message d'erreur.
 */
export const DEL_CRENEAU_PLANNING = (value) => {
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'DEL_CRENEAU_PLANNING'
    })
    api('del-creneau-planning', {
      value: value
    })
      .then(data => {
        if (data.status !== 'OK') {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          print_error(data.response)
        }
      })
      .catch(() => {
        print_error()
      })
  }
}

/**
 * Modifie un créneau dans le planning.
 * 
 * @param {Creneau} value Détails de l'objet.
 * @returns {Function} Une fonction redux thunk ou un message d'erreur.
 */
export const EDIT_CRENEAU_PLANNING = (value) => {
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'EDIT_CRENEAU_PLANNING'
    })
    api('edit-creneau-planning', {
      value: value
    })
      .then(data => {
        if (data.status !== 'OK') {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          print_error(data.response)
        }
      })
      .catch(() => {
        print_error()
      })
  }
}

/**
 * Vide les salariés d'un créneau dans le planning.
 * 
 * @param {string} value ID du créneau.
 * @returns {Function} Une fonction redux thunk ou un message d'erreur.
 */
export const EMPTY_CRENEAU_PLANNING = (value) => {
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'EMPTY_CRENEAU_PLANNING'
    })
    api('empty-creneau-planning', {
      value: value
    })
      .then(data => {
        if (data.status !== 'OK') {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          print_error(data.response)
        }
      })
      .catch(() => {
        print_error()
      })
  }
}

/**
 * Supprime tous les créneaux du jour dans le planning.
 * 
 * @param {Object} value Détails de l'objet.
 * @param {string} value.week Lundi de la semaine au format YYYYMMDD.
 * @param {string} value.day Date du jour de la semaine à supprimer au format YYYY-MM-DD.
 * @returns {Function} Une fonction redux thunk ou un message d'erreur.
 */
export const DEL_CRENEAU_PLANNING_DAY = (value) => {
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'DEL_CRENEAU_PLANNING_DAY'
    })
    api('del-creneau-planning-day', {
      day: value.day
    })
      .then(data => {
        if (data.status !== 'OK') {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          print_error(data.response)
        }
      })
      .catch(() => {
        print_error()
      })
  }
}

/**
 * Ajout d'un salarié dans le créneau du planning.
 * 
 * @param {Object} value Détails de l'objet.
 * @param {string} value.user ID du salarié.
 * @param {string} value.creneau ID du créneau.
 * @returns {Function} Une fonction redux thunk ou un message d'erreur.
 */
export const ADD_CRENEAU_PLANNING_USER = (value) => {
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'ADD_CRENEAU_PLANNING_USER'
    })
    api('add-creneau-planning-user', {
      value: value
    })
      .then(data => {
        if (data.status !== 'OK') {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          print_error(data.response)
        }
      })
      .catch(() => {
        print_error()
      })
  }
}

/**
 * Suppression d'un salarié dans le créneau du planning.
 * 
 * @param {Object} value Détails de l'objet.
 * @param {string} value.user ID du salarié.
 * @param {string} value.creneau ID du créneau.
 * @returns {Function} Une fonction redux thunk ou un message d'erreur.
 */
export const DEL_CRENEAU_PLANNING_USER = (value) => {
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'DEL_CRENEAU_PLANNING_USER'
    })
    api('del-creneau-planning-user', {
      value: value
    })
      .then(data => {
        if (data.status !== 'OK') {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          print_error(data.response)
        }
      })
      .catch(() => {
        print_error()
      })
  }
}

/**
 * Supprime tous les créneaux de la semaine dans le planning.
 * 
 * @param {string} value Lundi de la semaine au format YYYYMMDD.
 * @returns {Function} Une fonction redux thunk ou un message d'erreur.
 */
export const DEL_CRENEAU_PLANNING_WEEK = (value) => {
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'DEL_CRENEAU_PLANNING_WEEK'
    })
    api('del-creneau-planning-week', {
      week: value
    })
      .then(data => {
        if (data.status !== 'OK') {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          print_error(data.response)
        }
      })
      .catch(() => {
        print_error()
      })
  }
}

/**
 * Clonage d'un créneau dans le planning.
 * 
 * @param {Creneau} value Détails de l'objet.
 * @param {string} day Lundi de la semaine au format YYYYMMDD. 
 * @returns {Function} Une fonction redux thunk ou un message d'erreur.
 */
export const CLONE_CRENEAU_PLANNING = (value, day) => {
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'CLONE_CRENEAU_PLANNING'
    })
    api('clone-creneau-planning', {
      week: day,
      value: value
    })
      .then(data => {
        if (data.status !== 'OK') {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          print_error(data.response)
        }
      })
      .catch(() => {
        print_error()
      })
  }
}

/**
 * Modifie les horaires d'un créneau dans le planning.
 * 
 * @param {Horaires} value Détails de l'objet.
 * @returns {Function} Une fonction redux thunk ou un message d'erreur.
 */
export const UPDATE_CRENEAU_PLANNING_HORAIRES = (value) => {
  return (dispatch) => {
    const object = get_creneau_object(value, 'iso')
    dispatch({
      value: object,
      type: 'UPDATE_CRENEAU_PLANNING_HORAIRES'
    })
    api('update-creneau-planning-horaires', {
      value: object
    })
      .then(data => {
        if (data.status !== 'OK') {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          print_error(data.response)
        }
      })
      .catch(() => {
        print_error()
      })
  }
}

/**
 * Envoi du planning aux salariés par mail.
 * 
 * @param {PDF} value Détails de l'objet.
 * @returns {Function} Une fonction redux thunk ou un message de succès/d'erreur.
 */
export const SEND_PLANNING = (value) => {
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    api('send-planning', {
      value: value
    })
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
        dispatch(SET_PDF_PLANNING({}))
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
        dispatch(SET_PDF_PLANNING({}))
      })
  }
}

/**
 * Téléchargement du planning au format PDF.
 * 
 * @param {PDF} value Détails de l'objet.
 * @returns {Function} Une fonction redux thunk ou un message de succès/d'erreur.
 */
export const DOWNLOAD_PLANNING = (value) => {
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    api('download-planning', {
      value: value
    })
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          const blob = base64StringToBlob(data.data.file, 'application/pdf')
          saveAs(blob, data.data.name + '.pdf')
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}

/**
 * Ajout de créneau(x) dans le planning.
 * 
 * @param {object|Array<Creneau>} value Détails de l'array/l'objet.
 * @param {string} [week] Lundi de la semaine au format YYYYMMDD.
 * @param {string} [value.id] ID du créneau.
 * @param {string} [value.week] Lundi de la semaine au format YYYYMMDD.
 * @param {Array<Creneau>} [value.creneaux] Liste des créneaux.
 * @returns {Function} Une fonction redux thunk ou un message d'erreur.
 */
export const ADD_CRENEAU_PLANNING = (value, existe, week, hour) => {
  return (dispatch) => {
    dispatch({
      hour: hour,
      value: value,
      existe: existe,
      type: 'ADD_CRENEAU_PLANNING'
    })
    api('add-creneau-planning', {
      value: value,
      week: (existe) ? week : undefined
    })
      .then(data => {
        if (data.status !== 'OK') {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          print_error(data.response)
        }
      })
      .catch(() => {
        print_error()
      })
  }
}

/**
 * Modifie les horaires d'une heure dans le planning.
 * 
 * @param {Horaires} value Détails de l'objet.
 * @returns {Function} Une fonction redux thunk ou un message d'erreur.
 */
export const UPDATE_HEURE_PLANNING_HORAIRES = (value) => {
  return (dispatch) => {
    const object = get_creneau_object(value, 'unix')
    dispatch({
      value: object,
      type: 'UPDATE_CRENEAU_PLANNING_HORAIRES'
    })
    dispatch({
      value: object,
      type: 'UPDATE_HEURE_PLANNING_HORAIRES'
    })
    api('update-heure-planning-horaires', {
      value: object
    })
      .then(data => {
        if (data.status !== 'OK') {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          print_error(data.response)
        }
      })
      .catch(() => {
        print_error()
      })
  }
}

/**
 * Ajoute des semaines dans le planning.
 * 
 * @param {Array<Object>} value Liste des semaines.
 * @param {string} value[].date Lundi de la semaine au format YYYYMMDD.
 * @param {boolean} value[].hour Est-ce que la semaine cible contient des heures.
 * @param {boolean} value[].existe Est-ce que la semaine cible contient des créneaux (et non des heures).
 * @param {Array<Creneau>} value[].value La liste des créneaux ou un objet contenant :
 *  - {string} value[].value.id ID de la semaine.
 *  - {string} value[].value.week Lundi de la semaine au format YYYYMMDD.
 *  - {Array<Creneau>} value[].value.creneaux La liste des créneaux.
 * @returns {Function} Une fonction redux thunk ou un message d'erreur.
 */
export const ADD_CRENEAU_PLANNING_WIZARD = (value) => {
  return (dispatch) => {
    let array = []
    value.forEach(item => {
      if (Array.isArray(item.value)) {
        array.push({
          week: item.date,
          creneaux: item.value
        })
      } else {
        array.push(item.value)
      }
      dispatch({
        date: item.date,
        hour: item.hour,
        value: item.value,
        existe: item.existe,
        type: 'ADD_CRENEAU_PLANNING'
      })
    })
    api('add-creneau-planning', {
      value: array
    })
      .then(data => {
        if (data.status !== 'OK') {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          print_error(data.response)
        }
      })
      .catch(() => {
        print_error()
      })
  }
}

/**
 * Mise à jour des erreurs/absences dans le planning.
 * 
 * @returns {Function} Une fonction redux thunk.
 */
export const UPDATE_PLANNING = () => {
  return (dispatch, state) => {
    let array = []
    let creneaux = []
    const date = state().planning.date
    const view = state().planning.view
    const equipe = state().equipe.equipe
    const heures = state().heures.heures
    const absences = state().absences.absences
    const planning = state().planning.planning
    const settings = state().settings.variables
    const fonctions = state().fonctions.fonctions
    const end = dayjs(date).endOf(view.toLowerCase()).unix()
    const start = dayjs(date).startOf(view.toLowerCase()).unix()
    creneaux = planning.filter(item => item.week === first_day_format(date)).map(item => item.creneaux).flat()
    if (view === 'Day') {
      creneaux = creneaux.filter(item => dayjs(item.startDate).format('DD/MM/YYYY') === date.format('DD/MM/YYYY'))
    }
    heures.filter(item =>
      (item.debut <= end) &&
      (item.fin >= start) &&
      (item.nombre === 0) &&
      (item.sens === 1) &&
      (
        (item.status === 1) ||
        (item.status === 3)
      )
    ).forEach(item => {
      array.push({
        heure: 1,
        id: item.id,
        user: item.user,
        status: item.status,
        fin: dayjs.unix(item.fin).toDate(),
        debut: dayjs.unix(item.debut).toDate(),
        motif: (item.motif !== 'autre')
          ? settings.heure.motifs[item.motif]
          : item.texte
      })
    })
    const holidays = absences.filter(item =>
      (item.debut <= end) &&
      (item.fin >= start) &&
      (
        (item.status === 1) ||
        (item.status === 3)
      )
    ).map(item => {
      return {
        id: item.id,
        fin: item.fin,
        user: item.user,
        debut: item.debut,
        status: item.status,
        motif: (item.motif !== 'autre')
          ? settings.absence.motifs[item.motif]
          : item.texte
      }
    })
    creneaux.forEach(week => {
      holidays.filter(item =>
        dayjs(week.startDate).isSameOrAfter(dayjs.unix(item.debut)) &&
        dayjs(week.endDate).isSameOrBefore(dayjs.unix(item.fin)) &&
        week.equipe.includes(item.user)
      ).forEach(item => {
        array.push({
          heure: 0,
          id: week.id,
          user: item.user,
          absence: item.id,
          motif: item.motif,
          fin: week.endDate,
          status: item.status,
          debut: week.startDate
        })
      })
    })
    dispatch({
      type: 'SET_PLANNING_ERREURS',
      value: get_planning_erreurs(creneaux, true)
    })
    dispatch({
      type: 'SET_PLANNING_ABSENTS',
      value: array.map(item => {
        const user = equipe.find(object => object.id === item.user)
        const fonction = fonctions.find(object => object.id === user.fonction)
        return {
          id: item.user,
          fin: item.fin,
          nom: user.nom,
          creneau: item.id,
          color: user.color,
          debut: item.debut,
          heure: item.heure,
          motif: item.motif,
          prenom: user.prenom,
          status: item.status,
          absence: item.absence,
          fonction: fonction.fonction
        }
      })
    })
  }
}
