import React from 'react'
import dayjs from '../../Libraries/dayjs'
import Root from '../Includes/Wrapper/Root'
import Alert from '../Includes/Dialog/Alert'
import AbsenceModal from '../Absences/AbsenceModal'
import AbsencesPlanningModal from './AbsencesPlanningModal'
import AbsencesPlanningContent from './AbsencesPlanningContent'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  DELETE_ABSENCE,
  MODAL_ABSENCES,
  SELECTED_ABSENCE
} from '../../Redux/Actions/Absences'

const AbsencesPlanning = () => {
  const dispatch = useDispatch()
  const device = useSelector(state => state.device)
  const user = useSelector(state => state.login.user)
  const date = useSelector(state => state.absences.date)
  const modal = useSelector(state => state.absences.modal)
  const equipe = useSelector(state => state.equipe.equipe)
  const absence = useSelector(state => state.absences.absence)
  const absences = useSelector(state => state.absences.absences)
  const team = equipe.filter(item => item.archive === 0).map(item => item.id)
  const start = dayjs(date).startOf('month').unix()
  const end = dayjs(date).endOf('month').unix()
  const data = absences.filter(item =>
    (team.includes(item.user)) &&
    (item.debut <= end) &&
    (item.fin >= start) &&
    (
      (item.status === 1) ||
      (item.status === 3)
    )
  ).map(item => {
    return {
      ...item,
      half: (item.duree < 2),
      endDate: dayjs.unix(item.fin).toDate(),
      startDate: dayjs.unix(item.debut).toDate()
    }
  })
  return (
    <Root container={true}>
      {
        (equipe.length > 0)
          ? <AbsenceModal/>
          : null
      }
      {
        (device.mobile)
          ? <AbsencesPlanningModal
              open={(absence !== '')}
              setOpen={() => dispatch(SELECTED_ABSENCE(''))}
            />
          : null
      }
      {
        (user.profil === 'administrateur')
          ? <Alert
              titre="Confirmation"
              open={(modal.open === 'delete')}
              Cancel={() => dispatch(MODAL_ABSENCES('', ''))}
              texte="Voulez-vous vraiment supprimer cette absence ?"
              Commit={() => {
                dispatch(SELECTED_ABSENCE(''))
                dispatch(MODAL_ABSENCES('', ''))
                dispatch(DELETE_ABSENCE(modal.id))
              }}
            />
          : null
      }
      <AbsencesPlanningContent data={data}/>
    </Root>
  )
}

export default AbsencesPlanning
