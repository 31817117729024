import PlanningModalSettingsContent from './PlanningModalSettingsContent'
import React, {
  useEffect
} from 'react'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from '../Includes/Dialog/Dialog'
import {
  SHOW_PLANNING_HORAIRES
} from '../../Redux/Actions/Planning'

const PlanningModalSettings = ({
  open,
  setOpen
}) => {
  const dispatch = useDispatch()
  const show = useSelector(state => state.planning.ouverture)
  const [state, setState] = React.useState(show)
  useEffect(() => {
    if (open) {
      setState(show)
    }
    // eslint-disable-next-line
  }, [open])
  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth={true}
    >
      <DialogTitle>Paramètres</DialogTitle>
      <DialogContent>
        <PlanningModalSettingsContent
          state={state}
          setState={setState}
        />
      </DialogContent>
      <DialogActions
        buttons={[
          {
            onClick: () => setOpen(false)
          },
          {
            onClick: () => {
              setOpen(false)
              dispatch(SHOW_PLANNING_HORAIRES(state))
            }
          }
        ]}
      />
    </Dialog>
  )
}

export default PlanningModalSettings
