import React from 'react'
import history from '../../Navigation/Includes/History'
import LoginButton from '../Includes/Login/LoginButton'
import InputPassword from '../Includes/Input/InputPassword'
import {
  useDispatch
} from 'react-redux'
import {
  CheckPassword
} from '../../Functions/Check'
import {
  SET_PENDING
} from '../../Redux/Actions/Login'
import {
  RESET_USER_PASSWORD
} from '../../Redux/Actions/Signin'

const ForgotPassword = ({
  error,
  state,
  Update,
  UpdateError
}) => {
  const dispatch = useDispatch()
  const Password = (value) => {
    Update('password', value)
    UpdateError('confirmation', (value !== state.confirmation))
    UpdateError('password', (
      (value !== '') &&
      (!CheckPassword(value))
    ))
  }
  const Confirmation = (value) => {
    Update('confirmation', value)
    UpdateError('confirmation', (value !== state.password))
  }
  const Commit = () => {
    if (
      CheckPassword(state.password) &&
      (state.password === state.confirmation)
    ) {
      dispatch(SET_PENDING(true))
      UpdateError('password', false)
      UpdateError('confirmation', false)
      dispatch(RESET_USER_PASSWORD(
        state.mail,
        state.code,
        state.password,
        () => history.push('/login')
      ))
    } else {
      UpdateError('password', !CheckPassword(state.password))
      UpdateError('confirmation', (
        (state.confirmation === '') ||
        (state.password !== state.confirmation)
      ))
    }
  }
  return (
    <>
      <InputPassword
        setValue={Password}
        confirmation={false}
        label="Mot de passe"
        error={error.password}
        value={state.password}
      />
      <InputPassword
        confirmation={true}
        label="Confirmation"
        setValue={Confirmation}
        error={error.confirmation}
        value={state.confirmation}
      />
      <LoginButton
        label="Réinitialiser"
        onClick={() => Commit()}
      />
    </>
  )
}

export default ForgotPassword
