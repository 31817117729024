import React from 'react'
import ToolbarWizard from './ToolbarWizard'
import ButtonIconWizard from '../Button/ButtonIconWizard'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  MODAL_PLANNING
} from '../../../Redux/Actions/Planning'

const ToolbarRootWizard = () => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.login.user)
  const semaines = useSelector(state => state.semaines.semaines)
  return (
    (semaines.length > 0) &&
    (user.profil === 'administrateur')
  )
    ? <>
        <ToolbarWizard/>
        <ButtonIconWizard
          title="Remplir le planning"
          onClick={() => dispatch(MODAL_PLANNING('wizard', true))}
        />
      </>
    : null
}

export default ToolbarRootWizard
