import React from 'react'
import TableCellMobile from './TableCellMobile'
import TableCellDesktop from './TableCellDesktop'
import TableCellPaiementStatus from './TableCellPaiementStatus'

const TableCellPaiement = ({
  item,
  label,
  device
}) => {
  const value = (item.last === 1)
    ? <TableCellPaiementStatus
        color="green"
        texte="Succès"
      />
    : <TableCellPaiementStatus
        color="red"
        texte="Erreur"
      />
  return (device.mobile)
    ? <TableCellMobile
        label={label}
        value={value}
      />
    : <TableCellDesktop
        max={200}
        min={140}
        value={value}
      />
}

export default TableCellPaiement
