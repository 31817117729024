import clsx from 'clsx'
import TableHead from './TableHead'
import TableMobile from './TableMobile'
import TableDesktop from './TableDesktop'
import styles from './TableData.module.css'
import TablePagination from './TablePagination'
import React, {
  useEffect
} from 'react'
import {
  useSelector
} from 'react-redux'
import {
  Table,
  TableBody,
  TableContainer
} from '@mui/material'
import {
  Sort,
  Slice,
  Comparator,
  Pagination
} from '../../../Functions/Table'
import {
  get_id
} from '../../../Functions/Functions'

const TableData = ({
  all,
  data,
  head,
  page,
  size,
  sort,
  type,
  hover,
  titre,
  border,
  bottom,
  random,
  columns,
  desktop,
  pointer,
  request,
  setPage,
  children,
  selected,
  draggable,
  functions,
  pagination,
  ...others
}) => {
  const numbers = Pagination(type.pagination)
  const [by, setBy] = React.useState(sort.by)
  const [numero, setNumero] = React.useState(0)
  const target = useSelector(state => state.device)
  const user = useSelector(state => state.login.user)
  const [order, setOrder] = React.useState(sort.order)
  const [number, setNumber] = React.useState(numbers[0])
  const setCurrent = (setPage || setNumero)
  const current = (page || numero)
  const remove = (
    (current !== 0) &&
    (data.length <= (current * number))
  )
  const device = {
    desktop: (desktop || target.desktop),
    mobile: (
      (target.mobile) &&
      (desktop === undefined)
    )
  }
  const colonnes = columns.filter(item =>
    (user.profil !== 'utilisateur') ||
    (
      (item.user !== false) &&
      (user.profil === 'utilisateur')
    )
  )
  const Change = (event) => {
    setNumber(parseInt(event.target.value))
    setCurrent(0)
  }
  const Request = (property) => {
    const sort = {
      by: property,
      order: (
        (by === property) &&
        (order === 'asc')
      )
        ? 'desc'
        : 'asc'
    }
    if (request) {
      request(sort)
    } else {
      setBy(sort.by)
      setOrder(sort.order)
    }
  }
  useEffect(() => {
    if (remove) {
      setCurrent(0)
    }
    // eslint-disable-next-line
  }, [data])
  useEffect(() => {
    setBy(sort.by)
    setOrder(sort.order)
    // eslint-disable-next-line
  }, [sort])
  useEffect(() => {
    if (all) {
      setNumber(data.length)
    }
    // eslint-disable-next-line
  }, [all, data.length])
  return (
    <>
      <TableContainer
        className={
          clsx({
            [styles.border]: border,
            [styles.container]: (
              (border !== true) &&
              (device.mobile)
            )
          })
        }
      >
        <Table
          className={
            clsx(
              'table',
              'table-' + type.id,
              'table-' + type.groupe,
              {
                'table-mobile': device.mobile,
                'table-desktop': device.desktop
              }
            )
          }
        >
          {device.desktop && children}
          {
            (
              (data.length > 0) &&
              (device.desktop) &&
              (head !== false)
            )
              ? <TableHead
                  by={by}
                  order={order}
                  head={colonnes}
                  request={Request}
                  selected={selected}
                  {...others}
                />
              : null
          }
          <TableBody>
            {
              Slice(Sort(data, Comparator(order, by)), current, number).map(item => {
                const props = {
                  item: item,
                  type: type,
                  columns: colonnes,
                  functions: functions,
                  key: (random)
                    ? get_id(16)
                    : item.id
                }
                return (
                  (device.mobile) &&
                  (titre !== undefined)
                )
                  ? <TableMobile
                      titre={titre}
                      {...props}
                    />
                  : <TableDesktop
                      hover={hover}
                      bottom={bottom}
                      device={device}
                      pointer={pointer}
                      selected={selected}
                      draggable={draggable}
                      size={(size || 'large')}
                      {...props}
                    />
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
      {
        (pagination !== false)
          ? <TablePagination
              count={data.length}
              rowsPerPage={number}
              onPageChange={setCurrent}
              rowsPerPageOptions={numbers}
              onRowsPerPageChange={Change}
              page={
                (remove)
                  ? 0
                  : current
              }
            />
          : null
      }
    </>
  )
}

export default TableData
