import React from 'react'
import CodeModal from './CodeModal'
import CodesContent from './CodesContent'
import Root from '../../Includes/Wrapper/Root'
import Alert from '../../Includes/Dialog/Alert'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  lower_case,
  sans_accents
} from '../../../Functions/Strings'
import {
  DELETE_CODE,
  MODAL_CODES,
  SELECTED_CODES
} from '../../../Redux/Actions/Codes'

const Codes = () => {
  const dispatch = useDispatch()
  const open = useSelector(state => state.codes.open)
  const codes = useSelector(state => state.codes.codes)
  const search = useSelector(state => state.codes.search)
  const filters = useSelector(state => state.filters.pages)
  const selected = useSelector(state => state.codes.selected)
  const data = codes.filter(item =>
    (
      lower_case(sans_accents(item.companie)).includes(lower_case(sans_accents(search))) ||
      lower_case(sans_accents(item.nom)).includes(lower_case(sans_accents(search))) ||
      lower_case(sans_accents(item.id)).includes(lower_case(sans_accents(search)))
    ) &&
    (
      ((item.type === filters.codes.type) || (parseInt(filters.codes.type) === -1))
    )
  )
  const texte = (selected.length > 1)
    ? 'les codes sélectionnés'
    : 'le code sélectionné'
  return (
    <Root container={true}>
      <CodeModal/>
      {
        (codes.length > 0)
          ? <Alert
              titre="Confirmation"
              open={(open === 'delete')}
              Cancel={() => dispatch(MODAL_CODES(''))}
              texte={<span className="block block-admin">Voulez-vous vraiment <b>supprimer</b> {texte} ?</span>}
              Commit={() => {
                dispatch(MODAL_CODES(''))
                dispatch(SELECTED_CODES([]))
                dispatch(DELETE_CODE(selected))
              }}
            />
          : null
      }
      <CodesContent data={data}/>
    </Root>
  )
}

export default Codes
