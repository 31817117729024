import React from 'react'
import styles from './NotificationsToolbar.module.css'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  Button
} from '@mui/material'
import {
  Delete
} from '@mui/icons-material'
import {
  MODAL_NOTIFICATIONS
} from '../../Redux/Actions/Notifications'

const NotificationsToolbarDelete = () => {
  const dispatch = useDispatch()
  const device = useSelector(state => state.device)
  const selected = useSelector(state => state.notifications.selected)
  return (
    <Button
      color="secondary"
      variant="contained"
      className={styles.delete}
      disabled={(selected.length === 0)}
      onClick={() => dispatch(MODAL_NOTIFICATIONS(true))}
      startIcon={
        (device.desktop)
          ? <Delete/>
          : undefined
      }
    >
      {
        (device.mobile)
          ? <Delete/>
          : 'Supprimer'
      }
    </Button>
  )
}

export default NotificationsToolbarDelete
