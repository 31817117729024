import {
  USER_LOGOUT
} from './Login'
import {
  api
} from '../../API/api'
import {
  update_toast,
  print_loading
} from '../../Functions/Toast'
import {
  get_id
} from '../../Functions/Functions'

export const DEL_PLAQUETTE = (value) => { // suppression d'une adresse mail dans la liste des destinataires
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'DEL_PLAQUETTE'
    })
  }
}

export const SET_PLAQUETTE = (item, value) => { // mise à jour d'une valeur pour l'envoi du mail
  return (dispatch) => {
    dispatch({
      item: item,
      value: value,
      type: 'SET_PLAQUETTE'
    })
  }
}

export const SEND_MAIL_PLAQUETTE = (mails, callback) => { // envoi du mail plaquette
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    api('send-mail-plaquette', {
      mails: mails
    })
      .then(data => {
        callback()
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        callback()
        update_toast(toast, undefined, 'error')
      })
  }
}
