import React from 'react'
import styles from './Info.module.css'
import ButtonIcon from '../Button/ButtonIcon'
import {
  variables
} from '../../../Variables/Variables'

const Info = ({
  icon,
  children
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <img
          alt="logo"
          src={variables.url.server + variables.path.icon + 'planipo_text.png'}
        />
      </div>
      <div className={styles.content}>
        {
          (icon)
            ? <div className={styles.icon}>
                <ButtonIcon icon={icon}/>
              </div>
            : null
        }
        <div className={styles.children}>
          {children}
        </div>
      </div>
      <div className={styles.footer}>
        <a href={"mailto:" + variables.mail.contact}>
          {variables.mail.contact}
        </a>
      </div>
    </div>
  )
}

export default Info
