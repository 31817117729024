import React from 'react'
import Absences from '../Hooks/Absences'
import Planning from '../Hooks/Planning'
import TableData from '../Table/TableData'
import dayjs from '../../../Libraries/dayjs'
import LegendeAccordion from './LegendeAccordion'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  first_day_format,
  format_week_date
} from '../../../Functions/Dates'
import {
  get_user_long
} from '../../../Functions/Strings'
import {
  SORT_SALARIES
} from '../../../Redux/Actions/Sort'
import {
  SET_FILTER_SALARIES
} from '../../../Redux/Actions/Filters'
import {
  properties
} from '../../../Settings/Legende/Salaries'

const LegendeSalaries = ({
  liste,
  draggable
}) => {
  let data = []
  let team = []
  let selected = []
  const absences = Absences()
  const planning = Planning()
  const dispatch = useDispatch()
  const day = useSelector(state => state.semaines.day)
  const sort = useSelector(state => state.sort.salaries)
  const date = useSelector(state => state.planning.date)
  const view = useSelector(state => state.planning.view)
  const equipe = useSelector(state => state.equipe.equipe)
  const heures = useSelector(state => state.heures.heures)
  const filter = useSelector(state => state.filters.salaries)
  const absents = useSelector(state => state.planning.absents)
  const current = useSelector(state => state.semaines.current)
  const creneaux = useSelector(state => state.planning.planning)
  team = equipe.filter(item => item.archive === 0)
  selected = team.map(item => item.id).filter(item => filter.indexOf(item) < 0)
  const count = (liste)
    ? liste.length
    : team.length
  data = (planning)
    ? creneaux.filter(item => item.week === first_day_format(date)).map(item => item.creneaux).flat()
    : current
  if (view === 'Day') {
    const limite = (planning)
      ? date
      : format_week_date(day)
    data = data.filter(item =>
      dayjs(item.startDate).isSameOrAfter(limite.startOf('day')) &&
      dayjs(item.endDate).isSameOrBefore(limite.endOf('day'))
    )
  }
  data = data.map(item => {
    return {
      ...item,
      equipe: item.equipe.filter(item => selected.includes(item))
    }
  })
  team = team.map(item => {
    let nombre = 0
    if (data.length > 0) {
      data.filter(object => object.equipe.includes(item.id)).forEach(object => {
        const forward = absents.find(absence =>
          (absence.id === item.id) &&
          (absence.creneau === object.id)
        )
        if (
          (object.heure) ||
          (forward === undefined)
        ) {
          const end = dayjs(object.endDate)
          const start = dayjs(object.startDate)
          const duration = dayjs.duration(end.diff(start))
          const hours = duration.asHours()
          if (object.heure) {
            const heure = heures.find(hour => hour.id === object.id)
            if (
              (heure !== undefined) &&
              (heure.recuperation === 0)
            ) {
              nombre += (heure.sens === 1) ? -hours : hours
            }
          } else {
            nombre += hours
          }
        }
      })
    }
    return {
      ...item,
      heures: nombre,
      salarie: get_user_long(item, true),
      selected: selected.includes(item.id) ? 1 : 0
    }
  })
  if (liste) {
    team = team.filter(item => liste.includes(item.id))
    selected = selected.filter(item => liste.includes(item))
  }
  const Select = () => {
    dispatch(SET_FILTER_SALARIES(
      (count === selected.length)
        ? (liste)
          ? [
              ...filter,
              ...liste
            ]
          : team.map(item => item.id)
        : (liste)
          ? filter.filter(item => liste.indexOf(item) < 0)
          : []
    ))
  }
  return (
    <LegendeAccordion
      open={absences}
      titre="Salariés"
    >
      <TableData
        all={true}
        data={team}
        sort={sort}
        All={Select}
        count={count}
        bottom={false}
        pagination={false}
        selected={selected}
        draggable={draggable}
        request={(value) => dispatch(SORT_SALARIES(value))}
        {...properties}
        columns={
          properties.columns.filter(item =>
            (absences === false) ||
            (
              (absences) &&
              (item.id !== 'heures')
            )
          )
        }
      />
    </LegendeAccordion>
  )
}

export default LegendeSalaries
