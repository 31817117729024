import React from 'react'
import Alert from '../Includes/Dialog/Alert'
import ButtonDelete from '../Includes/Button/ButtonDelete'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  get_valeur
} from '../../Functions/Settings'
import {
  UPDATE_SETTING
} from '../../Redux/Actions/Settings'
import {
  DELETE_OUVERTURE
} from '../../Redux/Actions/Ouverture'

const SettingsTableDelete = ({ item }) => {
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState('')
  const ouverture = useSelector(state => state.ouverture.ouverture)
  return (
    (get_valeur(item, ouverture) !== '') &&
    (
      (item.id === 'comptable') ||
      (item.id === 'ouverture')
    )
  )
    ? <>
        <Alert
          titre="Confirmation"
          Cancel={() => setOpen('')}
          open={(open === 'comptable')}
          texte="Voulez-vous vraiment supprimer l'adresse mail du comptable ?"
          Commit={() => {
            setOpen('')
            dispatch(UPDATE_SETTING({
              value: '',
              id: 'comptable',
              action: 'update-companie-comptable'
            }))
          }}
        />
        <Alert
          titre="Confirmation"
          Cancel={() => setOpen('')}
          open={(open === 'ouverture')}
          texte="Voulez-vous vraiment supprimer les horaires d'ouverture ?"
          Commit={() => {
            setOpen('')
            dispatch(DELETE_OUVERTURE())
          }}
        />
        <ButtonDelete
          onClick={() => {
            setOpen(item.id)
          }}
        />
      </>
    : null
}

export default SettingsTableDelete
