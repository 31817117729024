import clsx from 'clsx'
import React from 'react'
import Planning from '../Hooks/Planning'
import ButtonEdit from '../Button/ButtonEdit'
import ButtonClose from '../Button/ButtonClose'
import ButtonDelete from '../Button/ButtonDelete'
import ButtonDuplicate from '../Button/ButtonDuplicate'
import styles from './LegendeCreneauToolbar.module.css'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  first_day_format
} from '../../../Functions/Dates'
import {
  get_id
} from '../../../Functions/Functions'
import {
  SELECTED_CRENEAU,
  CLONE_CRENEAU_PLANNING,
  EMPTY_CRENEAU_PLANNING
} from '../../../Redux/Actions/Planning'
import {
  CLONE_CRENEAU_SEMAINE,
  EMPTY_CRENEAU_SEMAINE
} from '../../../Redux/Actions/Semaines'

const LegendeCreneauToolbar = ({
  equipe,
  setOpen
}) => {
  const planning = Planning()
  const dispatch = useDispatch()
  const date = useSelector(state => state.planning.date)
  const event = useSelector(state => state.planning.event)
  const creneaux = useSelector(state => state.planning.planning)
  return (
    <>
      <div
        className={
          clsx(
            styles.actions,
            'actions-legende'
          )
        }
      >
        {
          (planning)
            ? <ButtonEdit
                onClick={() => setOpen(true)}
              />
            : null
        }
        <ButtonDuplicate
          onClick={() => {
            if (planning) {
              const day = first_day_format(date)
              const array = creneaux.find(item => item.week === day)
              const clone = array.creneaux.find(item => item.id === event.id)
              const value = {
                ...clone,
                id: get_id()
              }
              dispatch(CLONE_CRENEAU_PLANNING(value, day))
            } else {
              dispatch(CLONE_CRENEAU_SEMAINE(event.id))
            }
          }}
        />
        {
          (equipe.length > 0)
            ? <ButtonDelete
                title="Vider"
                onClick={() => {
                  if (planning) {
                    dispatch(EMPTY_CRENEAU_PLANNING(event.id))
                  } else {
                    dispatch(EMPTY_CRENEAU_SEMAINE(event.id))
                  }
                }}
              />
            : null
        }
        <ButtonClose
          onClick={() => {
            dispatch(SELECTED_CRENEAU({
              id: '',
              read: false
            }))
          }}
        />
      </div>
      <div className={styles.divider}/>
    </>
  )
}

export default LegendeCreneauToolbar
