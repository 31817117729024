import TableCellTemps from '../Components/Includes/Table/TableCellTemps'
import TableCellHeures from '../Components/Includes/Table/TableCellHeures'
import TableCellCongesDone from '../Components/Includes/Table/TableCellCongesDone'
import TableCellMailEquipe from '../Components/Includes/Table/TableCellMailEquipe'
import TableCellActionsUsers from '../Components/Includes/Table/TableCellActionsUsers'
import TableCellCongesCurrent from '../Components/Includes/Table/TableCellCongesCurrent'
import TableCellCongesRemaining from '../Components/Includes/Table/TableCellCongesRemaining'
import {
  nom
} from './Includes/Nom'
import {
  mail
} from './Includes/Mail'
import {
  color
} from './Includes/Color'
import {
  heures
} from './Includes/Heures'
import {
  prenom
} from './Includes/Prenom'
import {
  profil
} from './Includes/Profil'
import {
  actions
} from './Includes/Actions'
import {
  fonction
} from './Includes/Fonction'
import {
  absences
} from '../Variables/Variables'
import {
  MODAL_EQUIPE
} from '../Redux/Actions/Equipe'

export const properties = {
  type: {
    id: "equipe",
    groupe: "users"
  },
  sort: {
    by: "nom",
    order: "asc"
  },
  columns: [
    {
      ...prenom
    },
    {
      ...nom
    },
    {
      ...fonction
    },
    {
      ...profil
    },
    {
      ...mail,
      component: TableCellMailEquipe
    },
    {
      ...heures,
      component: TableCellHeures
    },
    {
      id: "conges_done",
      sort: true,
      mobile: true,
      padding: true,
      label: "Congés " + absences.done.year,
      component: TableCellCongesDone
    },
    {
      id: "conges_current",
      sort: true,
      mobile: true,
      padding: true,
      label: "Congés " + absences.current.year,
      component: TableCellCongesCurrent
    },
    {
      id: "conges_remaining",
      sort: true,
      mobile: true,
      padding: true,
      label: "Congés " + absences.done.year + " Restants",
      component: TableCellCongesRemaining
    },
    {
      id: "temps",
      sort: true,
      mobile: true,
      padding: true,
      label: "Compteur Temps",
      component: TableCellTemps
    },
    {
      ...color
    },
    {
      ...actions,
      modal: MODAL_EQUIPE,
      component: TableCellActionsUsers
    }
  ]
}
