import React from 'react'
import styles from './NotificationsToolbar.module.css'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  Button
} from '@mui/material'
import {
  Mail
} from '@mui/icons-material'
import {
  READ_NOTIFICATION,
  SELECTED_NOTIFICATIONS
} from '../../Redux/Actions/Notifications'

const NotificationsToolbarUnread = () => {
  const dispatch = useDispatch()
  const device = useSelector(state => state.device)
  const user = useSelector(state => state.login.user)
  const selected = useSelector(state => state.notifications.selected)
  const notifications = useSelector(state => state.notifications.notifications)
  return (
    <Button
      color="green"
      variant="contained"
      className={styles.unread}
      disabled={(selected.length === 0)}
      onClick={() => {
        const array = notifications.filter(item =>
          selected.includes(item.id) &&
          item.read.includes(user.unique_id_user)
        )
        dispatch(READ_NOTIFICATION(array.map(item => item.id), user.unique_id_user, 0))
        dispatch(SELECTED_NOTIFICATIONS([]))
      }}
      startIcon={
        (device.desktop)
          ? <Mail/>
          : undefined
      }
    >
      {
        (device.mobile)
          ? <Mail/>
          : 'Marquer comme non lu(s)'
      }
    </Button>
  )
}

export default NotificationsToolbarUnread
