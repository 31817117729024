import React from 'react'
import Scheduler from '../Includes/Planning/Scheduler'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  DayCell,
  EmptyCell,
  ScaleCellDate,
  AppointmentComponentUser,
  AppointmentComponentAdmin
} from '../Includes/Event/Event'
import {
  isDifferent
} from '../../Functions/Compare'
import {
  get_array_holidays
} from '../../Functions/Absences'
import {
  get_ouverture
} from '../../Functions/Ouverture'
import {
  RootDateComponent,
  RootToolbarComponent
} from '../Includes/Toolbar/Toolbar'
import {
  SELECTED_CRENEAU,
  SET_DATE_PLANNING,
  UPDATE_HEURE_PLANNING_HORAIRES,
  UPDATE_CRENEAU_PLANNING_HORAIRES
} from '../../Redux/Actions/Planning'

const PlanningScheduler = React.memo(({ data }) => {
  const dispatch = useDispatch()
  const device = useSelector(state => state.device)
  const user = useSelector(state => state.login.user)
  const date = useSelector(state => state.planning.date)
  const view = useSelector(state => state.planning.view)
  const heures = useSelector(state => state.heures.heures)
  const ouverture = useSelector(state => state.ouverture.ouverture)
  const holidays = get_array_holidays(date)
  const Date = (date) => {
    dispatch(SET_DATE_PLANNING(date))
    dispatch(SELECTED_CRENEAU({
      id: '',
      read: false
    }))
  }
  const Change = ({ changed }) => {
    if (changed) {
      const id = Object.keys(changed)[0]
      const heure = heures.find(item => item.id === id)
      dispatch(
        (heure === undefined)
          ? UPDATE_CRENEAU_PLANNING_HORAIRES(changed)
          : UPDATE_HEURE_PLANNING_HORAIRES(changed)
      )
    }
  }
  return (
    <Scheduler
      data={data}
      date={date}
      view={view}
      horaires={true}
      draggable={(
        (device.desktop) &&
        (user.profil === 'administrateur')
      )}
      functions={{
        Date: Date,
        Change: Change
      }}
      components={{
        Navigator: RootDateComponent,
        Toolbar: RootToolbarComponent,
        Appointment: (user.profil === 'administrateur')
          ? AppointmentComponentAdmin
          : AppointmentComponentUser
      }}
      cells={{
        dayScaleEmptyCellComponent: () =>
          <EmptyCell enable={(user.profil === 'administrateur')}/>,
        dayScaleCellComponent: (props) =>
          <ScaleCellDate
            holidays={holidays}
            {...props}
          />,
        timeTableCellComponent: (props) =>
          <DayCell
            planning={true}
            holidays={holidays}
            open={isDifferent([ouverture], [get_ouverture()])}
            {...props}
          />
      }}
    />
  )
})

export default PlanningScheduler
