import clsx from 'clsx'
import React from 'react'
import AbonnementBuy from './AbonnementBuy'
import styles from './Abonnement.module.css'
import AbonnementPrix from './AbonnementPrix'
import AbonnementLight from './AbonnementLight'
import AbonnementOffer from './AbonnementOffer'
import AbonnementFeatures from './AbonnementFeatures'
import AbonnementSeparate from './AbonnementSeparate'

const Abonnement = ({
  Buy,
  days,
  free,
  error,
  modal,
  mobile,
  Select,
  signin,
  content,
  current,
  payment,
  discount,
  favorite,
  selected,
  promotion,
  abonnement
}) => {
  const props = {
    mobile: mobile,
    favorite: favorite,
    selected: selected,
    abonnement: abonnement
  }
  const Check = (item) => (
    (current === undefined) &&
    (abonnement[item] > 0)
  )
  const short = (
    (mobile) &&
    (
      (payment) ||
      (signin)
    )
  )
  return (
    <div
      onClick={() => {
        if (
          (!error) &&
          (Select)
        ) {
          Select(abonnement)
        }
      }}
      className={
        clsx({
          [styles.error]: error,
          [styles.modal]: modal,
          [styles.mobile]: mobile,
          [styles.payment]: payment,
          [styles.abonnement]: true,
          [styles.favorite]: favorite,
          [styles.selected]: selected,
          [styles.border]: (Buy === undefined),
          [styles.hover]: (
            (!error) &&
            (!mobile) &&
            (!payment) &&
            (!selected) &&
            (Buy === undefined)
          )
        })
      }
    >
      <AbonnementLight
        top={true}
        {...props}
      />
      <AbonnementPrix
        discount={discount}
        promotion={promotion}
        {...props}
      />
      {
        (Check('free_days'))
          ? <AbonnementOffer
              days={days}
              free={free}
              short={short}
              {...props}
            />
          : null
      }
      {
        (Check('formation'))
          ? <AbonnementOffer
              short={short}
              time={abonnement.formation}
              {...props}
            />
          : null
      }
      <AbonnementSeparate {...props}/>
      <AbonnementFeatures
        error={error}
        modal={modal}
        content={content}
        {...props}
      />
      {
        (Buy)
          ? <AbonnementBuy
              onClick={() => Buy(abonnement)}
              {...props}
            />
          : <AbonnementLight
              bottom={true}
              {...props}
            />
      }
    </div>
  )
}

export default Abonnement
