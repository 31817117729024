import clsx from 'clsx'
import React from 'react'
import CardForm from './CardForm'
import CardContentPhrase from './CardContentPhrase'
import InputCoupon from '../Includes/Input/InputCoupon'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  Grid,
  Button
} from '@mui/material'
import {
  CheckStripe
} from '../../Functions/Check'
import {
  CHECK_CODE
} from '../../Redux/Actions/Stripe'

const CardContent = ({
  error,
  state,
  Submit,
  Update,
  UpdateError,
  UpdateSuccess
}) => {
  const dispatch = useDispatch()
  const abonnement = useSelector(state => state.signin.abonnement)
  const Code = (value) => {
    Update('code', value)
    UpdateError('code', (
      (value !== '') &&
      (!CheckStripe(value))
    ))
  }
  const Commit = () => {
    if (CheckStripe(state.code)) {
      Update('lock', true)
      dispatch(CHECK_CODE(
        state.code,
        abonnement.unique_id_abonnement,
        (data) => {
          Update('lock', false)
          Update('essai', data.essai)
          Update('engagement', data.engagement)
          if (
            (data.price > 0) &&
            (data.id !== '')
          ) { // code réduction/pourcentage (existe dans stripe)
            Update('coupon', data.id)
            Update('promotion', data.price)
          } else if (data.days > 0) { // code avec des jours gratuits (uniquement en BDD)
            Update('days', data.days)
            Update('free', true)
          }
        },
        () => Update('lock', false)
      ))
    } else {
      UpdateError('code', !CheckStripe(state.code))
    }
  }
  return (
    <form
      id="stripe-form"
      onSubmit={Submit}
    >
      {
        (
          (!state.free) &&
          (state.promotion === 0)
        )
          ? <>
              <Grid
                xs={6}
                sm={6}
                item={true}
                className="stripe-grid stripe-grid-reduction"
              >
                <InputCoupon
                  setValue={Code}
                  error={error.code}
                  value={state.code}
                  disabled={(state.lock || state.pending)}
                />
                <Button
                  color="green"
                  variant="contained"
                  onClick={() => Commit()}
                  disabled={(state.lock || state.pending)}
                  className={
                    clsx({
                      'reduction-button': true,
                      'reduction-button-error': error.code
                    })
                  }
                >
                  Valider
                </Button>
              </Grid>
              <div className="reduction"/>
            </>
          : null
      }
      {
        (
          (state.days > 0) ||
          (state.engagement > 0)
        )
          ? <CardContentPhrase
              state={state}
              prix={
                (state.promotion > 0)
                  ? state.promotion
                  : abonnement.price
              }
            />
          : null
      }
      <CardForm
        error={error}
        state={state}
        Update={Update}
        UpdateError={UpdateError}
        UpdateSuccess={UpdateSuccess}
      />
    </form>
  )
}

export default CardContent
