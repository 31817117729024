import React from 'react'
import Empty from './Empty'
import styles from './Error.module.css'
import Info from '../Includes/Wrapper/Info'
import history from '../../Navigation/Includes/History'
import {
  useSelector
} from 'react-redux'

const Error = () => {
  const user = useSelector(state => state.login.user)
  return (
    <Info icon="error">
      <Empty margin={false}/>
      <div className={styles.button}>
        <span
          onClick={() => {
            (user.logged)
              ? history.push('/accueil')
              : history.push('/login')
          }}
        >
          Retour
        </span>
      </div>
    </Info>
  )
}

export default Error
