import dayjs from '../Libraries/dayjs'
import store from '../Redux/Store/Store'

export function sort_absent(object) {
  return object.sort((a, b) => a.absent - b.absent)
}

export function sort_semaine(object) {
  return object.sort((a, b) => a.nom.localeCompare(b.nom))
}

export function sort_function(object) {
  return object.sort((a, b) => a.fonction.localeCompare(b.fonction))
}

export function sort_debut(object) {
  return object.sort((a, b) => parseInt(a.debut) - parseInt(b.debut))
}

export function sort_timestamp(object) {
  return object.sort((a, b) => dayjs(a.debut).unix() - dayjs(b.debut).unix())
}

export function sort_date(object) {
  return object.sort((a, b) => dayjs(a.startDate).unix() - dayjs(b.endDate).unix())
}

export function sort_name(object) {
  return object.sort((a, b) => (a.archive - b.archive) || a.nom.localeCompare(b.nom) || a.prenom.localeCompare(b.prenom))
}

export function sort_name_by_id(users) {
  const equipe = store.getState().equipe.equipe
  const array = users.map(id => {
    const user = equipe.find(item => item.id === id)
    return {
      id: id,
      nom: user.nom,
      prenom: user.prenom
    }
  })
  return sort_name(array).map(item => item.id)
}
