import {
  USER_LOGOUT
} from './Login'
import {
  UPDATE_PLANNING
} from './Planning'
import {
  api
} from '../../API/api'
import {
  print_error,
  update_toast,
  print_loading
} from '../../Functions/Toast'
import {
  get_id
} from '../../Functions/Functions'

export const SELECTED_ABSENCE = (value) => { // absence sélectionnée dans le calendrier des absences (rectangle à droite)
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'SELECTED_ABSENCE'
    })
  }
}

export const SET_DATE_ABSENCES = (value) => { // date sélectionnée dans le calendrier des absences (mois courant)
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'SET_DATE_ABSENCES'
    })
  }
}

export const MODAL_ABSENCES = (id, open, data) => { // modale pour créer, modifier ou supprimer une absence
  return (dispatch) => {
    dispatch({
      id: id,
      data: data,
      open: open,
      type: 'MODAL_ABSENCES'
    })
  }
}

export const CUT_ABSENCE = (value) => { // découpage des absences si elles sont à cheval sur les dates d'export comptable
  return (dispatch) => {
    if (value.length > 0) {
      api('cut-absence', {
        value: value
      })
        .then(data => {
          if (data.status === 'OK') {
            value.forEach(item => {
              dispatch({
                value: item,
                type: 'CREATE_ABSENCE'
              })
            })
          } else {
            if (data.data === 'die') {
              dispatch(USER_LOGOUT(false))
            }
            print_error(data.response)
          }
        })
        .catch(() => {
          print_error()
        })
    }
  }
}

export const UPDATE_ABSENCE = (id, value) => { // mise à jour du status d'une absence (0 => refusée, 1 => acceptée)
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    api('update-absence', {
      id: id,
      value: value
    })
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          dispatch({
            id: id,
            value: value,
            type: 'UPDATE_ABSENCE'
          })
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}

export const EDIT_ABSENCE = (value) => { // modification d'une absence
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    api('edit-absence', {
      value: value
    })
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          dispatch({
            value: value,
            type: 'EDIT_ABSENCE'
          })
          if (value.status === 1) {
            dispatch(UPDATE_PLANNING())
          }
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}

export const CREATE_ABSENCE = (value) => { // création d'une absence
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    api('create-absence', {
      value: [value]
    })
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          dispatch({
            value: value,
            type: 'CREATE_ABSENCE'
          })
          if (value.status === 1) {
            dispatch(UPDATE_PLANNING())
          }
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}

export const DELETE_ABSENCE = (id) => { // suppression d'une absence
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch, state) => {
    api('delete-absence', {
      id: id
    })
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          const absences = state().absences.absences
          const ancien = absences.find(item => item.id === id)
          dispatch({
            value: id,
            type: 'DELETE_ABSENCE'
          })
          if (ancien.status === 1) {
            dispatch(UPDATE_PLANNING())
          }
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}

export const CLOSE_ABSENCE_COMPTABILITE = (value) => { // clôture des absences (status = 3) et mise à jour des congés suite à l'export comptable
  return (dispatch) => {
    if (value.length > 0) {
      api('close-absence-comptabilite', {
        value: value
      })
        .then(data => {
          if (data.status === 'OK') {
            value.forEach(item => {
              dispatch({
                value: item,
                type: 'UPDATE_ABSENCE'
              })
            })
            value.forEach(item => {
              if (item.motif === 'conge') {
                dispatch({
                  value: item,
                  type: 'UPDATE_USER_CONGES'
                })
              }
            })
          } else {
            if (data.data === 'die') {
              dispatch(USER_LOGOUT(false))
            }
            print_error(data.response)
          }
        })
        .catch(() => {
          print_error()
        })
    }
  }
}
