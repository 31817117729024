import React from 'react'
import Checkbox from '../Misc/Checkbox'
import TableCellField from './TableCellField'

const TableCellFieldCheckbox = (props) => {
  return (
    <TableCellField
      style={{
        paddingLeft: 6,
        paddingRight: 6
      }}
    >
      <Checkbox {...props}/>
    </TableCellField>
  )
}

export default TableCellFieldCheckbox
