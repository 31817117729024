import React from 'react'
import ActionSheet from './ActionSheet'
import {
  useDispatch
} from 'react-redux'

const ActionSheetUsers = ({
  id,
  edit,
  modal,
  reset,
  texte,
  remove,
  archive
}) => {
  const dispatch = useDispatch()
  const action = (archive)
    ? 'unarchive'
    : 'archive'
  const label = (archive)
    ? 'Désarchiver'
    : 'Archiver'
  return (
    <ActionSheet
      options={[
        {
          id: 0,
          label: 'Modifier',
          condition: (edit !== false),
          action: () => dispatch(modal(id, 'edit'))
        },
        {
          id: 1,
          label: label,
          condition: (remove !== false),
          action: () => dispatch(modal(id, action, texte))
        },
        {
          id: 2,
          label: 'Supprimer',
          condition: (remove !== false),
          action: () => dispatch(modal(id, 'delete', texte))
        },
        {
          id: 3,
          label: 'Réinitialiser',
          condition: (reset !== false),
          action: () => dispatch(modal(id, 'reset', texte))
        }
      ]}
    />
  )
}

export default ActionSheetUsers
