import React from 'react'
import TableData from '../Includes/Table/TableData'
import EquipeMobileTitre from './EquipeMobileTitre'
import EquipeToolbarMobile from './EquipeToolbarMobile'
import EquipeToolbarDesktop from './EquipeToolbarDesktop'
import FilterEquipeMobile from '../Includes/Filter/FilterEquipeMobile'
import FilterEquipeDesktop from '../Includes/Filter/FilterEquipeDesktop'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  Paper
} from '@mui/material'
import {
  properties
} from '../../Settings/Equipe'
import {
  SET_FILTERS_PAGE
} from '../../Redux/Actions/Filters'

const EquipeContent = ({
  data,
  total
}) => {
  const dispatch = useDispatch()
  const [page, setPage] = React.useState(0)
  const [filter, setFilter] = React.useState(false)
  const device = useSelector(state => state.device)
  const maximum = useSelector(state => state.compte.maximum.users)
  return (
    <>
      {
        (
          (total < maximum) &&
          (maximum !== -1) &&
          (device.mobile)
        )
          ? <EquipeToolbarMobile
              setPage={setPage}
              setFilter={setFilter}
            />
          : null
      }
      <Paper>
        {
          (device.desktop)
            ? <EquipeToolbarDesktop/>
            : null
        }
        {
          (total > 0)
            ? <>
                {
                  (device.mobile)
                    ? <FilterEquipeMobile
                        open={filter}
                        setOpen={setFilter}
                        Commit={(state) => {
                          setPage(0)
                          dispatch(SET_FILTERS_PAGE('equipe', state))
                        }}
                      />
                    : <FilterEquipeDesktop setPage={setPage}/>
                }
                <TableData
                  data={data}
                  page={page}
                  setPage={setPage}
                  titre={EquipeMobileTitre}
                  pagination={(data.length > 0)}
                  {...properties}
                >
                  {
                    (total === maximum)
                      ? <caption>Vous avez atteint le nombre maximum d'utilisateurs, pour en créer de nouveaux, vous devez modifier votre abonnement.</caption>
                      : null
                  }
                </TableData>
              </>
            : null
        }
      </Paper>
    </>
  )
}

export default EquipeContent
