import clsx from 'clsx'
import React from 'react'
import history from '../Includes/History'
import styles from './Hamburger.module.css'
import HamburgerIcone from './HamburgerIcone'
import HamburgerTexte from './HamburgerTexte'
import HamburgerContainer from './HamburgerContainer'
import Badge from '../../Components/Includes/Wrapper/Badge'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  ListItemButton
} from '@mui/material'
import {
  routes
} from '../../Variables/Routes'
import {
  MENU_OPEN
} from '../../Redux/Actions/Menu'
import {
  SELECTED_ABSENCE
} from '../../Redux/Actions/Absences'
import {
  SELECTED_CRENEAU
} from '../../Redux/Actions/Planning'
import {
  SELECTED_NOTIFICATION
} from '../../Redux/Actions/Notifications'

const HamburgerAdmin = ({ active }) => {
  const dispatch = useDispatch()
  const device = useSelector(state => state.device)
  const heures = useSelector(state => state.heures.heures)
  const absences = useSelector(state => state.absences.absences)
  const indemnites = useSelector(state => state.indemnites.indemnites)
  const badge = {
    heures: heures.filter(item => item.status === 2).length,
    absences: absences.filter(item => item.status === 2).length,
    indemnites: indemnites.filter(item => item.status === 2).length
  }
  const Navigate = (path) => {
    dispatch(MENU_OPEN(false))
    dispatch(SELECTED_NOTIFICATION(''))
    history.push(path)
  }
  return (
    <HamburgerContainer>
      {
        routes.filter(item =>
          (item.hamburger) &&
          (item.admin) &&
          (
            (
              (device.mobile) &&
              (item.mobile)
            ) ||
            (
              (device.desktop) &&
              (item.desktop)
            )
          )
        ).map(item => {
          return (
            <ListItemButton
              key={item.path}
              className={
                clsx({
                  [styles.item]: true,
                  'active-item': (active === item.path)
                })
              }
              onClick={() => {
                if (item.path === 'absences-planning') {
                  dispatch(SELECTED_ABSENCE(''))
                } else if (
                  (item.path === 'planning') ||
                  (item.path === 'semaines')
                ) {
                  dispatch(SELECTED_CRENEAU({
                    id: '',
                    read: false
                  }))
                }
                Navigate('/' + item.path)
              }}
            >
              {
                (
                  (item.path === 'indemnites') ||
                  (item.path === 'absences') ||
                  (item.path === 'heures')
                )
                  ? <Badge
                      left={true}
                      className="admin-badge"
                      content={badge[item.path]}
                      style={{
                        top: 3,
                        left: 21,
                        padding: '0px 4px'
                      }}
                    >
                      <HamburgerIcone icone={item.icon}/>
                    </Badge>
                  : <HamburgerIcone icone={item.icon}/>
              }
              <HamburgerTexte texte={item.name}/>
            </ListItemButton>
          )
        })
      }
    </HamburgerContainer>
  )
}

export default HamburgerAdmin
