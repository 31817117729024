import React from 'react'
import Button from './Button'

const ButtonArchive = ({
  archive,
  ...others
}) => {
  return (
    <Button
      icon={
        (archive)
          ? "Unarchive"
          : "Archive"
      }
      title={
        (archive)
          ? "Désarchiver"
          : "Archiver"
      }
      {...others}
    />
  )
}

export default ButtonArchive
