import CodeModalContent from './CodeModalContent'
import React, {
  useEffect
} from 'react'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  CheckLong
} from '../../../Functions/Check'
import {
  regex
} from '../../../Variables/Regex'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from '../../Includes/Dialog/Dialog'
import {
  CREATE_CODE,
  MODAL_CODES,
  SELECTED_CODES
} from '../../../Redux/Actions/Codes'

const CodeModal = () => {
  const dispatch = useDispatch()
  const open = useSelector(state => state.codes.open)
  const settings = useSelector(state => state.settings.variables)
  const abonnements = useSelector(state => state.landing.abonnements)
  const [error, setError] = React.useState({
    nom: false,
    valeur: false
  })
  const [state, setState] = React.useState({
    nom: '',
    type: 0,
    essai: 0,
    valeur: 10,
    condition: '',
    disabled: false,
    nombre: settings.codes.nombres[0],
    engagement: settings.codes.engagements[0].value,
    abonnements: abonnements.map(item => item.unique_id_abonnement)
  })
  const Update = (item, value) => {
    setState(current => ({
      ...current,
      [item]: value
    }))
  }
  const UpdateError = (item, value) => {
    setError(current => ({
      ...current,
      [item]: value
    }))
  }
  const Commit = () => {
    if (
      (!error.valeur) &&
      CheckLong(state.nom)
    ) {
      UpdateError('nom', false)
      UpdateError('valeur', false)
      dispatch(CREATE_CODE({
        nom: state.nom,
        type: state.type,
        nombre: state.nombre,
        valeur: state.valeur,
        engagement: state.engagement,
        abonnements: state.abonnements,
        essai: (state.type !== 2)
          ? state.essai
          : 0
      }))
      dispatch(MODAL_CODES(''))
      dispatch(SELECTED_CODES([]))
    } else {
      UpdateError('nom', !CheckLong(state.nom))
    }
  }
  useEffect(() => {
    if (state.engagement === 0) {
      Update('essai', 0)
      Update('disabled', true)
    } else {
      Update('disabled', false)
    }
    // eslint-disable-next-line
  }, [state.engagement])
  useEffect(() => {
    const condition = new RegExp(regex[settings.codes.types[state.type].type]['positive'], 'g')
    Update('condition', condition)
    UpdateError('valeur', (
      (state.valeur > settings.codes.types[state.type].maximum) ||
      (state.valeur < settings.codes.types[state.type].minimum) ||
      (!condition.test(state.valeur))
    ))
    // eslint-disable-next-line
  }, [state.type, state.valeur])
  useEffect(() => {
    if (open === 'create') {
      Update('nom', '')
      Update('type', 0)
      Update('essai', 0)
      Update('valeur', 10)
      UpdateError('nom', false)
      UpdateError('valeur', false)
      Update('nombre', settings.codes.nombres[0])
      Update('engagement', settings.codes.engagements[0].value)
      Update('abonnements', abonnements.map(item => item.unique_id_abonnement))
    }
    // eslint-disable-next-line
  }, [open])
  return (
    <Dialog open={(open === 'create')}>
      <DialogTitle>Nouveau code</DialogTitle>
      <DialogContent>
        <CodeModalContent
          error={error}
          state={state}
          Commit={Commit}
          Update={Update}
          UpdateError={UpdateError}
        />
      </DialogContent>
      <DialogActions
        buttons={[
          {
            onClick: () => dispatch(MODAL_CODES(''))
          },
          {
            onClick: () => Commit()
          }
        ]}
      />
    </Dialog>
  )
}

export default CodeModal
