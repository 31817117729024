import React from 'react'
import Alert from '../Includes/Dialog/Alert'
import ButtonIconClose from '../Includes/Button/ButtonIconClose'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  SELECTED_CRENEAU
} from '../../Redux/Actions/Planning'
import {
  SEMAINE_NOM,
  SET_SEMAINE_WORKING
} from '../../Redux/Actions/Semaines'

const SemainesBarClose = () => {
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false)
  const working = useSelector(state => state.semaines.working)
  const Close = () => {
    dispatch(SEMAINE_NOM({
      value: '',
      error: false
    }))
    dispatch(SELECTED_CRENEAU({
      id: '',
      read: false
    }))
    dispatch(SET_SEMAINE_WORKING({
      id: '',
      nom: '',
      new: false,
      edit: false,
      open: false,
      read: false
    }))
  }
  return (
    <>
      <Alert
        open={open}
        titre="Confirmation"
        Cancel={() => setOpen(false)}
        texte={
          <span>
            <span className="block">Voulez-vous vraiment quitter ?</span>
            <span className="block">Toutes vos modifications seront perdues...</span>
          </span>
        }
        Commit={() => {
          setOpen(false)
          Close()
        }}
      />
      <ButtonIconClose
        onClick={() => {
          if (working.read) {
            Close()
          } else {
            setOpen(true)
          }
        }}
      />
    </>
  )
}

export default SemainesBarClose
