/**
 * Ajoute un salarié à l'array.
 * 
 * @param {string} value ID du salarié.
 * @returns {Function} Une fonction redux thunk.
 */
export const ADD_FILTER_SALARIE = (value) => {
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'ADD_FILTER_SALARIE'
    })
  }
}

/**
 * Supprime un salarié de l'array.
 * 
 * @param {string} value ID du salarié.
 * @returns {Function} Une fonction redux thunk.
 */
export const DEL_FILTER_SALARIE = (value) => {
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'DEL_FILTER_SALARIE'
    })
  }
}

/**
 * Définit la liste des salariés à ne pas afficher sur le planning.
 * 
 * @param {Array<string>} value IDs des salariés.
 * @returns {Function} Une fonction redux thunk.
 */
export const SET_FILTER_SALARIES = (value) => {
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'SET_FILTER_SALARIES'
    })
  }
}

/**
 * Réinitialise les critères du filtre pour la page.
 * 
 * @param {string} page Nom de la page sur laquelle on applique le filtre (codes, users, heures, absences, etc...).
 * @returns {Function} Une fonction redux thunk.
 */
export const SET_FILTERS_PAGE_RESET = (page) => {
  return (dispatch) => {
    dispatch({
      page: page,
      type: 'SET_FILTERS_PAGE_RESET'
    })
  }
}

/**
 * Définit les critères du filtre pour la page.
 * 
 * @param {string} page Nom de la page sur laquelle on applique le filtre (codes, users, heures, absences, etc...).
 * @param {Object} value Objet qui contient les paramètres du filtre (type, motif, status, companie, etc...).
 * @returns {Function} Une fonction redux thunk.
 */
export const SET_FILTERS_PAGE = (page, value) => {
  return (dispatch) => {
    dispatch({
      page: page,
      value: value,
      type: 'SET_FILTERS_PAGE'
    })
  }
}

/**
 * Définit la valeur d'un critère du filtre pour la page.
 * 
 * @param {string} page Nom de la page sur laquelle on applique le filtre (codes, users, heures, absences, etc...).
 * @param {string} value Nom du critère à modifier (type, motif, status, companie, etc...).
 * @param {any} value Valeur du critère.
 * @returns {Function} Une fonction redux thunk.
 */
export const SET_FILTERS_PAGE_VALUE = (page, filtre, value) => {
  return (dispatch) => {
    dispatch({
      page: page,
      value: value,
      filtre: filtre,
      type: 'SET_FILTERS_PAGE_VALUE'
    })
  }
}

/**
 * Réinitialise le salarié du filtre pour chaque page.
 * 
 * @param {string} id ID du salarié.
 * @returns {Function} Une fonction redux thunk.
 */
export const SET_FILTERS_PAGE_RESET_SALARIE = (id) => (dispatch, state) => {
  const filters = state().filters.pages
  if (filters.heures.salarie === id) {
    dispatch(SET_FILTERS_PAGE_VALUE('heures', 'salarie', -1))
  }
  if (filters.absences.salarie === id) {
    dispatch(SET_FILTERS_PAGE_VALUE('absences', 'salarie', -1))
  }
  if (filters.indemnites.salarie === id) {
    dispatch(SET_FILTERS_PAGE_VALUE('indemnites', 'salarie', -1))
  }
}
