import React from 'react'
import Button from './Button'

const ButtonIconWizard = (props) => {
  return (
    <Button
      icon="wizard"
      {...props}
    />
  )
}

export default ButtonIconWizard
