import React from 'react'
import FilterGestion from '../../Includes/Filter/FilterGestion'
import CompaniesToolbarButtons from './CompaniesToolbarButtons'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  SET_FILTERS_PAGE
} from '../../../Redux/Actions/Filters'

const CompaniesToolbarMobile = ({ setPage }) => {
  const dispatch = useDispatch()
  const filters = useSelector(state => state.filters.pages)
  const companies = useSelector(state => state.companies.companies)
  return (
    <div className="toolbar-actions-mobile toolbar-actions-mobile-companies">
      <CompaniesToolbarButtons/>
      <FilterGestion
        data={companies}
        setPage={setPage}
        target="companies"
        label="entreprises"
        state={filters.companies}
        setState={(value) => dispatch(SET_FILTERS_PAGE('companies', value))}
      />
    </div>
  )
}

export default CompaniesToolbarMobile
