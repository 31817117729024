import FonctionModalContent from './FonctionModalContent'
import React, {
  useEffect
} from 'react'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  CheckTexte
} from '../../Functions/Check'
import {
  lower_case
} from '../../Functions/Strings'
import {
  get_id
} from '../../Functions/Functions'
import {
  initial
} from '../../Variables/Variables'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from '../Includes/Dialog/Dialog'
import {
  MANAGE_FONCTION,
  MODAL_FONCTIONS
} from '../../Redux/Actions/Fonctions'

const FonctionModal = () => {
  const dispatch = useDispatch()
  const modal = useSelector(state => state.fonctions.modal)
  const fonctions = useSelector(state => state.fonctions.fonctions)
  const [state, setState] = React.useState({
    fonction: '',
    error: false,
    existe: false,
    values: undefined
  })
  const Update = (item, value) => {
    setState(current => ({
      ...current,
      [item]: value
    }))
  }
  const Commit = () => {
    if (CheckTexte(state.fonction)) {
      const fonction = lower_case(state.fonction)
      const existe = fonctions.find(item => lower_case(item.fonction) === fonction)
      if (existe === undefined) {
        Update('error', false)
        Update('existe', false)
        const value = {
          fonction: fonction,
          id: (modal.open === 'edit')
            ? state.values.id
            : get_id()
        }
        if (modal.open === 'edit') {
          if (fonction !== lower_case(state.values.fonction)) {
            dispatch(MANAGE_FONCTION(value, 'edit-fonction'))
          }
        } else {
          dispatch(MANAGE_FONCTION(value, 'create-fonction'))
        }
        dispatch(MODAL_FONCTIONS('', ''))
      } else {
        Update('error', true)
        Update('existe', true)
      }
    } else {
      Update('error', !CheckTexte(state.fonction))
    }
  }
  useEffect(() => {
    if (modal.open !== '') {
      Update('error', false)
      Update('existe', false)
      if (modal.id === '') {
        Update('fonction', initial.fonction.fonction)
      } else {
        const fonction = fonctions.find(item => item.id === modal.id)
        Update('values', {
          ...fonction
        })
      }
    }
    // eslint-disable-next-line
  }, [modal])
  useEffect(() => {
    if (state.values !== undefined) {
      Update('fonction', state.values.fonction)
    }
    // eslint-disable-next-line
  }, [state.values])
  return (
    <Dialog
      open={(
        (modal.open === 'create') ||
        (modal.open === 'edit')
      )}
    >
      <DialogTitle>
        {
          (modal.open === 'edit')
            ? "Modifier la fonction"
            : "Nouvelle fonction"
        }
      </DialogTitle>
      <DialogContent>
        <FonctionModalContent
          state={state}
          Commit={Commit}
          Update={Update}
        />
      </DialogContent>
      <DialogActions
        buttons={[
          {
            onClick: () => dispatch(MODAL_FONCTIONS('', ''))
          },
          {
            onClick: () => Commit()
          }
        ]}
      />
    </Dialog>
  )
}

export default FonctionModal
