import React from 'react'
import Form from '../Wrapper/Form'
import FilterMotif from './FilterMotif'
import FilterStatut from './FilterStatut'
import FilterSalarie from './FilterSalarie'
import DateRangePicker from '../Picker/DateRangePicker'
import {
  useSelector
} from 'react-redux'

const FilterDemandesMobileContent = ({
  page,
  state,
  Update,
  setting,
  validation,
  setValidation
}) => {
  const user = useSelector(state => state.login.user)
  return (
    <Form>
      {
        (user.profil === 'administrateur')
          ? <FilterSalarie
              value={state.salarie}
              setValue={(value) => Update('salarie', value)}
            />
          : null
      }
      {
        (page !== 'indemnites')
          ? <FilterMotif
              setting={setting}
              value={state.motif}
              setValue={(value) => Update('motif', value)}
            />
          : null
      }
      <FilterStatut
        value={state.status}
        setValue={(value) => Update('status', value)}
      />
      <DateRangePicker
        value={state.dates}
        slotProps={{
          textField: {
            error: (!validation)
          }
        }}
        onChange={(value) => {
          setValidation(
            (
              (
                (value[0] !== null) &&
                (value[0].isValid())
              ) &&
              (
                (value[1] !== null) &&
                (value[1].isValid())
              )
            ) ||
            (
              (value[0] === null) &&
              (value[1] === null)
            )
          )
        }}
        onAccept={(value) => {
          const debut = (value[0] !== null)
            ? value[0].startOf('day')
            : value[0]
          const fin = (value[1] !== null)
            ? value[1].endOf('day')
            : value[1]
          Update('dates', [debut, fin])
        }}
      />
    </Form>
  )
}

export default FilterDemandesMobileContent
