import React from 'react'
import TableCellField from './TableCellField'
import ButtonEdit from '../Button/ButtonEdit'
import ButtonReset from '../Button/ButtonReset'
import ButtonDelete from '../Button/ButtonDelete'
import ButtonArchive from '../Button/ButtonArchive'
import TableCellContainer from './TableCellContainer'
import ActionSheetUsers from '../Action/ActionSheetUsers'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  get_user_long
} from '../../../Functions/Strings'

const TableCellActionsUsers = ({
  item,
  type,
  modal
}) => {
  const dispatch = useDispatch()
  const device = useSelector(state => state.device)
  const user = useSelector(state => state.login.user)
  const action = (item.archive)
    ? 'unarchive'
    : 'archive'
  const name = (type.id === 'offices')
    ? item.mail
    : get_user_long(item)
  const edit = (
    (type.id !== 'equipe') ||
    (item.archive === 0)
  )
  const reset = (
    (item.mail !== '') &&
    (item.archive === 0) &&
    (user.unique_id_user !== item.id)
  )
  return (device.mobile)
    ? <TableCellField>
        {
          (type.id === 'offices')
            ? <ActionSheetUsers
                id={item.id}
                modal={modal}
                texte={item.mail}
              />
            : <ActionSheetUsers
                edit={edit}
                id={item.id}
                modal={modal}
                reset={reset}
                archive={item.archive}
                texte={get_user_long(item)}
                remove={(user.unique_id_user !== item.id)}
              />
        }
      </TableCellField>
    : <TableCellContainer width={4}>
        {
          (edit)
            ? <ButtonEdit
                onClick={() => dispatch(modal(item.id, 'edit'))}
              />
            : null
        }
        {
          (
            (type.id === 'equipe') &&
            (user.unique_id_user !== item.id)
          )
            ? <ButtonArchive
                archive={item.archive}
                onClick={() => dispatch(modal(item.id, action, name))}
              />
            : null
        }
        {
          (
            (type.id === 'offices') ||
            (user.unique_id_user !== item.id)
          )
            ? <ButtonDelete
                onClick={() => dispatch(modal(item.id, 'delete', name))}
              />
            : null
        }
        {
          (
            (reset) ||
            (type.id === 'offices')
          )
            ? <ButtonReset
                onClick={() => dispatch(modal(item.id, 'reset', name))}
              />
            : null
        }
      </TableCellContainer>
}

export default TableCellActionsUsers
