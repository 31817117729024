import React from 'react'
import ButtonFilter from '../Button/ButtonFilter'
import {
  useDispatch
} from 'react-redux'
import {
  SET_FILTERS_PAGE_RESET
} from '../../../Redux/Actions/Filters'
import {
  SELECTED_NOTIFICATION
} from '../../../Redux/Actions/Notifications'

const FilterReset = ({
  target,
  callback,
  notification
}) => {
  const dispatch = useDispatch()
  return (
    <ButtonFilter
      off={true}
      onClick={() => {
        dispatch(
          (notification)
            ? SELECTED_NOTIFICATION('')
            : SET_FILTERS_PAGE_RESET(target)
        )
        if (callback) {
          callback()
        }
      }}
    />
  )
}

export default FilterReset
