const initial = {
  pdf: [],
  primes: [],
  exports: [],
  selected: [],
  dates: [null, null]
}

function comptabilite(state = initial, action) {
  switch (action.type) {
    case 'SET_PDF':
      return {
        ...state,
        pdf: action.value
      }
    case 'INIT_PRIMES':
      return {
        ...state,
        primes: action.value
      }
    case 'INIT_EXPORTS':
      return {
        ...state,
        exports: action.value
      }
    case 'SELECTED_COMPTABILITE':
      return {
        ...state,
        selected: action.value
      }
    case 'SET_DATES_COMPTABILITE':
      return {
        ...state,
        dates: action.value
      }
    case 'ARCHIVE_USER_COMPTABILITE':
      return {
        ...state,
        selected: state.selected.filter(item => item !== action.id)
      }
    case 'ADD_EXPORT':
      return {
        ...state,
        exports: [
          ...state.exports,
          action.value
        ]
      }
    case 'DELETE_USER_PRIMES':
      return {
        ...state,
        selected: [],
        primes: state.primes.map(item => {
          return {
            ...item,
            users: item.users.filter(item => item !== action.value)
          }
        })
      }
    default:
      return state
  }
}

export default comptabilite
