import React from 'react'
import Condition from '../Condition/Demandes'
import ButtonEdit from '../Button/ButtonEdit'
import ButtonClose from '../Button/ButtonClose'
import styles from './LegendeAbsence.module.css'
import ButtonDelete from '../Button/ButtonDelete'
import LegendeAbsenceContent from './LegendeAbsenceContent'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  Paper
} from '@mui/material'
import {
  MODAL_ABSENCES,
  SELECTED_ABSENCE
} from '../../../Redux/Actions/Absences'

const LegendeAbsence = () => {
  const dispatch = useDispatch()
  const absence = useSelector(state => state.absences.absence)
  const absences = useSelector(state => state.absences.absences)
  const data = absences.find(item => item.id === absence)
  const condition = Condition(data)
  return (
    <Paper className={styles.container}>
      {
        (condition)
          ? <>
              <div className={styles.actions}>
                <ButtonEdit
                  onClick={() => dispatch(MODAL_ABSENCES(data.id, 'edit'))}
                />
                <ButtonDelete
                  onClick={() => dispatch(MODAL_ABSENCES(data.id, 'delete'))}
                />
                <ButtonClose
                  onClick={() => dispatch(SELECTED_ABSENCE(''))}
                />
              </div>
              <div className={styles.divider}/>
            </>
          : null
      }
      <LegendeAbsenceContent
        absence={data}
        condition={condition}
      />
    </Paper>
  )
}

export default LegendeAbsence
