import clsx from 'clsx'
import React from 'react'
import UserColor from '../User/UserColor'
import ButtonClose from '../Button/ButtonClose'
import styles from './LegendeAbsenceUser.module.css'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  get_user_long
} from '../../../Functions/Strings'
import {
  SELECTED_ABSENCE
} from '../../../Redux/Actions/Absences'

const LegendeAbsenceUser = ({
  user,
  condition
}) => {
  const dispatch = useDispatch()
  const device = useSelector(state => state.device)
  return (
    <div
      className={
        clsx({
          [styles.user]: true,
          [styles.short]: (condition === false)
        })
      }
    >
      <UserColor
        color={user.color}
        className={
          clsx(
            styles.color,
            (device.mobile)
              ? styles.mobile
              : styles.desktop
          )
        }
      >
        <span className={styles.name}>
          {get_user_long(user)}
        </span>
      </UserColor>
      {
        (condition === false)
          ? <div className={styles.button}>
              <ButtonClose
                onClick={() => dispatch(SELECTED_ABSENCE(''))}
              />
            </div>
          : null
      }
    </div>
  )
}

export default LegendeAbsenceUser
