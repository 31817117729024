import {
  useSelector
} from 'react-redux'

const Condition = (id) => {
  const equipe = useSelector(state => state.equipe.equipe)
  const team = equipe.filter(item => item.archive === 0).map(item => item.id)
  return team.includes(id)
}

export default Condition
