import React from 'react'
import Ouverture from './Ouverture'
import {
  useDispatch
} from 'react-redux'
import {
  AppointmentComponentDraft,
  AppointmentComponentSource
} from '../Event/Event'
import {
  format_heure
} from '../../../Functions/Heures'
import {
  variables
} from '../../../Variables/Variables'
import {
  ViewState,
  EditingState
} from '@devexpress/dx-react-scheduler'
import {
  SET_PLANNING_VIEW
} from '../../../Redux/Actions/Planning'
import {
  DayView,
  Toolbar,
  WeekView,
  MonthView,
  Appointments,
  DateNavigator,
  DragDropProvider,
  EditRecurrenceMenu,
  Scheduler as Planning
} from '@devexpress/dx-react-scheduler-material-ui'

const Movable = ({
  heure,
  status
}) => {
  return (
    (heure !== true) ||
    (status !== 3)
  )
}

const Scheduler = ({
  data,
  date,
  view,
  cells,
  horaires,
  draggable,
  functions,
  components
}) => {
  const dispatch = useDispatch()
  const options = {
    ...cells,
    endDayHour: format_heure(variables.date.fin),
    startDayHour: format_heure(variables.date.debut)
  }
  return (
    <>
      <Planning
        data={data}
        locale="fr-FR"
        firstDayOfWeek={1}
      >
        <ViewState
          currentViewName={view}
          currentDate={date.toDate()}
          defaultCurrentViewName={view}
          defaultCurrentDate={date.toDate()}
          onCurrentDateChange={functions.Date}
          onCurrentViewNameChange={(value) => dispatch(SET_PLANNING_VIEW(value))}
        />
        <DayView {...options}/>
        <WeekView {...options}/>
        <MonthView {...options}/>
        <EditingState onCommitChanges={functions.Change}/>
        {
          (draggable)
            ? <EditRecurrenceMenu/>
            : null
        }
        {
          (components.Toolbar)
            ? <Toolbar rootComponent={components.Toolbar}/>
            : null
        }
        {
          (components.Navigator)
            ? <DateNavigator rootComponent={components.Navigator}/>
            : null
        }
        <Appointments
          placeAppointmentsNextToEachOther={true}
          appointmentComponent={components.Appointment}
        />
        {
          (draggable)
            ? <DragDropProvider
                allowDrag={Movable}
                allowResize={Movable}
                draftAppointmentComponent={AppointmentComponentDraft}
                sourceAppointmentComponent={AppointmentComponentSource}
              />
            : null
        }
      </Planning>
      {
        (horaires)
          ? <Ouverture/>
          : null
      }
    </>
  )
}

export default Scheduler
