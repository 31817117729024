import React from 'react'
import ToolbarSend from './ToolbarSend'
import ButtonIconSend from '../Button/ButtonIconSend'
import ButtonIconDownload from '../Button/ButtonIconDownload'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  first_week,
  first_day_format
} from '../../../Functions/Dates'
import {
  get_amplitude_ouverture
} from '../../../Functions/Ouverture'
import {
  MODAL_PLANNING,
  SET_PDF_PLANNING,
  DOWNLOAD_PLANNING
} from '../../../Redux/Actions/Planning'

const ToolbarRootShare = ({ existe }) => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.login.user)
  const date = useSelector(state => state.planning.date)
  const view = useSelector(state => state.planning.view)
  const equipe = useSelector(state => state.equipe.equipe)
  const filter = useSelector(state => state.filters.salaries)
  const absents = useSelector(state => state.planning.absents)
  const ouverture = useSelector(state => state.ouverture.ouverture)
  const Send = () => {
    dispatch(MODAL_PLANNING('share', true))
    dispatch(SET_PDF_PLANNING(Values()))
  }
  const Download = () => {
    dispatch(DOWNLOAD_PLANNING({
      users: [],
      ...Values()
    }))
  }
  const Values = () => {
    let array = []
    const start = first_week(date)
    const end = (view === 'Week')
      ? start.add(6, 'days')
      : date
    let debut = start
    while (debut.isSameOrBefore(end)) {
      array.push(debut.add(12, 'hours').utcOffset() / 60)
      debut = debut.add(1, 'days')
    }
    return {
      week: first_day_format(date),
      amplitude: get_amplitude_ouverture(ouverture),
      filter: equipe.filter(item => filter.indexOf(item.id) < 0).map(item => item.id),
      gmt: (view === 'Week')
        ? array
        : [date.add(12, 'hours').utcOffset() / 60],
      debut: (view === 'Week')
        ? start.format('YYYY-MM-DD')
        : date.format('YYYY-MM-DD'),
      fin: (view === 'Week')
        ? end.format('YYYY-MM-DD')
        : date.format('YYYY-MM-DD'),
      absents: absents.map(item => {
        return {
          user: item.id,
          heure: item.heure,
          creneau: item.creneau
        }
      })
    }
  }
  return (
    <>
      {
        (
          (existe) &&
          (
            (user.profil === 'administrateur') ||
            (user.profil === 'utilisateur')
          )
        )
          ? <ButtonIconDownload
              onClick={() => Download()}
              title={
                (view === 'Week')
                  ? "Télécharger la semaine"
                  : "Télécharger la journée"
              }
            />
          : null
      }
      {
        (
          (existe) &&
          (
            (user.profil === 'administrateur') ||
            (user.profil === 'office')
          )
        )
          ? <>
              <ToolbarSend/>
              <ButtonIconSend
                onClick={() => Send()}
                title={
                  (view === 'Week')
                    ? "Envoyer la semaine"
                    : "Envoyer la journée"
                }
              />
            </>
          : null
      }
    </>
  )
}

export default ToolbarRootShare
