const initial = {
  indemnites: [],
  modal: {
    id: '',
    open: ''
  }
}

function indemnites(state = initial, action) {
  switch (action.type) {
    case 'INIT_INDEMNITES':
      return {
        ...state,
        indemnites: action.value
      }
    case 'DELETE_INDEMNITE':
      return {
        ...state,
        indemnites: state.indemnites.filter(item => item.id !== action.value)
      }
    case 'DELETE_USER_INDEMNITES':
      return {
        ...state,
        indemnites: state.indemnites.filter(item => item.user !== action.value)
      }
    case 'CREATE_INDEMNITE':
      return {
        ...state,
        indemnites: [
          ...state.indemnites,
          action.value
        ]
      }
    case 'MODAL_INDEMNITES':
      return {
        ...state,
        modal: {
          id: action.id,
          open: action.open
        }
      }
    case 'UPDATE_INDEMNITE':
      return {
        ...state,
        indemnites: state.indemnites.map(item => {
          return (item.id === action.id)
            ? {
                ...item,
                status: action.value
              }
            : item
        })
      }
    case 'EDIT_INDEMNITE':
      return {
        ...state,
        indemnites: state.indemnites.map(item => {
          return (item.id === action.value.id)
            ? {
                ...item,
                date: action.value.date,
                user: action.value.user,
                nombre: action.value.nombre,
                status: action.value.status
              }
            : item
        })
      }
    default:
      return state
  }
}

export default indemnites
