import React from 'react'
import FirstLoginContent from './FirstLoginContent'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  landing
} from '../../Variables/Landing'
import {
  FIRST_LOGIN
} from '../../Redux/Actions/Login'
import {
  variables
} from '../../Variables/Variables'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from '../Includes/Dialog/Dialog'

const FirstLogin = () => {
  const dispatch = useDispatch()
  const first = useSelector(state => state.login.user.first)
  return (
    <Dialog
      fullScreen={false}
      open={(first === 1)}
      className="dialog-first-login"
    >
      <DialogTitle>
        <img
          alt="logo"
          width={102}
          height={60}
          src={variables.url.server + '/img/bandeau.png'}
        />
        <div>Bienvenue sur {landing.nom} !</div>
      </DialogTitle>
      <DialogContent>
        <FirstLoginContent/>
      </DialogContent>
      <DialogActions
        buttons={[
          {
            text: 'OK',
            color: 'primary',
            onClick: () => dispatch(FIRST_LOGIN())
          }
        ]}
      />
    </Dialog>
  )
}

export default FirstLogin
