import React from 'react'
import Alert from '../Dialog/Alert'
import ConditionArchive from '../Condition/Archive'
import ConditionAbsence from '../Condition/Demandes'
import ButtonIconEdit from '../Button/ButtonIconEdit'
import ButtonIconTrash from '../Button/ButtonIconTrash'
import styles from './LegendeCreneauAbsence.module.css'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  DELETE_ABSENCE,
  MODAL_ABSENCES,
  SELECTED_ABSENCE
} from '../../../Redux/Actions/Absences'

const LegendeCreneauAbsence = ({ item }) => {
  const dispatch = useDispatch()
  const modal = useSelector(state => state.absences.modal)
  const absences = useSelector(state => state.absences.absences)
  const absence = absences.find(object => object.id === item.absence)
  return (
    (ConditionAbsence(absence)) &&
    (ConditionArchive(absence.user))
  )
    ? <>
        <Alert
          titre="Confirmation"
          open={(modal.open === 'delete')}
          Cancel={() => dispatch(MODAL_ABSENCES('', ''))}
          texte="Voulez-vous vraiment supprimer cette absence ?"
          Commit={() => {
            dispatch(SELECTED_ABSENCE(''))
            dispatch(MODAL_ABSENCES('', ''))
            dispatch(DELETE_ABSENCE(modal.id))
          }}
        />
        <span className={styles.action}>
          <ButtonIconEdit
            small={true}
            inherit={true}
            title="Modifier l'absence"
            onClick={() => dispatch(MODAL_ABSENCES(item.absence, 'edit'))}
          />
        </span>
        <span className={styles.action}>
          <ButtonIconTrash
            small={true}
            inherit={true}
            title="Supprimer l'absence"
            onClick={() => dispatch(MODAL_ABSENCES(item.absence, 'delete'))}
          />
        </span>
      </>
    : null
}

export default LegendeCreneauAbsence
