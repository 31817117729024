import React from 'react'
import ButtonGestion from './ButtonGestion'

const ButtonGestionDownload = (props) => {
  return (
    <ButtonGestion
      icon="GetApp"
      color="green"
      title="Télécharger"
      {...props}
    />
  )
}

export default ButtonGestionDownload
