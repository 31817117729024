import React from 'react'
import styles from './Signin.module.css'
import Form from '../Includes/Wrapper/Form'
import InputNom from '../Includes/Input/InputNom'
import InputMail from '../Includes/Input/InputMail'
import InputPrenom from '../Includes/Input/InputPrenom'
import InputCompanie from '../Includes/Input/InputCompanie'
import InputPassword from '../Includes/Input/InputPassword'
import SelectTypeCompanie from '../Includes/Select/SelectTypeCompanie'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  Button
} from '@mui/material'
import {
  CheckMail,
  CheckTexte,
  CheckPassword
} from '../../Functions/Check'
import {
  lower_case
} from '../../Functions/Strings'
import {
  get_id,
  random_number
} from '../../Functions/Functions'
import {
  SIGNIN_USER,
  SIGNIN_UPDATE
} from '../../Redux/Actions/Signin'

const SigninForm = ({ setStep }) => {
  const dispatch = useDispatch()
  const signin = useSelector(state => state.signin)
  const [error, setError] = React.useState({
    nom: false,
    mail: false,
    prenom: false,
    companie: false,
    password: false,
    confirmation: false
  })
  const UpdateError = (item, value) => {
    setError(current => ({
      ...current,
      [item]: value
    }))
  }
  const Nom = (value) => {
    dispatch(SIGNIN_UPDATE('nom', value))
    UpdateError('nom', (
      (value !== '') &&
      (!CheckTexte(value))
    ))
  }
  const Prenom = (value) => {
    dispatch(SIGNIN_UPDATE('prenom', value))
    UpdateError('prenom', (
      (value !== '') &&
      (!CheckTexte(value))
    ))
  }
  const Mail = (value) => {
    dispatch(SIGNIN_UPDATE('mail', value))
    UpdateError('mail', (
      (value !== '') &&
      (!CheckMail(value))
    ))
  }
  const Password = (value) => {
    dispatch(SIGNIN_UPDATE('password', value))
    UpdateError('confirmation', (value !== signin.user.confirmation))
    UpdateError('password', (
      (value !== '') &&
      (!CheckPassword(value))
    ))
  }
  const Confirmation = (value) => {
    dispatch(SIGNIN_UPDATE('confirmation', value))
    UpdateError('confirmation', (value !== signin.user.password))
  }
  const Companie = (value) => {
    dispatch(SIGNIN_UPDATE('companie', value))
    UpdateError('companie', (
      (value !== '') &&
      (!CheckTexte(value))
    ))
  }
  const Commit = () => {
    if (
      CheckTexte(signin.user.nom) &&
      CheckMail(signin.user.mail) &&
      CheckTexte(signin.user.prenom) &&
      CheckTexte(signin.user.companie) &&
      CheckPassword(signin.user.password) &&
      (signin.user.password === signin.user.confirmation)
    ) {
      dispatch(SIGNIN_USER({
        mail: signin.user.mail,
        type: signin.user.type,
        unique_id_user: get_id(),
        unique_id_companie: get_id(),
        password: signin.user.password,
        companie: signin.user.companie,
        nom: lower_case(signin.user.nom),
        confirmation: signin.user.confirmation,
        prenom: lower_case(signin.user.prenom),
        color: {
          r: random_number(0, 255),
          g: random_number(0, 255),
          b: random_number(0, 255)
        }
      }))
      setStep(2)
    } else {
      UpdateError('nom', !CheckTexte(signin.user.nom))
      UpdateError('mail', !CheckMail(signin.user.mail))
      UpdateError('prenom', !CheckTexte(signin.user.prenom))
      UpdateError('companie', !CheckTexte(signin.user.companie))
      UpdateError('password', !CheckPassword(signin.user.password))
      UpdateError('confirmation', (
        (signin.user.confirmation === '') ||
        (signin.user.password !== signin.user.confirmation)
      ))
    }
  }
  return (
    <div className={styles.container}>
      <Form fit={true}>
        <InputPrenom
          setValue={Prenom}
          error={error.prenom}
          value={signin.user.prenom}
        />
        <InputNom
          setValue={Nom}
          uppercase={true}
          error={error.nom}
          value={signin.user.nom}
        />
        <InputMail
          setValue={Mail}
          error={error.mail}
          value={signin.user.mail}
        />
        <InputPassword
          setValue={Password}
          confirmation={false}
          label="Mot de passe"
          error={error.password}
          value={signin.user.password}
        />
        <InputPassword
          confirmation={true}
          label="Confirmation"
          setValue={Confirmation}
          error={error.confirmation}
          value={signin.user.confirmation}
        />
        <InputCompanie
          setValue={Companie}
          error={error.companie}
          value={signin.user.companie}
        />
        <SelectTypeCompanie
          value={signin.user.type}
          setValue={(value) => dispatch(SIGNIN_UPDATE('type', value))}
        />
      </Form>
      <div className="signin-button">
        <Button
          color="primary"
          variant="contained"
          onClick={() => Commit()}
        >
          Continuer
        </Button>
      </div>
    </div>
  )
}

export default SigninForm
