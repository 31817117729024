import React from 'react'
import styles from './Hamburger.module.css'
import HamburgerIcone from './HamburgerIcone'
import HamburgerTexte from './HamburgerTexte'
import {
  useDispatch
} from 'react-redux'
import {
  ListItemButton
} from '@mui/material'
import {
  ExitToApp
} from '@mui/icons-material'
import {
  MENU_OPEN
} from '../../Redux/Actions/Menu'
import {
  USER_LOGOUT
} from '../../Redux/Actions/Login'

const HamburgerLogoutButton = () => {
  const dispatch = useDispatch()
  return (
    <ListItemButton
      className={styles.item}
      onClick={() => {
        dispatch(MENU_OPEN(false))
        dispatch(USER_LOGOUT(true))
      }}
    >
      <HamburgerIcone icone={<ExitToApp className={styles.exit}/>}/>
      <HamburgerTexte texte="Déconnexion"/>
    </ListItemButton>
  )
}

export default HamburgerLogoutButton
