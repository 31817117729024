import {
  get_id
} from '../../Functions/Functions'
import {
  config
} from '../../Variables/Variables'

const initial = {
  abonnement: {},
  user: {
    type: 1,
    token: '',
    nom: (config.test)
      ? get_id(10)
      : '',
    mail: (config.test)
      ? get_id(10).toLowerCase() + '@gmail.com'
      : '',
    prenom: (config.test)
      ? get_id(10)
      : '',
    companie: (config.test)
      ? get_id(10)
      : '',
    password: (config.test)
      ? 'Azerty1234@'
      : '',
    confirmation: (config.test)
      ? 'Azerty1234@'
      : ''
  }
}

function signin(state = initial, action) {
  switch (action.type) {
    case 'SIGNIN_USER':
      return {
        ...state,
        user: action.value
      }
    case 'SIGNIN_ABONNEMENT':
      return {
        ...state,
        abonnement: action.value
      }
    case 'SIGNIN_UPDATE':
      return {
        ...state,
        user: {
          ...state.user,
          [action.input]: action.value
        }
      }
    default:
      return state
  }
}

export default signin
