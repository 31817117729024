import React from 'react'
import UsersMobileTitre from './UsersMobileTitre'
import UsersToolbarMobile from './UsersToolbarMobile'
import TableData from '../../Includes/Table/TableData'
import EmptyTexte from '../../Includes/Misc/EmptyTexte'
import UsersToolbarDesktop from './UsersToolbarDesktop'
import SearchMobile from '../../Includes/Search/SearchMobile'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  Paper
} from '@mui/material'
import {
  properties
} from '../../../Settings/Users'
import {
  All
} from '../../../Functions/Table'
import {
  variables
} from '../../../Variables/Variables'
import {
  SEARCH_USER,
  SELECTED_USERS
} from '../../../Redux/Actions/Users'

const UsersContent = ({ data }) => {
  const dispatch = useDispatch()
  const [page, setPage] = React.useState(0)
  const device = useSelector(state => state.device)
  const users = useSelector(state => state.users.users)
  const search = useSelector(state => state.users.search)
  const loading = useSelector(state => state.users.loading)
  const selected = useSelector(state => state.users.selected)
  const Select = (event) => {
    All(dispatch, SELECTED_USERS, data.map(item => item.id), event)
  }
  return (users.length > 0)
    ? <>
        {
          (device.mobile)
            ? <UsersToolbarMobile setPage={setPage}/>
            : null
        }
        <Paper>
          {
            (device.mobile)
              ? <SearchMobile
                  data={data}
                  search={search}
                  onChange={Select}
                  selected={selected}
                  setSearch={(value) => dispatch(SEARCH_USER(value))}
                />
              : <UsersToolbarDesktop data={data}/>
          }
          {
            (data.length > 0)
              ? <TableData
                  data={data}
                  page={page}
                  All={Select}
                  setPage={setPage}
                  count={data.length}
                  selected={selected}
                  border={device.mobile}
                  titre={UsersMobileTitre}
                  {...properties}
                />
              : null
          }
        </Paper>
      </>
    : (!loading)
      ? <EmptyTexte>{variables.empty.users}</EmptyTexte>
      : null
}

export default UsersContent
