import Root from '../Includes/Wrapper/Root'
import Alert from '../Includes/Dialog/Alert'
import TableData from '../Includes/Table/TableData'
import NotificationsToolbar from './NotificationsToolbar'
import React, {
  useEffect
} from 'react'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  Paper
} from '@mui/material'
import {
  All
} from '../../Functions/Table'
import {
  properties
} from '../../Settings/Notifications'
import {
  GET_NOTIFICATIONS,
  DELETE_NOTIFICATION,
  MODAL_NOTIFICATIONS,
  SELECTED_NOTIFICATIONS
} from '../../Redux/Actions/Notifications'

const Notifications = () => {
  const dispatch = useDispatch()
  const device = useSelector(state => state.device)
  const equipe = useSelector(state => state.equipe.equipe)
  const open = useSelector(state => state.notifications.open)
  const selected = useSelector(state => state.notifications.selected)
  const notifications = useSelector(state => state.notifications.notifications)
  const team = equipe.filter(item => item.archive === 0).map(item => item.id)
  const data = notifications.filter(item =>
    team.includes(item.admin) &&
    team.includes(item.user)
  )
  const texte = (selected.length > 1)
    ? 'les notifications sélectionnées'
    : 'la notification sélectionnée'
  useEffect(() => {
    dispatch(GET_NOTIFICATIONS())
    // eslint-disable-next-line
  }, [])
  return (
    <Root
      container={true}
      className={
        (device.mobile)
          ? 'page-container-notifications-mobile'
          : 'page-container-notifications'
      }
    >
      <Alert
        open={open}
        titre="Confirmation"
        Cancel={() => dispatch(MODAL_NOTIFICATIONS(false))}
        texte={"Voulez-vous vraiment supprimer " + texte + " ?"}
        Commit={() => {
          dispatch(MODAL_NOTIFICATIONS(false))
          dispatch(SELECTED_NOTIFICATIONS([]))
          dispatch(DELETE_NOTIFICATION(selected))
        }}
      />
      {
        (data.length > 0)
          ? <Paper>
              <NotificationsToolbar/>
              {
                (equipe.length > 0)
                  ? <TableData
                      data={data}
                      count={data.length}
                      selected={selected}
                      All={(event) => All(dispatch, SELECTED_NOTIFICATIONS, data.map(item => item.id), event)}
                      {...properties}
                    />
                  : null
              }
            </Paper>
          : (equipe.length > 0)
            ? <div className="notifications-empty">Aucune notification</div>
            : null
      }
    </Root>
  )
}

export default Notifications
