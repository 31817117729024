import {
  USER_LOGOUT
} from './Login'
import {
  api
} from '../../API/api'
import {
  print_error
} from '../../Functions/Toast'

export const GET_USER_DATA = () => { // récupération des données utilisateur
  return (dispatch) => {
    api('get-user-data')
      .then(data => {
        if (data.status === 'OK') {
          dispatch({
            type: 'INIT_FONCTIONS',
            value: data.response.fonctions
          })
          dispatch({
            type: 'INIT_PRIMES',
            value: data.response.primes
          })
          dispatch({
            type: 'INIT_COMPTE',
            value: data.response.compte
          })
          dispatch({
            type: 'INIT_EQUIPE',
            value: data.response.equipe
          })
          dispatch({
            type: 'INIT_HEURES',
            value: data.response.heures
          })
          dispatch({
            type: 'INIT_EXPORTS',
            value: data.response.exports
          })
          dispatch({
            type: 'INIT_OFFICES',
            value: data.response.offices
          })
          dispatch({
            type: 'INIT_ABSENCES',
            value: data.response.absences
          })
          dispatch({
            type: 'INIT_SETTINGS',
            value: data.response.settings
          })
          dispatch({
            type: 'INIT_OUVERTURE',
            value: data.response.ouverture
          })
          dispatch({
            type: 'INIT_INDEMNITES',
            value: data.response.indemnites
          })
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          print_error(data.response)
        }
      })
      .catch(() => {
        print_error()
      })
  }
}
