import React from 'react'
import ButtonGestion from './ButtonGestion'

const ButtonGestionBeta = (props) => {
  return (
    <ButtonGestion
      icon="Code"
      color="red"
      title="Beta"
      {...props}
    />
  )
}

export default ButtonGestionBeta
