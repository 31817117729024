import React from 'react'
import ButtonGestionBeta from '../../Includes/Button/ButtonGestionBeta'
import ButtonGestionBlock from '../../Includes/Button/ButtonGestionBlock'
import ButtonGestionUnblock from '../../Includes/Button/ButtonGestionUnblock'
import ButtonGestionProduction from '../../Includes/Button/ButtonGestionProduction'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  BLOCK_COMPANIE,
  MODAL_COMPANIES,
  ENVIRONMENT_COMPANIE
} from '../../../Redux/Actions/Companies'

const CompaniesToolbarButtons = () => {
  const dispatch = useDispatch()
  const selected = useSelector(state => state.companies.selected)
  const Texte = (action) => {
    const texte = (selected.length > 1)
      ? "les entreprises sélectionnées"
      : "l'entreprise sélectionnée"
    switch (action) {
      case 'block':
        return (
          <span>Voulez-vous vraiment <b>bloquer</b> {texte} ?</span>
        )
      case 'unblock':
        return (
          <span>Voulez-vous vraiment <b>débloquer</b> {texte} ?</span>
        )
      case 'beta':
        return (
          <span>Voulez-vous vraiment basculer {texte} sur la version <b>beta</b> ?</span>
        )
      case 'production':
        return (
          <span>Voulez-vous vraiment basculer {texte} sur la version de <b>production</b> ?</span>
        )
      default:
      break
    }
  }
  return (
    <>
      <ButtonGestionUnblock
        disabled={(selected.length === 0)}
        onClick={() => {
          dispatch(MODAL_COMPANIES(
            true,
            Texte('unblock'),
            () => dispatch(BLOCK_COMPANIE(selected, 0))
          ))
        }}
      />
      <ButtonGestionBlock
        disabled={(selected.length === 0)}
        onClick={() => {
          dispatch(MODAL_COMPANIES(
            true,
            Texte('block'),
            () => dispatch(BLOCK_COMPANIE(selected, 1))
          ))
        }}
      />
      <ButtonGestionProduction
        disabled={(selected.length === 0)}
        onClick={() => {
          dispatch(MODAL_COMPANIES(
            true,
            Texte('production'),
            () => dispatch(ENVIRONMENT_COMPANIE(selected, 0))
          ))
        }}
      />
      <ButtonGestionBeta
        disabled={(selected.length === 0)}
        onClick={() => {
          dispatch(MODAL_COMPANIES(
            true,
            Texte('beta'),
            () => dispatch(ENVIRONMENT_COMPANIE(selected, 1))
          ))
        }}
      />
    </>
  )
}

export default CompaniesToolbarButtons
