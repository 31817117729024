import PlanningModalFonctionContent from './PlanningModalFonctionContent'
import React, {
  useEffect
} from 'react'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from '../Includes/Dialog/Dialog'
import {
  SET_FILTER_SALARIES
} from '../../Redux/Actions/Filters'

const PlanningModalFonction = ({
  open,
  setOpen
}) => {
  const dispatch = useDispatch()
  const [users, setUsers] = React.useState([])
  const equipe = useSelector(state => state.equipe.equipe)
  const filter = useSelector(state => state.filters.salaries)
  const team = equipe.filter(item => item.archive === 0)
  useEffect(() => {
    if (open) {
      setUsers(team.filter(item => filter.indexOf(item.id) < 0).map(item => item.id))
    }
    // eslint-disable-next-line
  }, [open, filter])
  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth={true}
    >
      <DialogTitle>Fonctions</DialogTitle>
      <DialogContent>
        <PlanningModalFonctionContent
          users={users}
          setUsers={setUsers}
        />
      </DialogContent>
      <DialogActions
        buttons={[
          {
            onClick: () => setOpen(false)
          },
          {
            onClick: () => {
              setOpen(false)
              dispatch(SET_FILTER_SALARIES(team.filter(item => users.indexOf(item.id) < 0).map(item => item.id)))
            }
          }
        ]}
      />
    </Dialog>
  )
}

export default PlanningModalFonction
