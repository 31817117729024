import React from 'react'
import styles from './Forbidden.module.css'
import {
  messages
} from '../../Variables/Messages'

const Forbidden = ({ target }) => (
  <div className={styles.error}>
    {messages.error[target]}
  </div>
)

export default Forbidden
