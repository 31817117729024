import {
  USER_LOGOUT
} from './Login'
import {
  api
} from '../../API/api'
import {
  print_error,
  update_toast,
  print_loading
} from '../../Functions/Toast'
import {
  get_id
} from '../../Functions/Functions'

export const SEARCH_USER = (value) => { // recherche d'un utilisateur
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'SEARCH_USER'
    })
  }
}

export const SELECTED_USERS = (value) => { // sélection des utilisateurs
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'SELECTED_USERS'
    })
  }
}

export const MODAL_USERS = (open, texte, action) => { // modale pour bloquer/débloquer ou déconnecter des utilisateurs
  return (dispatch) => {
    dispatch({
      open: open,
      texte: texte,
      action: action,
      type: 'MODAL_USERS'
    })
  }
}

export const GET_USERS = () => { // récupération des utilisateurs en BDD
  return (dispatch) => {
    api('get-users')
      .then(data => {
        if (data.status === 'OK') {
          dispatch({
            type: 'GET_USERS',
            value: data.response
          })
          dispatch({
            type: 'LOADING_USERS'
          })
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          print_error(data.response)
        }
      })
      .catch(() => {
        print_error()
      })
  }
}

export const DISCONNECT_USER = (users) => { // déconnexion des utilisateurs
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    api('disconnect-user', {
      users: users
    })
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          dispatch({
            users: users,
            type: 'DISCONNECT_USER'
          })
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}

export const BLOCK_USER = (users, value) => { // bloquer/débloquer des utilisateurs
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    api('block-user', {
      users: users,
      value: value
    })
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          dispatch({
            users: users,
            value: value,
            type: 'BLOCK_USER'
          })
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}
