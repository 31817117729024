import Info from '../Includes/Wrapper/Info'
import styles from './Newsletter.module.css'
import history from '../../Navigation/Includes/History'
import React, {
  useEffect
} from 'react'
import {
  useDispatch
} from 'react-redux'
import {
  Button
} from '@mui/material'
import {
  useLocation
} from 'react-router-dom'
import {
  CheckToken
} from '../../Functions/Check'
import {
  GET_NEWSLETTER,
  UPDATE_NEWSLETTER
} from '../../Redux/Actions/Newsletter'

const Newsletter = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const [pending, setPending] = React.useState(false)
  const [state, setState] = React.useState({
    mail: undefined,
    newsletter: undefined
  })
  const match = location.pathname.match(/^\/newsletter\/([^/]+)$/)
  const token = (match) ? match[1] : ''
  useEffect(() => {
    if (CheckToken(token)) {
      dispatch(GET_NEWSLETTER(
        token,
        (data) => setState(data),
        () => history.push('/error')
      ))
    } else {
      history.push('/error')
    }
    // eslint-disable-next-line
  }, [token])
  return (state.newsletter !== undefined)
    ? <Info icon="newsletter">
        <div>
          <span>Bonjour </span>
          <span className={styles.mail}>
            {state.mail}
          </span>
          <span>,</span>
        </div>
        <div>
          <span>Vous êtes actuellement </span>
          <b>
            {
              (state.newsletter)
                ? "abonné"
                : "désabonné"
            }
          </b>
          <span> à notre newsletter.</span>
        </div>
        <div>
          {
            (state.newsletter)
              ? "Souhaitez-vous vraiment vous désabonner ?"
              : "Souhaitez-vous revenir ?"
          }
        </div>
        <div className={styles.button}>
          {
            (!pending)
              ? <Button
                  variant="contained"
                  color={
                    (state.newsletter)
                      ? "secondary"
                      : "primary"
                  }
                  onClick={() => {
                    const value = (state.newsletter) ? 0 : 1
                    dispatch(UPDATE_NEWSLETTER(
                      token,
                      value,
                      setPending,
                      () => setState({
                        ...state,
                        newsletter: value
                      })
                    ))
                  }}
                >
                  {
                    (state.newsletter)
                      ? "Se désinscrire"
                      : "Se réinscrire"
                  }
                </Button>
              : null
          }
        </div>
      </Info>
    : null
}

export default Newsletter
