import React from 'react'
import styles from './BarDie.module.css'
import BarTitleMobile from './BarTitleMobile'
import BarTitleDesktop from './BarTitleDesktop'
import ButtonMenu from '../../Components/Includes/Button/ButtonMenu'
import ButtonLogout from '../../Components/Includes/Button/ButtonLogout'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  Toolbar
} from '@mui/material'
import {
  USER_LOGOUT
} from '../../Redux/Actions/Login'
import {
  SELECTED_NOTIFICATION
} from '../../Redux/Actions/Notifications'

const BarDie = () => {
  const dispatch = useDispatch()
  const device = useSelector(state => state.device)
  return (
    <Toolbar className="bar-menu">
      {
        (device.mobile)
          ? <>
              <ButtonMenu
                edge="start"
                className={styles.disabled}
              />
              <BarTitleMobile/>
            </>
          : <BarTitleDesktop/>
      }
      <ButtonLogout
        edge="start"
        className={styles.button}
        onClick={() => {
          dispatch(SELECTED_NOTIFICATION(''))
          dispatch(USER_LOGOUT(true))
        }}
      />
    </Toolbar>
  )
}

export default BarDie
