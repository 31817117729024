import React from 'react'
import Badge from '../Wrapper/Badge'
import Planning from '../Hooks/Planning'
import ButtonIconEye from '../Button/ButtonIconEye'
import ButtonIconUser from '../Button/ButtonIconUser'
import ButtonIconWeek from '../Button/ButtonIconWeek'
import ButtonIconFonction from '../Button/ButtonIconFonction'
import PlanningModalCreneau from '../../Planning/PlanningModalCreneau'
import PlanningModalSalarie from '../../Planning/PlanningModalSalarie'
import PlanningModalSemaine from '../../Planning/PlanningModalSemaine'
import PlanningModalFonction from '../../Planning/PlanningModalFonction'
import PlanningModalSettings from '../../Planning/PlanningModalSettings'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  first_day_format
} from '../../../Functions/Dates'
import {
  SELECTED_CRENEAU,
  EDIT_CRENEAU_PLANNING,
  UPDATE_HEURE_PLANNING_HORAIRES
} from '../../../Redux/Actions/Planning'
import {
  SET_CRENEAU_SEMAINE_EQUIPE,
  UPDATE_CRENEAU_SEMAINE_HORAIRES
} from '../../../Redux/Actions/Semaines'
import {
  Toolbar
} from '@devexpress/dx-react-scheduler-material-ui'

const EventToolbarMobile = () => {
  const planning = Planning()
  const dispatch = useDispatch()
  const view = useSelector(state => state.planning.view)
  const equipe = useSelector(state => state.equipe.equipe)
  const event = useSelector(state => state.planning.event)
  const filter = useSelector(state => state.filters.salaries)
  const [modalSalarie, setModalSalarie] = React.useState(false)
  const [modalSemaine, setModalSemaine] = React.useState(false)
  const semaines = useSelector(state => state.semaines.semaines)
  const [modalFonction, setModalFonction] = React.useState(false)
  const [modalSettings, setModalSettings] = React.useState(false)
  const team = equipe.filter(item => item.archive === 0)
  const badge = {
    user: team.filter(item => filter.indexOf(item.id) < 0).length,
    fonction: [...new Set(team.filter(item => filter.indexOf(item.id) < 0).map(item => item.fonction))].length
  }
  return (
    <Toolbar.Root className="toolbar toolbar-actions">
      <PlanningModalCreneau
        open={(
          (event.mobile !== undefined) &&
          (event.mobile === true)
        )}
        setOpen={() => {
          dispatch(SELECTED_CRENEAU({
            id: '',
            read: false
          }))
        }}
        Commit={(value) => {
          if (planning) {
            if (value.heure) {
              dispatch(UPDATE_HEURE_PLANNING_HORAIRES({
                [value.id]: {
                  endDate: value.endDate,
                  startDate: value.startDate
                }
              }))
            }
            dispatch(EDIT_CRENEAU_PLANNING({
              ...value,
              week: first_day_format(value.startDate)
            }))
          } else {
            dispatch(UPDATE_CRENEAU_SEMAINE_HORAIRES({
              [value.id]: {
                endDate: value.endDate,
                startDate: value.startDate
              }
            }))
            dispatch(SET_CRENEAU_SEMAINE_EQUIPE({
              creneau: value.id,
              equipe: value.equipe
            }))
          }
        }}
      />
      <PlanningModalSettings
        open={modalSettings}
        setOpen={setModalSettings}
      />
      <PlanningModalSalarie
        open={modalSalarie}
        setOpen={setModalSalarie}
      />
      <PlanningModalSemaine
        open={modalSemaine}
        setOpen={setModalSemaine}
      />
      <PlanningModalFonction
        open={modalFonction}
        setOpen={setModalFonction}
      />
      {
        (
          (semaines.length > 0) &&
          (view === 'Week') &&
          (planning)
        )
          ? <ButtonIconWeek
              onClick={() => setModalSemaine(true)}
            />
          : null
      }
      <Badge content={badge.user}>
        <ButtonIconUser
          onClick={() => setModalSalarie(true)}
        />
      </Badge>
      <Badge content={badge.fonction}>
        <ButtonIconFonction
          onClick={() => setModalFonction(true)}
        />
      </Badge>
      <ButtonIconEye
        onClick={() => setModalSettings(true)}
      />
    </Toolbar.Root>
  )
}

export default EventToolbarMobile
