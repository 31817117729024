import clsx from 'clsx'
import React from 'react'
import Bulle from './Bulle'
import styles from './InfoBulle.module.css'
import {
  Info
} from '@mui/icons-material'

const InfoBulle = ({
  style,
  ...others
}) => {
  return (
    <Bulle
      arrow={true}
      placement="top"
      {...others}
    >
      <Info
        className={
          clsx(
            style,
            styles.info
          )
        }
      />
    </Bulle>
  )
}

export default InfoBulle
