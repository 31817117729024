import clsx from 'clsx'
import React from 'react'
import dayjs from '../../../Libraries/dayjs'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  TableCell
} from '@mui/material'
import {
  start_case
} from '../../../Functions/Strings'
import {
  UPDATE_PLANNING,
  SELECT_PLANNING_DAY
} from '../../../Redux/Actions/Planning'

const EventScaleCellDate = ({
  today,
  holidays,
  startDate
}) => {
  const dispatch = useDispatch()
  const device = useSelector(state => state.device)
  const view = useSelector(state => state.planning.view)
  return (
    <TableCell className="table-cell">
      <div
        onClick={() => {
          if (view === 'Week') {
            dispatch(SELECT_PLANNING_DAY(startDate))
            dispatch(UPDATE_PLANNING())
          }
        }}
        className={
          clsx({
            'cell-day': true,
            'cell-today': today,
            'text-center': true,
            'pointer': (view === 'Week'),
            'cell-day-hover': (view === 'Week'),
            'cell-ferie': (holidays[dayjs(startDate).weekday()] === true)
          })
        }
      >
        {
          (device.mobile)
            ? <div className="div-day">
                {
                  (view === 'Week')
                    ? start_case(dayjs(startDate).format('dd.'))
                    : start_case(dayjs(startDate).format('dddd'))
                }
              </div>
            : <>
                <div className="div-day">
                  {start_case(dayjs(startDate).format('dddd'))}
                </div>
                <div className="div-year">
                  {start_case(dayjs(startDate).format('DD/MM/YYYY'))}
                </div>
              </>
        }
      </div>
    </TableCell>
  )
}

export default EventScaleCellDate
