import React from 'react'
import HeureModal from './HeureModal'
import dayjs from '../../Libraries/dayjs'
import HeuresContent from './HeuresContent'
import Root from '../Includes/Wrapper/Root'
import Alert from '../Includes/Dialog/Alert'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  convert_heure
} from '../../Functions/Heures'
import {
  DELETE_HEURE,
  MODAL_HEURES
} from '../../Redux/Actions/Heures'

const Heures = () => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.login.user)
  const modal = useSelector(state => state.heures.modal)
  const array = useSelector(state => state.heures.heures)
  const equipe = useSelector(state => state.equipe.equipe)
  const filters = useSelector(state => state.filters.pages)
  const settings = useSelector(state => state.settings.variables)
  const fonctions = useSelector(state => state.fonctions.fonctions)
  const notification = useSelector(state => state.notifications.notification)
  const heures = array.filter(item =>
    (
      (user.profil === 'administrateur') ||
      (
        (item.user === user.unique_id_user) &&
        (user.profil !== 'administrateur')
      )
    )
  )
  const data = heures.map(item => {
    const signe = (item.sens === 0) ? '+' : '-'
    const user = equipe.find(object => object.id === item.user)
    const fonction = fonctions.find(object => object.id === user.fonction)
    const number = (item.nombre > 0)
      ? item.nombre
      : (dayjs.unix(item.fin) - dayjs.unix(item.debut)) / 60000
    return {
      ...item,
      nom: user.nom,
      prenom: user.prenom,
      archive: user.archive,
      fonction: fonction.fonction,
      cause: settings.heure.motifs[item.motif],
      value: signe + convert_heure(number, false),
      number: (item.sens === 0) ? number : -number
    }
  }).filter(item => item.archive === 0).filter(item =>
    (notification === '')
      ? (
          ((filters.heures.dates[0] === null) || (dayjs.unix(item.debut).isSameOrAfter(filters.heures.dates[0]))) &&
          ((filters.heures.dates[1] === null) || (dayjs.unix(item.fin).isSameOrBefore(filters.heures.dates[1]))) &&
          ((item.status === filters.heures.status) || (parseInt(filters.heures.status) === -1)) &&
          ((item.user === filters.heures.salarie) || (parseInt(filters.heures.salarie) === -1)) &&
          ((item.motif === filters.heures.motif) || (parseInt(filters.heures.motif) === -1))
        )
      : (item.id === notification)
  )
  return (
    <Root container={true}>
      {
        (equipe.length > 0)
          ? <HeureModal/>
          : null
      }
      {
        (data.length > 0)
          ? <Alert
              titre="Confirmation"
              open={(modal.open === 'delete')}
              Cancel={() => dispatch(MODAL_HEURES('', ''))}
              texte="Voulez-vous vraiment supprimer cette heure ?"
              Commit={() => {
                dispatch(MODAL_HEURES('', ''))
                dispatch(DELETE_HEURE(modal.id))
              }}
            />
          : null
      }
      <HeuresContent
        data={data}
        total={heures.length}
      />
    </Root>
  )
}

export default Heures
