import React from 'react'
import Page from '../Includes/Wrapper/Page'
import Planning from '../Includes/Wrapper/Planning'
import LegendePlanning from '../Includes/Legende/LegendePlanning'
import AbsencesPlanningScheduler from './AbsencesPlanningScheduler'
import AbsencesPlanningToolbarMobile from './AbsencesPlanningToolbarMobile'
import AbsencesPlanningToolbarDesktop from './AbsencesPlanningToolbarDesktop'
import {
  useSelector
} from 'react-redux'

const AbsencesPlanningContent = ({ data }) => {
  const device = useSelector(state => state.device)
  const filter = useSelector(state => state.filters.salaries)
  const absence = useSelector(state => state.absences.absence)
  const absences = useSelector(state => state.absences.absences)
  const liste = data.map(item => item.user).flat().filter((item, index, array) => array.indexOf(item) === index)
  return (
    <Page column={device.mobile}>
      {
        (device.mobile)
          ? <AbsencesPlanningToolbarMobile/>
          : null
      }
      <Planning>
        {
          (device.desktop)
            ? <AbsencesPlanningToolbarDesktop/>
            : null
        }
        <AbsencesPlanningScheduler absences={data}/>
      </Planning>
      {
        (
          (liste.length > 0) &&
          (device.desktop)
        )
          ? <LegendePlanning
              liste={liste}
              absence={(
                (absence !== '') &&
                (filter.indexOf(absences.find(item => item.id === absence).user) < 0)
              )}
            />
          : null
      }
    </Page>
  )
}

export default AbsencesPlanningContent
