import clsx from 'clsx'
import React from 'react'
import styles from './Event.module.css'
import CreneauContent from '../Creneau/CreneauContent'
import {
  useDrop
} from 'react-dnd'
import {
  useSelector
} from 'react-redux'

const EventDrop = ({ data }) => {
  const team = useSelector(state => state.equipe.equipe)
  const filter = useSelector(state => state.filters.salaries)
  const equipe = team.filter(item => data.equipe.indexOf(item.id) < 0).map(item => item.id.toString()).filter(item => filter.indexOf(item) < 0)
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: equipe,
    drop: () => ({
      id: data.id
    }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  }), [equipe])
  return (
    <div
      ref={drop}
      className={
        clsx(
          styles.drop,
          styles.appointment,
          (isOver && canDrop)
            ? styles.hover
            : (canDrop)
              ? styles.normal
              : undefined
        )
      }
    >
      <CreneauContent data={data}/>
    </div>
  )
}

export default EventDrop
