import React from 'react'
import dayjs from '../../Libraries/dayjs'
import Root from '../Includes/Wrapper/Root'
import Alert from '../Includes/Dialog/Alert'
import IndemniteModal from './IndemniteModal'
import IndemnitesContent from './IndemnitesContent'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  DELETE_INDEMNITE,
  MODAL_INDEMNITES
} from '../../Redux/Actions/Indemnites'

const Indemnites = () => {
  const dispatch = useDispatch()
  const equipe = useSelector(state => state.equipe.equipe)
  const filters = useSelector(state => state.filters.pages)
  const modal = useSelector(state => state.indemnites.modal)
  const fonctions = useSelector(state => state.fonctions.fonctions)
  const indemnites = useSelector(state => state.indemnites.indemnites)
  const notification = useSelector(state => state.notifications.notification)
  const data = indemnites.map(item => {
    const user = equipe.find(object => object.id === item.user)
    const fonction = fonctions.find(object => object.id === user.fonction)
    return {
      ...item,
      nom: user.nom,
      prenom: user.prenom,
      archive: user.archive,
      fonction: fonction.fonction
    }
  }).filter(item => item.archive === 0).filter(item =>
    (notification === '')
      ? (
          ((filters.indemnites.dates[1] === null) || (dayjs.unix(item.date).isSameOrBefore(filters.indemnites.dates[1]))) &&
          ((filters.indemnites.dates[0] === null) || (dayjs.unix(item.date).isSameOrAfter(filters.indemnites.dates[0]))) &&
          ((item.status === filters.indemnites.status) || (parseInt(filters.indemnites.status) === -1)) &&
          ((item.user === filters.indemnites.salarie) || (parseInt(filters.indemnites.salarie) === -1))
        )
      : (item.id === notification)
  )
  return (
    <Root container={true}>
      {
        (equipe.length > 0)
          ? <IndemniteModal/>
          : null
      }
      {
        (data.length > 0)
          ? <Alert
              titre="Confirmation"
              open={(modal.open === 'delete')}
              Cancel={() => dispatch(MODAL_INDEMNITES('', ''))}
              texte="Voulez-vous vraiment supprimer cette indemnité ?"
              Commit={() => {
                dispatch(MODAL_INDEMNITES('', ''))
                dispatch(DELETE_INDEMNITE(modal.id))
              }}
            />
          : null
      }
      <IndemnitesContent
        data={data}
        total={indemnites.length}
      />
    </Root>
  )
}

export default Indemnites
