import React from 'react'
import InfoBulle from '../Wrapper/InfoBulle'
import TableCellMobile from './TableCellMobile'
import styles from './TableCellMotif.module.css'
import TableCellDesktop from './TableCellDesktop'

const TableCellMotif = ({
  item,
  label,
  device
}) => {
  const other = (item.motif === 'autre')
  return (device.mobile)
    ? <TableCellMobile label={label}>
        {
          (other)
            ? item.texte
            : item.cause
        }
      </TableCellMobile>
    : <TableCellDesktop max={150}>
        <>
          {item.cause}
          {
            (other)
              ? <InfoBulle
                  title={item.texte}
                  style={styles.motif}
                />
              : null
          }
        </>
      </TableCellDesktop>
}

export default TableCellMotif
