import React from 'react'
import SelectSalarie from '../Select/SelectSalarie'

const FilterSalarie = (props) => {
  return (
    <SelectSalarie
      filter={true}
      {...props}
    />
  )
}

export default FilterSalarie
