import FilterEquipeMobileContent from './FilterEquipeMobileContent'
import React, {
  useEffect
} from 'react'
import {
  useSelector
} from 'react-redux'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from '../Dialog/Dialog'

const FilterEquipeMobile = ({
  open,
  Commit,
  setOpen
}) => {
  const [state, setState] = React.useState({})
  const filters = useSelector(state => state.filters.pages)
  const Update = (item, value) => {
    setState(current => ({
      ...current,
      [item]: value
    }))
  }
  useEffect(() => {
    if (open) {
      setState(filters.equipe)
    }
    // eslint-disable-next-line
  }, [open])
  return (
    <Dialog open={open}>
      <DialogTitle>Filtrer les salariés</DialogTitle>
      <DialogContent>
        <FilterEquipeMobileContent
          state={state}
          Update={Update}
        />
      </DialogContent>
      <DialogActions
        buttons={[
          {
            onClick: () => setOpen(false)
          },
          {
            onClick: () => {
              setOpen(false)
              Commit(state)
            }
          }
        ]}
      />
    </Dialog>
  )
}

export default FilterEquipeMobile
