import React from 'react'
import UsersToolbarButtons from './UsersToolbarButtons'
import FilterGestion from '../../Includes/Filter/FilterGestion'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  SET_FILTERS_PAGE
} from '../../../Redux/Actions/Filters'

const UsersToolbarMobile = ({ setPage }) => {
  const dispatch = useDispatch()
  const users = useSelector(state => state.users.users)
  const filters = useSelector(state => state.filters.pages)
  return (
    <div className="toolbar-actions-mobile toolbar-actions-mobile-users">
      <UsersToolbarButtons/>
      <FilterGestion
        data={users}
        target="users"
        setPage={setPage}
        label="utilisateurs"
        state={filters.users}
        setState={(value) => dispatch(SET_FILTERS_PAGE('users', value))}
      />
    </div>
  )
}

export default UsersToolbarMobile
