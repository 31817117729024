import React from 'react'
import FilterCodesContent from './FilterCodesContent'
import FilterUsersContent from './FilterUsersContent'
import FilterCompaniesContent from './FilterCompaniesContent'

const FilterGestionContent = ({
  target,
  ...others
}) => {
  switch (target) {
    case 'codes':
      return <FilterCodesContent {...others}/>
    case 'users':
      return <FilterUsersContent {...others}/>
    case 'companies':
      return <FilterCompaniesContent {...others}/>
    default:
      return null
  }
}

export default FilterGestionContent
