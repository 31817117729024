import CardForm from '../Stripe/CardForm'
import React, {
  useEffect
} from 'react'
import {
  useDispatch
} from 'react-redux'
import {
  print_error
} from '../../Functions/Toast'
import {
  check_input
} from '../../Functions/Stripe'
import {
  useStripe,
  useElements,
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement
} from '@stripe/react-stripe-js'
import {
  messages
} from '../../Variables/Messages'
import {
  initial
} from '../../Variables/Variables'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from '../Includes/Dialog/Dialog'
import {
  UPDATE_COMPANIE_CARD
} from '../../Redux/Actions/Stripe'

const CompteStripe = ({
  open,
  setOpen
}) => {
  const stripe = useStripe()
  const dispatch = useDispatch()
  const elements = useElements()
  const [disabled, setDisabled] = React.useState(false)
  const [error, setError] = React.useState({
    cvc: false,
    card: false,
    expire: false
  })
  const [state, setState] = React.useState({
    pending: false,
    cvc: initial.stripe,
    card: initial.stripe,
    expire: initial.stripe
  })
  const [success, setSuccess] = React.useState({
    cvc: false,
    card: false,
    expire: false
  })
  const Update = (item, value) => {
    setState(current => ({
      ...current,
      [item]: value
    }))
  }
  const UpdateError = (item, value) => {
    setError(current => ({
      ...current,
      [item]: value
    }))
  }
  const UpdateSuccess = (item, value) => {
    setSuccess(current => ({
      ...current,
      [item]: value
    }))
  }
  const Error = () => {
    Disable(false)
    print_error(messages.stripe.card)
  }
  const Disable = (value) => {
    setDisabled(value)
    Update('pending', value)
    const cvc = elements.getElement(CardCvcElement)
    const card = elements.getElement(CardNumberElement)
    const expire = elements.getElement(CardExpiryElement)
    cvc.update({
      disabled: value
    })
    card.update({
      disabled: value
    })
    expire.update({
      disabled: value
    })
  }
  const Submit = async(event) => {
    event.preventDefault()
    if (
      (success.expire) &&
      (success.card) &&
      (success.cvc)
    ) {
      Disable(true)
      UpdateError('cvc', false)
      UpdateError('card', false)
      UpdateError('expire', false)
      if (
        (!elements) ||
        (!stripe)
      ) {
        return
      }
      stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardNumberElement)
      })
        .then(data => {
          if (
            (data.paymentMethod) &&
            (data.paymentMethod.id !== '')
          ) {
            dispatch(UPDATE_COMPANIE_CARD(data.paymentMethod.id))
            setOpen(false)
            Disable(false)
          } else {
            Error()
          }
        })
        .catch(() => {
          Error()
        })
    } else {
      UpdateSuccess('cvc', check_input(state.cvc))
      UpdateSuccess('card', check_input(state.card))
      UpdateSuccess('expire', check_input(state.expire))
      if (state.cvc.empty === true) {
        UpdateError('cvc', true)
        Update('cvc', {
          ...state.cvc,
          error: {
            ...state.cvc.error,
            message: messages.stripe.empty.cvc
          }
        })
      }
      if (state.card.empty === true) {
        UpdateError('card', true)
        Update('card', {
          ...state.card,
          error: {
            ...state.card.error,
            message: messages.stripe.empty.card
          }
        })
      }
      if (state.expire.empty === true) {
        UpdateError('expire', true)
        Update('expire', {
          ...state.expire,
          error: {
            ...state.expire.error,
            message: messages.stripe.empty.expire
          }
        })
      }
    }
  }
  useEffect(() => {
    if (open) {
      UpdateError('cvc', false)
      UpdateError('card', false)
      UpdateError('expire', false)
      UpdateSuccess('cvc', false)
      UpdateSuccess('card', false)
      UpdateSuccess('expire', false)
      Update('cvc', initial.stripe)
      Update('card', initial.stripe)
      Update('expire', initial.stripe)
    }
    // eslint-disable-next-line
  }, [open])
  return (
    <Dialog
      open={open}
      maxWidth="xs"
      fullWidth={true}
      fullScreen={false}
    >
      <form
        id="stripe-form"
        onSubmit={Submit}
      >
        <DialogTitle>Modifier votre moyen de paiement</DialogTitle>
        <DialogContent>
          <CardForm
            error={error}
            state={state}
            Update={Update}
            UpdateError={UpdateError}
            UpdateSuccess={UpdateSuccess}
          />
        </DialogContent>
        <DialogActions
          buttons={[
            {
              disabled: disabled,
              onClick: () => setOpen(false)
            },
            {
              type: 'submit',
              disabled: disabled
            }
          ]}
        />
      </form>
    </Dialog>
  )
}

export default CompteStripe
