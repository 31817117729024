import React from 'react'
import about from '../../../about.json'
import dayjs from '../../../Libraries/dayjs'
import {
  landing
} from '../../../Variables/Landing'
import {
  config
} from '../../../Variables/Variables'

const Copyright = () => {
  const year = dayjs().year()
  const beta = (config.beta === '1') ? 'Beta ' : ''
  return (
    <p>
      <span>Version {beta}{about.version.major}.{about.version.minor}.{about.version.build}. </span>
      <span>© {year} {landing.nom}. </span>
      <span>Tous droits réservés.</span>
    </p>
  )
}

export default Copyright
