import React from 'react'
import styles from './Compte.module.css'
import Titre from '../Includes/Misc/Titre'
import history from '../../Navigation/Includes/History'
import InputPassword from '../Includes/Input/InputPassword'
import ButtonCompteEdit from '../Includes/Button/ButtonCompteEdit'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  Paper,
  Toolbar
} from '@mui/material'
import {
  CheckPassword
} from '../../Functions/Check'
import {
  UPDATE_USER_PASSWORD
} from '../../Redux/Actions/Compte'

const ComptePassword = () => {
  const dispatch = useDispatch()
  const device = useSelector(state => state.device)
  const [error, setError] = React.useState({
    password: false,
    confirmation: false
  })
  const [state, setState] = React.useState({
    password: '',
    disabled: false,
    confirmation: ''
  })
  const Update = (item, value) => {
    setState(current => ({
      ...current,
      [item]: value
    }))
  }
  const UpdateError = (item, value) => {
    setError(current => ({
      ...current,
      [item]: value
    }))
  }
  const Password = (value) => {
    Update('password', value)
    UpdateError('confirmation', (value !== state.confirmation))
    UpdateError('password', (
      (value !== '') &&
      (!CheckPassword(value))
    ))
  }
  const Confirmation = (value) => {
    Update('confirmation', value)
    UpdateError('confirmation', (value !== state.password))
  }
  const Commit = () => {
    if (
      CheckPassword(state.password) &&
      (state.password === state.confirmation)
    ) {
      Update('disabled', true)
      UpdateError('password', false)
      UpdateError('confirmation', false)
      dispatch(UPDATE_USER_PASSWORD(
        state.password,
        () => Update('disabled', false),
        () => history.push('/login')
      ))
    } else {
      UpdateError('password', !CheckPassword(state.password))
      UpdateError('confirmation', (
        (state.confirmation === '') ||
        (state.password !== state.confirmation)
      ))
    }
  }
  return (
    <Paper
      className={
        (device.mobile)
          ? styles.mobile
          : styles.desktop
      }
    >
      <Toolbar>
        <Titre>Mon mot de passe</Titre>
        <ButtonCompteEdit
          onClick={() => Commit()}
          disabled={state.disabled}
        />
      </Toolbar>
      <div className="form-password">
        <div className="block">
          <InputPassword
            setValue={Password}
            label="Mot de passe"
            confirmation={false}
            error={error.password}
            value={state.password}
          />
        </div>
        <div className="block div-input-confirmation">
          <InputPassword
            confirmation={true}
            label="Confirmation"
            setValue={Confirmation}
            error={error.confirmation}
            value={state.confirmation}
            onKeyUp={(event) => {
              if (event.key === 'Enter') {
                Commit()
              }
            }}
          />
        </div>
      </div>
    </Paper>
  )
}

export default ComptePassword
