import {
  USER_LOGOUT
} from './Login'
import {
  api
} from '../../API/api'
import {
  update_toast,
  print_loading
} from '../../Functions/Toast'
import {
  get_id
} from '../../Functions/Functions'

export const MODAL_OFFICES = (id, open, name) => { // modale pour créer, modifier ou supprimer un compte office
  return (dispatch) => {
    dispatch({
      id: id,
      name: name,
      open: open,
      type: 'MODAL_OFFICES'
    })
  }
}

export const UPDATE_OFFICE = (id, password) => { // mise à jour du mot de passe d'un compte office
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    api('update-office', {
      id: id,
      password: password
    })
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}

export const DELETE_OFFICE = (id) => { // suppression d'un compte office
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    api('delete-office', {
      id: id
    })
      .then(data => {
        if (data.status === 'OK') {
          dispatch({
            value: id,
            type: 'DELETE_OFFICE'
          })
          update_toast(toast, data.response, 'success')
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}

export const RESET_OFFICE = (id) => { // réinitialisation du mot de passe du compte office (envoi du mail)
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    dispatch({
      value: true,
      type: 'SET_OFFICE_DISABLED'
    })
    api('reset-office', {
      id: id
    })
      .then(data => {
        dispatch({
          value: false,
          type: 'SET_OFFICE_DISABLED'
        })
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
        dispatch({
          value: false,
          type: 'SET_OFFICE_DISABLED'
        })
      })
  }
}

export const CREATE_OFFICE = () => { // création d'un compte office
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    dispatch({
      value: true,
      type: 'SET_OFFICE_DISABLED'
    })
    api('create-office')
      .then(data => {
        dispatch({
          value: false,
          type: 'SET_OFFICE_DISABLED'
        })
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          dispatch({
            value: data.data,
            type: 'CREATE_OFFICE'
          })
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
        dispatch({
          value: false,
          type: 'SET_OFFICE_DISABLED'
        })
      })
  }
}
