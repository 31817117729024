import React from 'react'
import './Styles/Mui.css'
import './Styles/Icons.css'
import './Styles/Toast.css'
import './Styles/Mobile.css'
import './Styles/Desktop.css'
import './Styles/Variables.css'
import history from './Navigation/Includes/History'
import store from './Redux/Store/Store'
import Container from './Container'
import Content from './Content'
import Toast from './Toast'
import {
  Provider
} from 'react-redux'
import {
  Router
} from 'react-router-dom'
import {
  LicenseInfo
} from '@mui/x-license-pro'
import {
  frFR
} from '@mui/material/locale'
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider
} from '@mui/material/styles'
import {
  variables
} from './Variables/Variables'

LicenseInfo.setLicenseKey(variables.mui.key)

const theme = createTheme({
  palette: {
    primary: {
      main: '#3f51b5',
      dark: '#2c387e',
      light: '#6573c3',
      contrastText: '#fff'
    },
    secondary: {
      main: '#f44336',
      dark: '#aa2e25',
      light: '#f6685e',
      contrastText: '#fff'
    },
    aide: {
      main: '#1b9ba3',
      dark: '#12686e',
      light: '#24cfdb',
      contrastText: '#fff'
    },
    green: {
      main: '#4caf50',
      dark: '#357a38',
      light: '#6fbf73',
      contrastText: '#fff'
    }
  }
}, frFR)

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <StyledEngineProvider injectFirst={true}>
          <Router history={history}>
            <Container>
              <Content/>
              <Toast/>
            </Container>
          </Router>
        </StyledEngineProvider>
      </Provider>
    </ThemeProvider>
  )
}

export default App
