import {
  get_time
} from './Heures'

export function formation_time(time, short = false) {
  return get_time(time) + (
    (short)
      ? ''
      : ' formation'
  )
}

export function offer_time(days, short = false) {
  let s = ''
  let texte = ''
  const month = 30
  if (days === 1) {
    texte = '1 jour'
  } else if (days <= (month - 1)) {
    s = 's'
    texte = days + ' jours'
  } else if (days === month) {
    texte = '1 mois'
  } else {
    const nombre = Math.floor(days / month)
    s = (nombre > 1) ? 's' : ''
    texte = nombre + ' mois'
  }
  return texte + (
    (short)
      ? ''
      : ' offert' + s
  )
}
