import React from 'react'
import Bar from '../Bar/Bar'
import Page from '../../Landing/Page'
import Login from '../../Components/Login/Login'
import Forgot from '../../Components/Forgot/Forgot'
import Signin from '../../Components/Signin/Signin'
import Forbidden from '../../Components/Navigation/Forbidden'
import {
  Route,
  Switch,
  Redirect
} from 'react-router-dom'
import {
  config
} from '../../Variables/Variables'

const RoutesLogin = () => (
  <>
    <Bar/>
    <Switch>
      <Route exact path="/">
        <Page/>
      </Route>
      <Route exact path="/login">
        <Login/>
      </Route>
      <Route exact path="/signin">
        {
          (config.beta === '1')
            ? <Forbidden target="beta"/>
            : (config.demo === '1')
              ? <Forbidden target="demo"/>
              : <Signin/>
        }
      </Route>
      <Route exact path="/forgot">
        <Forgot/>
      </Route>
      <Route path="*">
        <Redirect to="/login"/>
      </Route>
    </Switch>
  </>
)

export default RoutesLogin
