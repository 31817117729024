import React from 'react'
import Form from '../Wrapper/Form'
import ErrorMessage from '../Misc/ErrorMessage'
import InputAutocomplete from '../Input/InputAutocomplete'
import {
  useSelector
} from 'react-redux'

const ToolbarSendContent = ({
  state,
  Update
}) => {
  const equipe = useSelector(state => state.equipe.equipe)
  const team = equipe.filter(item =>
    (item.archive === 0) &&
    (item.mail !== '')
  )
  return (
    <Form full={true}>
      {
        (equipe.length > 0)
          ? <InputAutocomplete
              options={team}
              label="Salariés"
              error={(state.error !== '')}
              value={team.filter(item => state.users.includes(item.id))}
              onChange={(event, value) => {
                if (value.length > 0) {
                  Update('error', '')
                }
                Update('users', value.map(item => item.id))
              }}
            />
          : null
      }
      {
        (state.error !== '')
          ? <ErrorMessage>{state.error}</ErrorMessage>
          : null
      }
    </Form>
  )
}

export default ToolbarSendContent
