import React from 'react'
import Bar from '../Bar/Bar'
import Empty from '../../Components/Navigation/Empty'
import Error from '../../Components/Navigation/Error'
import Mail from '../../Components/Gestion/Mail/Mail'
import Codes from '../../Components/Gestion/Codes/Codes'
import Users from '../../Components/Gestion/Users/Users'
import HamburgerGestion from '../Hamburger/HamburgerGestion'
import Accueil from '../../Components/Gestion/Accueil/Accueil'
import Companies from '../../Components/Gestion/Companies/Companies'
import Plaquette from '../../Components/Gestion/Plaquette/Plaquette'
import {
  useSelector
} from 'react-redux'
import {
  Route,
  Switch,
  Redirect,
  useLocation
} from 'react-router-dom'
import {
  routes
} from '../../Variables/Routes'

const RoutesGestion = () => {
  const location = useLocation()
  const device = useSelector(state => state.device)
  const Check = (path) => (
    routes.filter(item =>
      (item.gestion) &&
      (
        (
          (device.mobile) &&
          (item.mobile)
        ) ||
        (
          (device.desktop) &&
          (item.desktop)
        )
      )
    ).find(item => item.path === path)
  )
  return (location.pathname === '/error')
    ? <Error/>
    : <>
        <Bar/>
        <Switch>
          <Route exact path="/">
            <div className="flex-container">
              <HamburgerGestion active="accueil"/>
              {
                (Check('accueil'))
                  ? <Accueil/>
                  : <Empty/>
              }
            </div>
          </Route>
          <Route exact path="/accueil">
            <div className="flex-container">
              <HamburgerGestion active="accueil"/>
              {
                (Check('accueil'))
                  ? <Accueil/>
                  : <Empty/>
              }
            </div>
          </Route>
          <Route exact path="/companies">
            <div className="flex-container">
              <HamburgerGestion active="companies"/>
              {
                (Check('companies'))
                  ? <Companies/>
                  : <Empty/>
              }
            </div>
          </Route>
          <Route exact path="/users">
            <div className="flex-container">
              <HamburgerGestion active="users"/>
              {
                (Check('users'))
                  ? <Users/>
                  : <Empty/>
              }
            </div>
          </Route>
          <Route exact path="/codes">
            <div className="flex-container">
              <HamburgerGestion active="codes"/>
              {
                (Check('codes'))
                  ? <Codes/>
                  : <Empty/>
              }
            </div>
          </Route>
          <Route exact path="/mail">
            <div className="flex-container">
              <HamburgerGestion active="mail"/>
              {
                (Check('mail'))
                  ? <Mail/>
                  : <Empty/>
              }
            </div>
          </Route>
          <Route exact path="/plaquette">
            <div className="flex-container">
              <HamburgerGestion active="plaquette"/>
              {
                (Check('plaquette'))
                  ? <Plaquette/>
                  : <Empty/>
              }
            </div>
          </Route>
          <Route path="*">
            <Redirect to="/error"/>
          </Route>
        </Switch>
      </>
}

export default RoutesGestion
