import React from 'react'
import FilterReset from '../Includes/Filter/FilterReset'
import ButtonFilter from '../Includes/Button/ButtonFilter'
import AbsencesToolbarContent from './AbsencesToolbarContent'
import {
  useSelector
} from 'react-redux'
import {
  isDifferent
} from '../../Functions/Compare'
import {
  filtres
} from '../../Variables/Variables'

const AbsencesToolbarMobile = ({
  setPage,
  setFilter
}) => {
  const filters = useSelector(state => state.filters.pages)
  const absences = useSelector(state => state.absences.absences)
  const notification = useSelector(state => state.notifications.notification)
  return (
    <div className="toolbar-actions-mobile">
      <AbsencesToolbarContent/>
      {
        (absences.length > 0)
          ? (notification === '')
            ? <ButtonFilter
                onClick={() => setFilter(true)}
              />
            : <FilterReset notification={true}/>
          : null
      }
      {
        (isDifferent([filters.absences], [filtres.absences]))
          ? <FilterReset
              target="absences"
              callback={() => setPage(0)}
            />
          : null
      }
    </div>
  )
}

export default AbsencesToolbarMobile
