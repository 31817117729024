import {
  filtres
} from '../../Variables/Variables'

const initial = {
  salaries: [],
  pages: filtres
}

function filters(state = initial, action) {
  switch (action.type) {
    case 'SET_FILTER_SALARIES':
      return {
        ...state,
        salaries: action.value
      }
    case 'ADD_FILTER_SALARIE':
      return {
        ...state,
        salaries: [ ...state.salaries, action.value ]
      }
    case 'DEL_FILTER_SALARIE':
      return {
        ...state,
        salaries: state.salaries.filter(item => item !== action.value)
      }
    case 'SET_FILTERS_PAGE':
      return {
        ...state,
        pages: {
          ...state.pages,
          [action.page]: action.value
        }
      }
    case 'SET_FILTERS_PAGE_RESET':
      return {
        ...state,
        pages: {
          ...state.pages,
          [action.page]: initial.pages[action.page]
        }
      }
    case 'SET_FILTERS_PAGE_VALUE':
      return {
        ...state,
        pages: {
          ...state.pages,
          [action.page]: {
            ...state.pages[action.page],
            [action.filtre]: action.value
          }
        }
      }
    default:
      return state
  }
}

export default filters
