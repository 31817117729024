import React from 'react'
import ButtonRead from '../Button/ButtonRead'
import ButtonVoir from '../Button/ButtonVoir'
import TableCellField from './TableCellField'
import ActionSheet from '../Action/ActionSheet'
import ButtonDelete from '../Button/ButtonDelete'
import ButtonUnread from '../Button/ButtonUnread'
import TableCellContainer from './TableCellContainer'
import history from '../../../Navigation/Includes/History'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  READ_NOTIFICATION,
  DELETE_NOTIFICATION,
  SELECTED_NOTIFICATION
} from '../../../Redux/Actions/Notifications'

const TableCellActionsNotifications = ({
  item,
  device
}) => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.login.user)
  const heures = useSelector(state => state.heures.heures)
  const absences = useSelector(state => state.absences.absences)
  const indemnites = useSelector(state => state.indemnites.indemnites)
  const array = item.notification.split('-')
  const type = array[1] + 's'
  const Voir = (target, type) => {
    dispatch(SELECTED_NOTIFICATION(target))
    history.push(type)
  }
  const Read = (id) => {
    dispatch(READ_NOTIFICATION([id], user.unique_id_user, 1))
  }
  const Unread = (id) => {
    dispatch(READ_NOTIFICATION([id], user.unique_id_user, 0))
  }
  const Delete = (id) => {
    dispatch(DELETE_NOTIFICATION([id]))
  }
  const Existe = (id, type) => {
    if (id !== '') {
      switch (type) {
        case 'heures':
          return heures.find(item => item.id === id)
        case 'absences':
          return absences.find(item => item.id === id)
        case 'indemnites':
          return indemnites.find(item => item.id === id)
        default:
          return false
      }
    } else {
      return false
    }
  }
  return (
    <>
      {
        (device.mobile)
          ? <TableCellField>
              <ActionSheet
                options={[
                  {
                    id: 0,
                    label: 'Voir',
                    condition: Existe(item.target, type),
                    action: () => Voir(item.target, type)
                  },
                  {
                    id: 1,
                    label: 'Marquer comme non lu',
                    action: () => Unread(item.id),
                    condition: item.read.includes(user.unique_id_user)
                  },
                  {
                    id: 2,
                    label: 'Marquer comme lu',
                    action: () => Read(item.id),
                    condition: (item.read.indexOf(user.unique_id_user) < 0)
                  },
                  {
                    id: 3,
                    label: 'Supprimer',
                    action: () => Delete(item.id)
                  }
                ]}
              />
            </TableCellField>
          : <TableCellContainer width={3}>
              {
                (Existe(item.target, type))
                  ? <ButtonVoir
                      onClick={() => Voir(item.target, type)}
                    />
                  : null
              }
              {
                (item.read.includes(user.unique_id_user))
                  ? <ButtonUnread
                      onClick={() => Unread(item.id)}
                    />
                  : <ButtonRead
                      onClick={() => Read(item.id)}
                    />
              }
              <ButtonDelete
                onClick={() => Delete(item.id)}
              />
            </TableCellContainer>
      }
    </>
  )
}

export default TableCellActionsNotifications
