import thunk from 'redux-thunk'
import mail from '../Reducers/Mail'
import menu from '../Reducers/Menu'
import sort from '../Reducers/Sort'
import codes from '../Reducers/Codes'
import login from '../Reducers/Login'
import users from '../Reducers/Users'
import compte from '../Reducers/Compte'
import device from '../Reducers/Device'
import equipe from '../Reducers/Equipe'
import heures from '../Reducers/Heures'
import signin from '../Reducers/Signin'
import stripe from '../Reducers/Stripe'
import filters from '../Reducers/Filters'
import landing from '../Reducers/Landing'
import offices from '../Reducers/Offices'
import absences from '../Reducers/Absences'
import planning from '../Reducers/Planning'
import semaines from '../Reducers/Semaines'
import settings from '../Reducers/Settings'
import companies from '../Reducers/Companies'
import fonctions from '../Reducers/Fonctions'
import ouverture from '../Reducers/Ouverture'
import plaquette from '../Reducers/Plaquette'
import indemnites from '../Reducers/Indemnites'
import comptabilite from '../Reducers/Comptabilite'
import notifications from '../Reducers/Notifications'
import {
  applyMiddleware,
  combineReducers,
  legacy_createStore as createStore
} from 'redux'

const reducers = combineReducers({
  mail: mail,
  menu: menu,
  sort: sort,
  codes: codes,
  login: login,
  users: users,
  compte: compte,
  device: device,
  equipe: equipe,
  heures: heures,
  signin: signin,
  stripe: stripe,
  filters: filters,
  landing: landing,
  offices: offices,
  absences: absences,
  planning: planning,
  semaines: semaines,
  settings: settings,
  companies: companies,
  fonctions: fonctions,
  ouverture: ouverture,
  plaquette: plaquette,
  indemnites: indemnites,
  comptabilite: comptabilite,
  notifications: notifications
})

const root = (state, action) => {
  if (action.type === 'LOGOUT') {
    state = {
      device: state.device
    }
  }
  return reducers(state, action)
}

export default createStore(root, applyMiddleware(thunk))
