export const landing = {
  speed: 3000,
  nom: "Planipo",
  directions: [
    "left",
    "right"
  ],
  social: {
    discord: "https://discord.gg/7FpMAefn9T",
    facebook: "https://www.facebook.com/profile.php?id=100091451318889"
  },
  explain: [
    {
      icon: "euro",
      title: "Abordable",
      description: "Nous vous promettons le meilleur rapport qualité/prix possible."
    },
    {
      icon: "briefcase",
      title: "Professionel",
      description: "Notre outil a été mis au point en collaboration par un pharmacien, et un développeur."
    }
  ],
  faq: [
    {
      question: "Qu'est-ce qu'une semaine type ?",
      answer: "Une semaine type est une semaine que vous pouvez sauvegarder, modifier, dupliquer, renommer. Il vous suffit ensuite dans le planning, de sélectionner la semaine à insérer. De ce fait, vous générez un planning sur mesure en un clic."
    },
    {
      question: "Mes salariés peuvent-ils consulter leurs plannings ?",
      answer: "Chacun de vos salarié pourra se connecter sur notre portail. Ainsi, il peut récupérer lui-même son planning. Si vous le souhaitez, vous pouvez directement générer le planning de la semaine, et l'envoyer à chaque salarié dans sa boîte mail."
    },
    {
      question: "Est-ce que mes salariés peuvent déclarer des absences tous seuls ?",
      answer: "Oui, les salariés peuvent eux-mêmes déclarer leurs absences, leurs heures supplémentaires, ainsi que les indemnités kilométriques. Ces demandes peuvent être soumises à l'approbation d'un administrateur ou non, c'est vous qui choisissez. Vous pouvez aussi faire ces demandes en leur nom, si vous le souhaitez."
    },
    {
      question: "Est-ce forcément mon responsable qui doit faire le planning ?",
      answer: "Il y a 2 types de profils : administrateur et utilisateur. Ainsi, vous pouvez affecter les droits que vous souhaitez à qui vous voulez. L'administrateur n'est pas forcément le gérant, mais peut être par exemple un adjoint. Il peut également y avoir plusieurs admninistrateurs."
    },
    {
      question: "Puis-je changer d'abonnement ?",
      answer: "Vous pouvez changer quand vous voulez pour n'importe quel abonnement à partir du moment où vous remplissez les critères de ce dernier."
    },
    {
      question: "Quel type de paiement acceptez-vous ?",
      answer: "Le paiement sécurisé s'effectue directement lors de l'inscription par carte bancaire. Une fois que vous êtes inscrit, l'abonnement est mis en place."
    }
  ],
  features: [
    {
      icon: "planning",
      title: "Planning",
      description: "Alimenter votre planning au fil de l'eau en un clic."
    },
    {
      icon: "week",
      title: "Semaines",
      description: "Créez des semaines types, et insérez les dans votre planning."
    },
    {
      icon: "absences",
      title: "Absences",
      description: "Gérez les absences de vos employés, et visualisez les sur votre planning."
    },
    {
      icon: "heures",
      title: "Heures",
      description: "Gardez un oeil sur les heures supplémentaires."
    },
    {
      icon: "car",
      title: "Déplacements",
      description: "Déclarez les indémnités kilométriques de vos employés."
    },
    {
      icon: "chart",
      title: "Comptabilité",
      description: "Générez vos plannings et envoyez les directement à votre comptable."
    }
  ],
  about: [
    "Amis de longue date, #Brice# et #Thibaut# se sont rencontrés sur les bancs de l'école. À la fin du lycée, Thibaut se dirige vers la faculté de Bordeaux pour y suivre des études de #Pharmacie#, tandis que Brice s'oriente vers une école d'ingénieur en #Informatique#.",
    "Le temps passe, et Thibaut achète sa propre pharmacie, qu'il gère depuis plusieurs années... Brice développe plusieurs sites internet, et se perfectionne dans plusieurs languages de programmation.",
    "Au fil du temps, Thibaut perçoit le manque crucial #d'informatiser les plannings# au sein des officines en France : le bilan est clair, #aucune solution# ne répond actuellement au besoin. Nous sommes début 2020, et c'est à ce moment, que l'idée de développer un site internet qui répond à ce cahier des charges, leur vient.",
    "Cela fait donc plusieurs années que nous #travaillons coinjointement# pour vous proposer le meilleur compromis entre fonctionnalités et ergonomie. Notre outil ne propose pas seulement une gestion de planning comme n'importe quel tableau Excel le permet, nous avons mis en place toutes les #fonctionnalités essentielles# aux métiers de la santé. La passion commune de nos métiers est la clé de la réussite de #notre travail#.",
    "Depuis plus d'un an maintenant, #Planipo# est en production dans plusieurs entreprises.",
    "Nous avons #modifié#, #corrigé#, #amélioré# et #optimisé# encore notre site internet en fonction des nombreux #retours utilisateurs#. Aujourd'hui, nous vous proposons, force de notre expérience, un outil fonctionnel à #prix abordable# !",
    "N'hésitez pas à nous faire part de #vos impressions#, de #vos idées# pour continuer à améliorer notre service, ou bien à reporter d'éventuels bugs."
  ],
  legendes: {
    brice: "Brice, développeur depuis plus de 10 ans",
    thibaut: "Thibaut, pharmacien titulaire depuis 2011"
  }
}
