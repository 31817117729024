import React from 'react'

const UserArchive = ({
  name,
  archive
}) => {
  const planning = (archive)
    ? ''
    : ' et ne sera visible que sur le planning'
  const action = (archive)
    ? 'désarchiver'
    : 'archiver'
  const texte = (archive)
    ? 'pourra à nouveau'
    : 'ne pourra plus'
  return (
    <span>
      <span className="block">Voulez-vous vraiment {action} <span className="archive-name">{name}</span> ?</span>
      <span className="block">L'utilisateur {texte} se connecter{planning}.</span>
    </span>
  )
}

export default UserArchive
