import React from 'react'
import EquipeModal from './EquipeModal'
import dayjs from '../../Libraries/dayjs'
import EquipeContent from './EquipeContent'
import Root from '../Includes/Wrapper/Root'
import Alert from '../Includes/Dialog/Alert'
import UserReset from '../Includes/User/UserReset'
import UserDelete from '../Includes/User/UserDelete'
import UserArchive from '../Includes/User/UserArchive'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  get_heures
} from '../../Functions/Heures'
import {
  get_range_conges,
  get_conges_number_length
} from '../../Functions/Absences'
import {
  RESET_USER,
  DELETE_USER,
  ARCHIVE_USER,
  MODAL_EQUIPE
} from '../../Redux/Actions/Equipe'

const range = get_range_conges()

const Equipe = () => {
  const dispatch = useDispatch()
  const modal = useSelector(state => state.equipe.modal)
  const equipe = useSelector(state => state.equipe.equipe)
  const filters = useSelector(state => state.filters.pages)
  const absences = useSelector(state => state.absences.absences)
  const maximum = useSelector(state => state.compte.maximum.users)
  const fonctions = useSelector(state => state.fonctions.fonctions)
  const data = equipe.map(item => {
    let conges = 0
    absences.filter(object =>
      (dayjs.unix(object.debut).isSameOrBefore(range.done.fin)) &&
      (dayjs.unix(object.fin).isSameOrAfter(range.done.debut)) &&
      (object.user === item.id) &&
      (object.status === 1)
    ).forEach(object => {
      let nombre = object.nombre
      if (
        (dayjs.unix(object.debut).isBefore(range.done.debut)) &&
        (dayjs.unix(object.fin).isAfter(range.done.fin))
      ) {
        nombre = get_conges_number_length(
          range.done.debut,
          range.done.fin,
          object.duree
        )
      } else {
        if (dayjs.unix(object.debut).isBefore(range.done.debut)) {
          nombre = get_conges_number_length(
            range.done.debut,
            dayjs.unix(object.fin),
            object.duree
          )
        }
        if (dayjs.unix(object.fin).isAfter(range.done.fin)) {
          nombre = get_conges_number_length(
            dayjs.unix(object.debut),
            range.done.fin,
            object.duree
          )
        }
      }
      conges += nombre
    })
    return {
      ...item,
      temps: item.temps + get_heures(item.id),
      conges_remaining: item.conges_done - conges,
      metier: fonctions.find(object => object.id === item.fonction).fonction
    }
  }).filter(item =>
    (item.archive === filters.equipe.archive) &&
    ((item.profil === filters.equipe.profil) || (parseInt(filters.equipe.profil) === -1))
  )
  return (
    <Root container={true}>
      {
        (maximum !== -1)
          ? <EquipeModal data={data}/>
          : null
      }
      {
        (equipe.length > 0)
          ? <>
              <Alert
                titre="Confirmation"
                open={(modal.open === 'reset')}
                Cancel={() => dispatch(MODAL_EQUIPE('', ''))}
                Commit={() => {
                  dispatch(MODAL_EQUIPE('', ''))
                  dispatch(RESET_USER(modal.id))
                }}
                texte={
                  <UserReset
                    mot="lui"
                    name={modal.name}
                  />
                }
              />
              <Alert
                titre="Confirmation"
                open={(modal.open === 'delete')}
                Cancel={() => dispatch(MODAL_EQUIPE('', ''))}
                Commit={() => {
                  dispatch(MODAL_EQUIPE('', ''))
                  dispatch(DELETE_USER(modal.id))
                }}
                texte={
                  <UserDelete
                    equipe={true}
                    name={modal.name}
                  />
                }
              />
              <Alert
                titre="Confirmation"
                Cancel={() => dispatch(MODAL_EQUIPE('', ''))}
                open={(
                  (modal.open === 'unarchive') ||
                  (modal.open === 'archive')
                )}
                Commit={() => {
                  dispatch(MODAL_EQUIPE('', ''))
                  dispatch(ARCHIVE_USER(modal.id, (modal.open === 'archive') ? 1 : 0))
                }}
                texte={
                  <UserArchive
                    name={modal.name}
                    archive={(modal.open === 'unarchive')}
                  />
                }
              />
            </>
          : null
      }
      <EquipeContent
        data={data}
        total={equipe.length}
      />
    </Root>
  )
}

export default Equipe
