import {
  USER_LOGOUT
} from './Login'
import {
  api
} from '../../API/api'
import {
  print_error,
  update_toast,
  print_loading
} from '../../Functions/Toast'
import {
  get_id
} from '../../Functions/Functions'

export const MODAL_CODES = (value) => { // modale pour créer ou supprimer un code
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'MODAL_CODES'
    })
  }
}

export const SEARCH_CODE = (value) => { // recherche d'un code
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'SEARCH_CODE'
    })
  }
}

export const SELECTED_CODES = (value) => { // sélection des codes
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'SELECTED_CODES'
    })
  }
}

export const GET_CODES = () => { // récupération des codes en BDD
  return (dispatch) => {
    api('get-codes')
      .then(data => {
        if (data.status === 'OK') {
          dispatch({
            type: 'GET_CODES',
            value: data.response
          })
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          print_error(data.response)
        }
      })
      .catch(() => {
        print_error()
      })
  }
}

export const CREATE_CODE = (value) => { // création d'un ou plusieurs codes
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    api('create-code', {
      value: value
    })
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          dispatch(GET_CODES())
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}

export const DELETE_CODE = (codes) => { // suppression d'un ou plusieurs codes
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    api('delete-code', {
      codes: codes
    })
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          dispatch({
            value: codes,
            type: 'DELETE_CODE'
          })
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}
