import React from 'react'
import dayjs from '../../Libraries/dayjs'
import AbsenceModal from './AbsenceModal'
import Root from '../Includes/Wrapper/Root'
import Alert from '../Includes/Dialog/Alert'
import AbsencesContent from './AbsencesContent'
import AbsenceModalUser from './AbsenceModalUser'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  DELETE_ABSENCE,
  MODAL_ABSENCES
} from '../../Redux/Actions/Absences'

const Absences = () => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.login.user)
  const modal = useSelector(state => state.absences.modal)
  const equipe = useSelector(state => state.equipe.equipe)
  const filters = useSelector(state => state.filters.pages)
  const array = useSelector(state => state.absences.absences)
  const settings = useSelector(state => state.settings.variables)
  const fonctions = useSelector(state => state.fonctions.fonctions)
  const notification = useSelector(state => state.notifications.notification)
  const absences = array.filter(item =>
    (
      (user.profil === 'administrateur') ||
      (
        (item.user === user.unique_id_user) &&
        (user.profil !== 'administrateur')
      )
    )
  )
  const data = absences.map(item => {
    const user = equipe.find(object => object.id === item.user)
    const fonction = fonctions.find(object => object.id === user.fonction)
    return {
      ...item,
      nom: user.nom,
      prenom: user.prenom,
      nombre: item.nombre,
      archive: user.archive,
      fonction: fonction.fonction,
      duree: settings.absence.conges[item.duree],
      cause: settings.absence.motifs[item.motif]
    }
  }).filter(item => item.archive === 0).filter(item =>
    (notification === '')
      ? (
          ((filters.absences.dates[0] === null) || (dayjs.unix(item.debut).isSameOrAfter(filters.absences.dates[0]))) &&
          ((filters.absences.dates[1] === null) || (dayjs.unix(item.fin).isSameOrBefore(filters.absences.dates[1]))) &&
          ((item.status === filters.absences.status) || (parseInt(filters.absences.status) === -1)) &&
          ((item.user === filters.absences.salarie) || (parseInt(filters.absences.salarie) === -1)) &&
          ((item.motif === filters.absences.motif) || (parseInt(filters.absences.motif) === -1))
        )
      : (item.id === notification)
  )
  return (
    <Root container={true}>
      {
        (equipe.length > 0)
          ? <AbsenceModal/>
          : null
      }
      {
        (user.profil === 'utilisateur')
          ? <AbsenceModalUser/>
          : null
      }
      {
        (data.length > 0)
          ? <Alert
              titre="Confirmation"
              open={(modal.open === 'delete')}
              Cancel={() => dispatch(MODAL_ABSENCES('', ''))}
              texte="Voulez-vous vraiment supprimer cette absence ?"
              Commit={() => {
                dispatch(MODAL_ABSENCES('', ''))
                dispatch(DELETE_ABSENCE(modal.id))
              }}
            />
          : null
      }
      <AbsencesContent
        data={data}
        total={absences.length}
      />
    </Root>
  )
}

export default Absences
