import React from 'react'
import SemainesToolbarContent from './SemainesToolbarContent'
import {
  useSelector
} from 'react-redux'

const SemainesToolbarMobile = () => {
  const semaines = useSelector(state => state.semaines.semaines)
  const maximum = useSelector(state => state.compte.maximum.semaines)
  return (
    (maximum !== -1) &&
    (semaines.length < maximum)
  )
    ? <div className="toolbar-actions-mobile">
        <SemainesToolbarContent/>
      </div>
    : null
}

export default SemainesToolbarMobile
