import clsx from 'clsx'
import React from 'react'
import Form from '../Wrapper/Form'
import dayjs from '../../../Libraries/dayjs'
import ErrorMessage from '../Misc/ErrorMessage'
import styles from './ToolbarWizardContent.module.css'
import DateRangePicker from '../Picker/DateRangePicker'
import {
  useSelector
} from 'react-redux'
import {
  Badge,
  Button
} from '@mui/material'
import {
  is_day
} from '../../../Functions/Absences'

const ToolbarWizardContent = ({
  error,
  state,
  Update,
  UpdateError
}) => {
  const device = useSelector(state => state.device)
  const semaines = useSelector(state => state.semaines.semaines)
  const weeks = [
    ...state.weeks.map(item => semaines.find(week => week.id === item)),
    ...semaines.filter(week => state.weeks.indexOf(week.id) < 0)
  ]
  const Toggle = (id) => {
    const remove = state.weeks.includes(id)
    Update('weeks',
      (remove)
        ? state.weeks.filter(item => item !== id)
        : [ ...state.weeks, id ]
    )
    if (!remove) {
      UpdateError('weeks', false)
    }
  }
  const Disable = (date, position) => {
    if (position === 'start') {
      return !is_day(date, 0)
    }
    if (position === 'end') {
      return (
        !is_day(date, 6) ||
        dayjs(state.dates[0]).isAfter(date) ||
        dayjs(date).isAfter(dayjs(state.dates[0]).add(1, 'year'))
      )
    }
    return false
  }
  return (
    <Form full={true}>
      <div className={styles.explain}>Sélectionnez les semaines à insérer dans l'ordre, puis la plage de dates (lundi au dimanche).</div>
      <div
        className={
          clsx({
            [styles.container]: true,
            [styles.mobile]: device.mobile
          })
        }
      >
        {
          weeks.map(item => {
            const index = state.weeks.findIndex(week => week === item.id) + 1
            return (
              <Badge
                key={item.id}
                color="primary"
                badgeContent={index}
              >
                <Button
                  variant="outlined"
                  className={styles.week}
                  onClick={() => Toggle(item.id)}
                  color={
                    (index > 0)
                      ? "primary"
                      : "error"
                  }
                >
                  {item.nom}
                </Button>
              </Badge>
            )
          })
        }
      </div>
      {
        (error.weeks)
          ? <ErrorMessage>Merci de sélectionner au moins une semaine</ErrorMessage>
          : null
      }
      <DateRangePicker
        disablePast={true}
        value={state.dates}
        shouldDisableDate={Disable}
        onAccept={() => UpdateError('dates', false)}
        slotProps={{
          fieldSeparator: {
            className: 'picker-separator-dates'
          }
        }}
        onChange={(value) => {
          Update('dates', [
            value[0],
            (value[1] !== null)
              ? value[1]
              : value[0]
          ])
        }}
      />
      {
        (error.dates)
          ? <ErrorMessage>Merci de renseigner une plage de dates valide</ErrorMessage>
          : null
      }
    </Form>
  )
}

export default ToolbarWizardContent
