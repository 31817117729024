import dayjs from '../../Libraries/dayjs'
import {
  week_date
} from '../../Functions/Dates'
import {
  get_creneau_object
} from '../../Functions/Planning'
import {
  get_id
} from '../../Functions/Functions'

const initial = {
  day: 0,
  data: [],
  current: [],
  erreurs: [],
  semaines: [],
  commit: false,
  date: week_date,
  nom: {
    value: '',
    error: false
  },
  rename: {
    id: '',
    nom: '',
    open: false,
    duplicate: false
  },
  working: {
    id: '',
    nom: '',
    new: false,
    edit: false,
    open: false,
    read: false
  }
}

function semaines(state = initial, action) {
  switch (action.type) {
    case 'SEMAINE_NOM':
      return {
        ...state,
        nom: action.value
      }
    case 'SAVE_SEMAINE':
      return {
        ...state,
        commit: action.value
      }
    case 'GET_SEMAINES':
      return {
        ...state,
        semaines: action.value
      }
    case 'SET_SEMAINE_DAY':
      return {
        ...state,
        day: action.value
      }
    case 'SET_SEMAINE_DATA':
      return {
        ...state,
        data: action.value
      }
    case 'SET_DATE_SEMAINES':
      return {
        ...state,
        date: dayjs(action.value)
      }
    case 'SET_SEMAINE_RENAME':
      return {
        ...state,
        rename: action.value
      }
    case 'SET_SEMAINE_ERREURS':
      return {
        ...state,
        erreurs: action.value
      }
    case 'SET_SEMAINE_WORKING':
      return {
        ...state,
        working: action.value
      }
    case 'EDIT_CURRENT_SEMAINE':
      return {
        ...state,
        current: action.value
      }
    case 'DEL_CRENEAU_SEMAINE':
      return {
        ...state,
        current: state.current.filter(item => item.id !== action.value)
      }
    case 'DELETE_SEMAINE':
      return {
        ...state,
        semaines: state.semaines.filter(item => item.id !== action.value)
      }
    case 'ADD_CRENEAU_SEMAINE':
      return {
        ...state,
        current: [
          ...state.current,
          ...action.value
        ]
      }
    case 'EDIT_SEMAINE':
      return {
        ...state,
        semaines: state.semaines.map(item => {
          return (item.id === action.value.id)
            ? action.value
            : item
        })
      }
    case 'CLONE_SEMAINE':
      return {
        ...state,
        semaines: [
          ...state.semaines,
          {
            ...action.value
          }
        ]
      }
    case 'CREATE_SEMAINE':
      return {
        ...state,
        semaines: [
          ...state.semaines,
          {
            ...action.value,
            creneaux: state.current
          }
        ]
      }
    case 'CLONE_CRENEAU_SEMAINE':
      return {
        ...state,
        current: [
          ...state.current,
          {
            ...state.current.find(item => item.id === action.value),
            id: get_id()
          }
        ]
      }
    case 'RENAME_SEMAINE':
      return {
        ...state,
        semaines: state.semaines.map(item => {
          return (item.id === action.value.id)
            ? {
                ...item,
                nom: action.value.nom
              }
            : item
        })
      }
    case 'EMPTY_CRENEAU_SEMAINE':
      return {
        ...state,
        current: state.current.map(item => {
          return (item.id === action.value)
            ? {
                ...item,
                equipe: []
              }
            : item
        })
      }
    case 'DEL_CRENEAU_SEMAINE_USER':
      return {
        ...state,
        current: state.current.map(item => {
          return (item.id === action.value.creneau)
            ? {
                ...item,
                equipe: item.equipe.filter(item => item !== action.value.user)
              }
            : item
        })
      }
    case 'SET_CRENEAU_SEMAINE_EQUIPE':
      return {
        ...state,
        current: state.current.map(item => {
          return (item.id === action.value.creneau)
            ? {
                ...item,
                equipe: action.value.equipe
              }
            : item
        })
      }
    case 'UPDATE_CRENEAU_SEMAINE_HORAIRES':
      const value = get_creneau_object(action.value, 'iso')
      return {
        ...state,
        current: state.current.map(item => {
          return (item.id === value.id)
            ? {
                ...item,
                endDate: value.endDate,
                startDate: value.startDate
              }
            : item
        })
      }
    case 'ADD_CRENEAU_SEMAINE_USER':
      return {
        ...state,
        current: state.current.map(item => {
          return (item.id === action.value.creneau)
            ? {
                ...item,
                equipe: (item.equipe.includes(action.value.user))
                  ? item.equipe
                  : [
                      ...item.equipe,
                      action.value.user
                    ]
              }
            : item
        })
      }
    default:
      return state
  }
}

export default semaines
