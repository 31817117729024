import React from 'react'
import Bar from '../Bar/Bar'
import Compte from '../../Components/Compte/Compte'
import Error from '../../Components/Navigation/Error'
import Planning from '../../Components/Planning/Planning'
import {
  useSelector
} from 'react-redux'
import {
  Route,
  Switch,
  Redirect,
  useLocation
} from 'react-router-dom'

const RoutesOffice = () => {
  const location = useLocation()
  const die = useSelector(state => state.login.die)
  return (location.pathname === '/error')
    ? <Error/>
    : (die)
      ? <>
          <Bar/>
          <Switch>
            <Route exact path="/compte">
              <div className="flex-container">
                <Compte/>
              </div>
            </Route>
            <Route path="*">
              <Redirect to="/compte"/>
            </Route>
          </Switch>
        </>
      : <>
          <Bar/>
          <Switch>
            <Route exact path="/">
              <div className="flex-container">
                <Planning/>
              </div>
            </Route>
            <Route exact path="/accueil">
              <div className="flex-container">
                <Planning/>
              </div>
            </Route>
            <Route path="*">
              <Redirect to="/error"/>
            </Route>
          </Switch>
        </>
}

export default RoutesOffice
