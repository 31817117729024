import React from 'react'
import Planning from '../Hooks/Planning'
import ButtonClose from '../Button/ButtonClose'
import styles from './LegendeCreneau.module.css'
import LegendeCreneauDate from './LegendeCreneauDate'
import LegendeCreneauModal from './LegendeCreneauModal'
import LegendeCreneauDelete from './LegendeCreneauDelete'
import CreneauFonctions from '../Creneau/CreneauFonctions'
import LegendeCreneauContent from './LegendeCreneauContent'
import LegendeCreneauToolbar from './LegendeCreneauToolbar'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  Paper
} from '@mui/material'
import {
  sort_name
} from '../../../Functions/Sort'
import {
  first_day_format
} from '../../../Functions/Dates'
import {
  SELECTED_CRENEAU
} from '../../../Redux/Actions/Planning'

const LegendeCreneau = ({ fonctions }) => {
  let data = []
  let liste = []
  const planning = Planning()
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false)
  const team = useSelector(state => state.equipe.equipe)
  const date = useSelector(state => state.planning.date)
  const event = useSelector(state => state.planning.event)
  const filter = useSelector(state => state.filters.salaries)
  const absents = useSelector(state => state.planning.absents)
  const current = useSelector(state => state.semaines.current)
  const creneaux = useSelector(state => state.planning.planning)
  if (planning) {
    data = creneaux.filter(item => item.week === first_day_format(date)).map(item => item.creneaux).flat().find(item => item.id === event.id)
  } else {
    data = current.find(item => item.id === event.id)
  }
  const equipe = (data !== undefined)
    ? data.equipe
    : []
  const checked = equipe.filter(item => filter.indexOf(item) < 0)
  liste = team.filter(item =>
    (checked.includes(item.id)) &&
    (equipe.includes(item.id))
  )
  liste = sort_name(liste).map(item => {
    const absence = absents.find(object =>
      (object.id === item.id) &&
      (object.creneau === data.id)
    )
    return {
      ...item,
      heure: (data.heure && (data.sens === 1)),
      status: (absence)
        ? absence.status
        : undefined,
      absence: (absence)
        ? absence.absence
        : undefined,
      absent: (absents.filter(object => object.creneau === data.id).map(object => object.id).includes(item.id))
        ? 1
        : 0
    }
  })
  return (
    (data !== undefined) &&
    (event.id !== '')
  )
    ? <>
        {
          (planning)
            ? <LegendeCreneauModal
                open={open}
                id={event.id}
                setOpen={setOpen}
              />
            : null
        }
        <Paper className={styles.container}>
          {
            (event.read)
              ? <div className={styles.alone}>
                  <ButtonClose
                    onClick={() => {
                      dispatch(SELECTED_CRENEAU({
                        id: '',
                        read: false
                      }))
                    }}
                  />
                </div>
              : <LegendeCreneauToolbar
                  equipe={equipe}
                  setOpen={setOpen}
                />
          }
          <LegendeCreneauDate data={data}/>
          {
            (equipe.length > 0)
              ? <>
                  {
                    (fonctions)
                      ? <>
                          <div
                            className={
                              (liste.length > 0)
                                ? styles.margin
                                : styles.empty
                            }
                          />
                          <CreneauFonctions liste={liste}/>
                        </>
                      : <div
                          className={
                            (liste.length > 0)
                              ? styles.empty
                              : styles.vide
                          }
                        />
                  }
                  <LegendeCreneauContent
                    data={data}
                    liste={liste}
                  />
                </>
              : <div
                  className={
                    (event.read)
                      ? styles.vide
                      : styles.empty
                  }
                />
          }
          {
            (!event.read)
              ? <div
                  className={
                    (
                      (planning) &&
                      (liste.length === 0)
                    )
                      ? styles.separate
                      : styles.divider
                  }
                />
              : null
          }
          {
            (!event.read)
              ? <LegendeCreneauDelete/>
              : null
          }
        </Paper>
      </>
    : null
}

export default LegendeCreneau
