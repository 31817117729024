import React from 'react'
import Alert from '../Dialog/Alert'
import Planning from '../Hooks/Planning'
import styles from './LegendeCreneauDelete.module.css'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  Button
} from '@mui/material'
import {
  Delete
} from '@mui/icons-material'
import {
  SELECTED_CRENEAU,
  DEL_CRENEAU_PLANNING
} from '../../../Redux/Actions/Planning'
import {
  DEL_CRENEAU_SEMAINE
} from '../../../Redux/Actions/Semaines'

const LegendeCreneauDelete = () => {
  const planning = Planning()
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false)
  const user = useSelector(state => state.login.user)
  const event = useSelector(state => state.planning.event)
  return (
    <>
      {
        (user.profil === 'administrateur')
          ? <Alert
              open={open}
              titre="Confirmation"
              Cancel={() => setOpen(false)}
              texte="Voulez-vous vraiment supprimer ce créneau ?"
              Commit={() => {
                setOpen(false)
                dispatch(SELECTED_CRENEAU({
                  id: '',
                  read: false
                }))
                if (planning) {
                  dispatch(DEL_CRENEAU_PLANNING(event.id))
                } else {
                  dispatch(DEL_CRENEAU_SEMAINE(event.id))
                }
              }}
            />
          : null
      }
      <div className={styles.buttons}>
        <Button
          color="secondary"
          variant="contained"
          startIcon={<Delete/>}
          onClick={() => setOpen(true)}
        >
          Supprimer le créneau
        </Button>
      </div>
    </>
  )
}

export default LegendeCreneauDelete
