import '../../Variables/Types.js'
import {
  USER_LOGOUT
} from './Login'
import {
  api
} from '../../API/api'
import {
  print_error,
  update_toast,
  print_loading
} from '../../Functions/Toast'
import {
  get_id
} from '../../Functions/Functions'

export const SET_SEMAINE_DATA = (value) => {
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'SET_SEMAINE_DATA'
    })
  }
}

export const EDIT_CURRENT_SEMAINE = (value) => {
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'EDIT_CURRENT_SEMAINE'
    })
  }
}

/**
 * Mise à jour du nom de la semaine dans la toolbar (lors de la modification).
 * 
 * @param {Object} value Détails de l'objet.
 * @param {string} value.value Nom de la semaine.
 * @param {boolean} value.error Est-ce que le nom est valide.
 * @returns {Function} Une fonction redux thunk.
 */
export const SEMAINE_NOM = (value) => {
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'SEMAINE_NOM'
    })
  }
}

/**
 * L'utilisateur sauvegarde la semaine.
 * 
 * @param {boolean} value Lance l'action de sauvegarde.
 * @returns {Function} Une fonction redux thunk.
 */
export const SAVE_SEMAINE = (value) => {
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'SAVE_SEMAINE'
    })
  }
}

/**
 * Définit le numéro du jour de la semaine.
 * 
 * @param {number} value Numéro du jour de la semaine.
 * @returns {Function} Une fonction redux thunk.
 */
export const SET_SEMAINE_DAY = (value) => {
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'SET_SEMAINE_DAY'
    })
  }
}

/**
 * Date sélectionnée dans la semaine.
 * 
 * @param {Date|Dayjs} value Date sélectionnée.
 * @returns {Function} Une fonction redux thunk.
 */
export const SET_DATE_SEMAINES = (value) => {
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'SET_DATE_SEMAINES'
    })
  }
}

/**
 * Mise à jour du nom de la semaine (dans la popup de renommage).
 * 
 * @param {Object} value Détails de l'objet.
 * @param {string} value.id ID de la semaine.
 * @param {string} value.nom Le nouveau nom de la semaine.
 * @param {boolean} value.open Indique si la popup est ouverte.
 * @param {boolean} value.duplicate Est-ce qu'on effectue un clonage ou un renommage.
 * @returns {Function} Une fonction redux thunk.
 */
export const SET_SEMAINE_RENAME = (value) => {
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'SET_SEMAINE_RENAME'
    })
  }
}

/**
 * Ajout de créneau(x) dans la semaine.
 * 
 * @param {Array<Creneau>} value Liste des créneaux.
 * @returns {Function} Une fonction redux thunk.
 */
export const ADD_CRENEAU_SEMAINE = (value) => {
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'ADD_CRENEAU_SEMAINE'
    })
  }
}

/**
 * Suppression d'un créneau dans la semaine.
 * 
 * @param {string} value ID du créneau.
 * @returns {Function} Une fonction redux thunk.
 */
export const DEL_CRENEAU_SEMAINE = (value) => {
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'DEL_CRENEAU_SEMAINE'
    })
  }
}

/**
 * Vérification des erreurs dans la semaine (salarié dans deux créneaux en même temps).
 * 
 * @param {array} value Liste des erreurs.
 * @param {string} value[].user Salarié concerné.
 * @param {Array<{start: number, end: number}>} value[].creneaux Les deux créneaux qui se chevauchent.
 * @param {number} value[].creneaux.start Timestamp de début.
 * @param {number} value[].creneaux.end Timestamp de fin.
 * @returns {Function} Une fonction redux thunk.
 */
export const SET_SEMAINE_ERREURS = (value) => {
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'SET_SEMAINE_ERREURS'
    })
  }
}

/**
 * Définit l'état/l'affichage de la page semaine.
 * 
 * @param {Object} value Détails de l'objet.
 * @param {string} value.id ID de la semaine sélectionnée.
 * @param {string} value.nom Nom de la semaine sélectionnée.
 * @param {boolean} value.new Est-ce qu'on travaille sur une nouvelle semaine.
 * @param {boolean} value.edit Sommes-nous en train de modifier une semaine.
 * @param {boolean} value.open Est-ce qu'on travaille sur une semaine (ou bien nous sommes sur l'affichage de la liste des semaines).
 * @param {boolean} value.read On regarde une semaine en lecture seule.
 * @returns {Function} Une fonction redux thunk.
 */
export const SET_SEMAINE_WORKING = (value) => {
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'SET_SEMAINE_WORKING'
    })
  }
}

/**
 * Clonage d'un créneau dans la semaine.
 * 
 * @param {string} value ID du créneau.
 * @returns {Function} Une fonction redux thunk.
 */
export const CLONE_CRENEAU_SEMAINE = (value) => {
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'CLONE_CRENEAU_SEMAINE'
    })
  }
}

/**
 * Vide les salariés d'un créneau dans la semaine.
 * 
 * @param {string} value ID du créneau.
 * @returns {Function} Une fonction redux thunk.
 */
export const EMPTY_CRENEAU_SEMAINE = (value) => {
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'EMPTY_CRENEAU_SEMAINE'
    })
  }
}

/**
 * Ajout d'un salarié dans le créneau de la semaine.
 * 
 * @param {Object} value Détails de l'objet.
 * @param {string} value.user ID du salarié.
 * @param {string} value.creneau ID du créneau.
 * @returns {Function} Une fonction redux thunk.
 */
export const ADD_CRENEAU_SEMAINE_USER = (value) => {
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'ADD_CRENEAU_SEMAINE_USER'
    })
  }
}

/**
 * Suppression d'un salarié dans le créneau de la semaine.
 * 
 * @param {Object} value Détails de l'objet.
 * @param {string} value.user ID du salarié.
 * @param {string} value.creneau ID du créneau.
 * @returns {Function} Une fonction redux thunk.
 */
export const DEL_CRENEAU_SEMAINE_USER = (value) => {
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'DEL_CRENEAU_SEMAINE_USER'
    })
  }
}

/**
 * Définit les salariés dans le créneau de la semaine.
 * 
 * @param {Object} value Détails de l'objet.
 * @param {string} value.creneau ID du créneau.
 * @param {Array<string>} value[].equipe Salariés du créneau.
 * @returns {Function} Une fonction redux thunk.
 */
export const SET_CRENEAU_SEMAINE_EQUIPE = (value) => {
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'SET_CRENEAU_SEMAINE_EQUIPE'
    })
  }
}

/**
 * Modifie les horaires d'un créneau dans la semaine.
 * 
 * @param {Horaires} value Détails de l'objet.
 * @returns {Function} Une fonction redux thunk.
 */
export const UPDATE_CRENEAU_SEMAINE_HORAIRES = (value) => {
  return (dispatch) => {
    dispatch({
      value: value,
      type: 'UPDATE_CRENEAU_SEMAINE_HORAIRES'
    })
  }
}

/**
 * Récupération des semaines en BDD.
 * 
 * @returns {Function} Une fonction redux thunk ou un message d'erreur.
 */
export const GET_SEMAINES = () => {
  return (dispatch) => {
    api('get-semaines')
      .then(data => {
        if (data.status === 'OK') {
          dispatch({
            value: data.response,
            type: 'GET_SEMAINES'
          })
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          print_error(data.response)
        }
      })
      .catch(() => {
        print_error()
      })
  }
}

/**
  * Modification d'une semaine.
  * 
  * @param {Object} value Détails de l'objet.
  * @param {string} value.id ID de la semaine.
  * @param {string} value.nom Nom de la semaine.
  * @param {Array<Creneau>} value.creneaux Les créneaux de la semaine.
  * @returns {Function} Une fonction redux thunk ou un message de succès/d'erreur.
  */
export const EDIT_SEMAINE = (value) => {
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    api('edit-semaine', {
      value: value
    })
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          dispatch({
            value: value,
            type: 'EDIT_SEMAINE'
          })
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}

/**
  * Clonage d'une semaine.
  * 
  * @param {Object} value Détails de l'objet.
  * @param {string} value.id ID de la semaine.
  * @param {string} value.nom Nom de la semaine.
  * @param {Array<Creneau>} value.creneaux Les créneaux de la semaine.
  * @returns {Function} Une fonction redux thunk ou un message de succès/d'erreur.
  */
export const CLONE_SEMAINE = (value) => {
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    api('clone-semaine', {
      value: value
    })
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          dispatch({
            value: value,
            type: 'CLONE_SEMAINE'
          })
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}

/**
  * Création d'une semaine.
  * 
  * @param {Object} value Détails de l'objet.
  * @param {string} value.id ID de la semaine.
  * @param {string} value.nom Nom de la semaine.
  * @param {Array<Creneau>} value.creneaux Les créneaux de la semaine.
  * @returns {Function} Une fonction redux thunk ou un message de succès/d'erreur.
  */
export const CREATE_SEMAINE = (value) => {
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    api('create-semaine', {
      value: value
    })
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          dispatch({
            value: value,
            type: 'CREATE_SEMAINE'
          })
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}

/**
 * Suppression d'une semaine.
 * 
 * @param {string} value ID de la semaine.
 * @returns {Function} Une fonction redux thunk ou un message de succès/d'erreur.
 */
export const DELETE_SEMAINE = (value) => {
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    api('delete-semaine', {
      value: value
    })
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          dispatch({
            value: value,
            type: 'DELETE_SEMAINE'
          })
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}

/**
 * Renommage d'une semaine.
 * 
 * @param {Object} value Détails de l'objet.
 * @param {string} value.id ID de la semaine.
 * @param {string} value.nom Nom de la semaine.
 * @returns {Function} Une fonction redux thunk ou un message de succès/d'erreur.
 */
export const RENAME_SEMAINE = (value) => {
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    api('rename-semaine', {
      value: value
    })
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          dispatch({
            value: value,
            type: 'RENAME_SEMAINE'
          })
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}
