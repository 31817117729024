const initial = {
  heures: [],
  modal: {
    id: '',
    open: '',
    data: {},
    nombre: true
  }
}

function heures(state = initial, action) {
  switch (action.type) {
    case 'INIT_HEURES':
      return {
        ...state,
        heures: action.value
      }
    case 'DELETE_HEURE':
      return {
        ...state,
        heures: state.heures.filter(item => item.id !== action.value)
      }
    case 'DELETE_USER_HEURES':
      return {
        ...state,
        heures: state.heures.filter(item => item.user !== action.value)
      }
    case 'CREATE_HEURE':
      return {
        ...state,
        heures: [
          ...state.heures,
          action.value
        ]
      }
    case 'MODAL_HEURES':
      return {
        ...state,
        modal: {
          id: action.id,
          data: action.data,
          open: action.open,
          nombre: action.nombre
        }
      }
    case 'UPDATE_HEURE':
      return {
        ...state,
        heures: state.heures.map(item => {
          return (item.id === action.id)
            ? {
                ...item,
                status: action.value
              }
            : item
        })
      }
    case 'UPDATE_HEURE_PLANNING_HORAIRES':
      return {
        ...state,
        heures: state.heures.map(item => {
          return (item.id === action.value.id)
            ? {
                ...item,
                fin: action.value.endDate,
                debut: action.value.startDate
              }
            : item
        })
      }
    case 'EDIT_HEURE':
      return {
        ...state,
        heures: state.heures.map(item => {
          return (item.id === action.value.id)
            ? {
                ...item,
                fin: action.value.fin,
                sens: action.value.sens,
                user: action.value.user,
                debut: action.value.debut,
                texte: action.value.texte,
                motif: action.value.motif,
                nombre: action.value.nombre,
                recuperation: action.value.recuperation
              }
            : item
        })
      }
    default:
      return state
  }
}

export default heures
