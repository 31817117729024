import React from 'react'
import Alert from '../Dialog/Alert'
import ButtonEdit from '../Button/ButtonEdit'
import ButtonVoir from '../Button/ButtonVoir'
import TableCellField from './TableCellField'
import ActionSheet from '../Action/ActionSheet'
import ButtonDelete from '../Button/ButtonDelete'
import ButtonRename from '../Button/ButtonRename'
import TableCellContainer from './TableCellContainer'
import ButtonDuplicate from '../Button/ButtonDuplicate'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  DELETE_SEMAINE,
  SET_SEMAINE_DATA,
  SET_SEMAINE_RENAME,
  SET_SEMAINE_WORKING,
  EDIT_CURRENT_SEMAINE
} from '../../../Redux/Actions/Semaines'

const TableCellActionsSemaines = ({
  item,
  device
}) => {
  const dispatch = useDispatch()
  const equipe = useSelector(state => state.equipe.equipe)
  const filter = useSelector(state => state.filters.salaries)
  const semaines = useSelector(state => state.semaines.semaines)
  const maximum = useSelector(state => state.compte.maximum.semaines)
  const checked = equipe.map(item => item.id).filter(item => filter.indexOf(item) < 0)
  const [remove, setRemove] = React.useState({
    id: '',
    open: false
  })
  const Delete = (id) => {
    setRemove({
      id: id,
      open: true
    })
  }
  const Rename = (item) => {
    dispatch(SET_SEMAINE_RENAME({
      open: true,
      id: item.id,
      nom: item.nom,
      duplicate: false
    }))
  }
  const Duplicate = (item) => {
    dispatch(SET_SEMAINE_RENAME({
      open: true,
      id: item.id,
      nom: item.nom,
      duplicate: true
    }))
  }
  const Edit = (id) => {
    const semaine = semaines.find(item => item.id === id)
    dispatch(EDIT_CURRENT_SEMAINE(semaine.creneaux))
    dispatch(SET_SEMAINE_WORKING({
      new: false,
      edit: true,
      open: true,
      read: false,
      id: semaine.id,
      nom: semaine.nom
    }))
  }
  const Voir = (id) => {
    const semaine = semaines.find(item => item.id === id)
    dispatch(EDIT_CURRENT_SEMAINE(semaine.creneaux))
    dispatch(SET_SEMAINE_WORKING({
      new: false,
      open: true,
      read: true,
      edit: false,
      id: semaine.id,
      nom: semaine.nom
    }))
    dispatch(SET_SEMAINE_DATA(semaine.creneaux.map(item => {
      return {
        ...item,
        empty: (item.equipe.length === 0),
        equipe: item.equipe.filter(item => checked.includes(item))
      }
    }).filter(item =>
      (item.empty) ||
      (item.equipe.length > 0)
    )))
  }
  return (
    <>
      <Alert
        open={remove.open}
        titre="Confirmation"
        texte="Voulez-vous vraiment supprimer cette semaine ?"
        Cancel={() => {
          setRemove({
            id: '',
            open: false
          })
        }}
        Commit={() => {
          dispatch(DELETE_SEMAINE(remove.id))
          setRemove({
            id: '',
            open: false
          })
        }}
      />
      {
        (device.mobile)
          ? <TableCellField>
              <ActionSheet
                options={[
                  {
                    id: 0,
                    label: 'Voir',
                    action: () => Voir(item.id)
                  },
                  {
                    id: 1,
                    label: 'Modifier',
                    action: () => Edit(item.id)
                  },
                  {
                    id: 2,
                    label: 'Dupliquer',
                    action: () => Duplicate(item),
                    condition: (semaines.length < maximum)
                  },
                  {
                    id: 3,
                    label: 'Renommer',
                    action: () => Rename(item)
                  },
                  {
                    id: 4,
                    label: 'Supprimer',
                    action: () => Delete(item.id)
                  }
                ]}
              />
            </TableCellField>
          : <TableCellContainer width={5}>
              <ButtonVoir
                onClick={() => Voir(item.id)}
              />
              <ButtonEdit
                onClick={() => Edit(item.id)}
              />
              {
                (semaines.length < maximum)
                  ? <ButtonDuplicate
                      onClick={() => Duplicate(item)}
                    />
                  : null
              }
              <ButtonRename
                onClick={() => Rename(item)}
              />
              <ButtonDelete
                onClick={() => Delete(item.id)}
              />
            </TableCellContainer>
      }
    </>
  )
}

export default TableCellActionsSemaines
