import React from 'react'
import Planning from '../Hooks/Planning'
import {
  useDrag
} from 'react-dnd'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  TableRow
} from '@mui/material'
import {
  first_day_format
} from '../../../Functions/Dates'
import {
  ADD_CRENEAU_PLANNING_USER
} from '../../../Redux/Actions/Planning'
import {
  ADD_CRENEAU_SEMAINE_USER
} from '../../../Redux/Actions/Semaines'

const EventDrag = ({
  id,
  children,
  ...others
}) => {
  let liste = []
  const planning = Planning()
  const dispatch = useDispatch()
  const team = useSelector(state => state.equipe.equipe)
  const date = useSelector(state => state.planning.date)
  const event = useSelector(state => state.planning.event)
  const filter = useSelector(state => state.filters.salaries)
  const semaine = useSelector(state => state.semaines.current)
  const creneaux = useSelector(state => state.planning.planning)
  const selected = team.map(item => item.id).filter(item => filter.indexOf(item) < 0)
  if (planning) {
    liste = creneaux.filter(item => item.week === first_day_format(date)).map(item => item.creneaux).flat().find(item => item.id === event.id)
  }
  const current = (semaine.length > 0)
    ? semaine.find(item => item.id === event.id)
    : undefined
  const array = (current !== undefined)
    ? (current.equipe !== undefined)
      ? current.equipe
      : []
    : []
  const equipe = (liste !== undefined)
    ? (liste.equipe !== undefined)
      ? liste.equipe
      : []
    : []
  const clickable = (
    (equipe.indexOf(id) < 0) &&
    (array.indexOf(id) < 0) &&
    (event.heure !== true) &&
    (event.id !== '')
  )
  const [{ isDragging }, drag] = useDrag(() => ({
    type: id,
    item: {
      id
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId()
    }),
    end: (item, monitor) => {
      const result = monitor.getDropResult()
      if (item && result) {
        const data = {
          user: item.id,
          creneau: result.id
        }
        if (planning) {
          dispatch(ADD_CRENEAU_PLANNING_USER(data))
        } else {
          dispatch(ADD_CRENEAU_SEMAINE_USER(data))
        }
      }
    }
  }))
  return (
    <TableRow
      ref={drag}
      hover={clickable}
      onClick={() => {
        if (clickable) {
          const data = {
            user: id,
            creneau: event.id
          }
          if (planning) {
            dispatch(ADD_CRENEAU_PLANNING_USER(data))
          } else {
            dispatch(ADD_CRENEAU_SEMAINE_USER(data))
          }
        }
      }}
      style={{
        background: (
          (equipe.includes(id)) ||
          (array.includes(id)) ||
          (isDragging)
        )
          ? 'rgba(0, 0, 0, 0.04)'
          : undefined,
        cursor: (event.id !== '')
          ? (event.heure === true)
            ? 'default'
            : (
                (equipe.includes(id)) ||
                (array.includes(id))
              )
                ? 'default'
                : 'pointer'
          : (selected.indexOf(id) < 0)
            ? 'default'
            : 'move'
      }}
      {...others}
    >
      {children}
    </TableRow>
  )
}

export default EventDrag
