import React from 'react'
import Info from '../Includes/Wrapper/Info'
import styles from './Maintenance.module.css'
import {
  landing
} from '../../Variables/Landing'
import {
  config
} from '../../Variables/Variables'

const Maintenance = () => {
  return (
    <Info icon="cone">
      <div>Nous sommes désolés,</div>
      <div><b>{landing.nom}</b> est actuellement en <b>maintenance</b>.</div>
      <div>Le service devrait être rétabli le :</div>
      <div className={styles.date}>
        {config.fin.replace(' ', ' à ').replace(':', 'h')}
      </div>
    </Info>
  )
}

export default Maintenance
