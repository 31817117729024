import clsx from 'clsx'
import dayjs from '../../Libraries/dayjs'
import Planning from '../Includes/Hooks/Planning'
import PlanningModalCreneauPaper from './PlanningModalCreneauPaper'
import PlanningModalCreneauUsers from './PlanningModalCreneauUsers'
import PlanningModalCreneauDelete from './PlanningModalCreneauDelete'
import React, {
  useEffect
} from 'react'
import {
  useSelector
} from 'react-redux'
import {
  first_day_format
} from '../../Functions/Dates'
import {
  isDifferent
} from '../../Functions/Compare'
import {
  get_id
} from '../../Functions/Functions'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from '../Includes/Dialog/Dialog'

const PlanningModalCreneau = ({
  open,
  Commit,
  setOpen
}) => {
  let data = []
  const planning = Planning()
  const date = useSelector(state => state.planning.date)
  const event = useSelector(state => state.planning.event)
  const equipe = useSelector(state => state.equipe.equipe)
  const current = useSelector(state => state.semaines.current)
  const creneaux = useSelector(state => state.planning.planning)
  const team = equipe.filter(item => item.archive === 0).map(item => item.id)
  const [state, setState] = React.useState({
    users: [],
    excludes: [],
    error: false,
    classe: undefined,
    dates: [
      dayjs(),
      dayjs()
    ]
  })
  const Update = (item, value) => {
    setState(current => ({
      ...current,
      [item]: value
    }))
  }
  if (planning) {
    data = creneaux.filter(item => item.week === first_day_format(date)).map(item => item.creneaux).flat().find(item => item.id === event.id)
  } else {
    data = current.find(item => item.id === event.id)
  }
  const title = (event.read)
    ? 'Voir'
    : 'Modifier'
  useEffect(() => {
    Update('classe', (
      (
        (state.error) ||
        (state.dates[0].isSameOrAfter(state.dates[1]))
      )
        ? 'modal-creneau-error'
        : undefined
    ))
    // eslint-disable-next-line
  }, [state.dates, state.error])
  useEffect(() => {
    let inner = []
    let outer = []
    if (data !== undefined) {
      Update('dates', [
        dayjs(data.startDate),
        dayjs(data.endDate)
      ])
      equipe.forEach(item => {
        if (data.equipe.includes(item.id)) {
          inner.push(item)
        } else if (team.includes(item.id)) {
          outer.push(item)
        }
      })
      Update('error', false)
      Update('users', inner)
      Update('excludes', outer)
      Update('classe', undefined)
    }
    // eslint-disable-next-line
  }, [open])
  return (event.id !== '')
    ? <Dialog
        open={open}
        maxWidth="md"
        fullWidth={true}
        className={
          clsx({
            'modal-mobile-creneau': true,
            'modal-mobile-heure': data.heure
          })
        }
      >
        <DialogTitle>
          {
            (data.heure)
              ? title + " l'heure"
              : title + " le créneau"
          }
          <PlanningModalCreneauDelete/>
        </DialogTitle>
        <DialogContent>
          <PlanningModalCreneauPaper
            data={data}
            state={state}
            Update={Update}
          />
          <PlanningModalCreneauUsers
            data={data}
            state={state}
            Update={Update}
          />
        </DialogContent>
        <DialogActions
          buttons={[
            {
              onClick: () => setOpen()
            },
            {
              hidden: event.read,
              onClick: () => {
                if (state.classe === undefined) {
                  setOpen()
                  const creneau = {
                    ...data,
                    equipe: data.equipe.sort()
                  }
                  const value = {
                    id: event.id,
                    heure: data.heure,
                    endDate: state.dates[1].toDate(),
                    startDate: state.dates[0].toDate(),
                    equipe: state.users.map(item => item.id).sort()
                  }
                  if (isDifferent([value], [creneau])) {
                    Commit({
                      ...value,
                      semaine: get_id()
                    })
                  }
                }
              }
            }
          ]}
        />
      </Dialog>
    : null
}

export default PlanningModalCreneau
