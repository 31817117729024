import {
  READY_PAGE
} from './Menu'
import {
  USER_LOGOUT,
  SUCCESS_LOGIN
} from './Login'
import {
  api
} from '../../API/api'
import {
  print_error
} from '../../Functions/Toast'
import {
  redirection
} from '../../Functions/Functions'

export const USER_BACK = () => { // récupération des données utilisateur (si l'utilisateur actualise la page)
  return (dispatch) => {
    api('user-back')
      .then(data => {
        if (data.status === 'OK') {
          const url = redirection(data.response.beta)
          if (url !== null) {
            window.location.assign(url)
          }
          dispatch({
            type: 'SET_USER',
            value: data.response
          })
          dispatch({
            type: 'SET_DIE',
            value: data.response.die
          })
          dispatch({
            type: 'SET_CARD',
            value: data.response.card
          })
          dispatch({
            type: 'SET_STRIPE',
            value: data.response.stripe
          })
          dispatch({
            type: 'SET_COMPANIE',
            value: data.response.companie
          })
          dispatch({
            type: 'SET_FACTURES',
            value: data.response.factures
          })
          dispatch({
            type: 'SET_VARIABLES',
            value: data.response.settings
          })
          dispatch({
            type: 'SET_ABONNEMENT',
            value: data.response.abonnement
          })
          if (data.response.die === false) {
            dispatch(SUCCESS_LOGIN(data.response.profil))
          }
          dispatch(READY_PAGE(true))
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          print_error(data.response)
        }
      })
      .catch(() => {
        print_error()
      })
  }
}
