import {
  USER_LOGOUT
} from './Login'
import {
  api
} from '../../API/api'
import {
  update_toast,
  print_loading
} from '../../Functions/Toast'
import {
  get_id
} from '../../Functions/Functions'

export const DELETE_OUVERTURE = () => { // suppression des horaires d'ouverture de l'entreprise
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    api('delete-ouverture')
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          dispatch({
            value: '',
            type: 'INIT_OUVERTURE'
          })
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}

export const UPDATE_OUVERTURE = (value) => { // mise à jour des horaires d'ouverture de l'entreprise
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    api('update-ouverture', {
      value: value
    })
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          dispatch({
            value: value,
            type: 'INIT_OUVERTURE'
          })
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}
