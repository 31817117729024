import React from 'react'
import ButtonGestion from './ButtonGestion'

const ButtonGestionUnblock = (props) => {
  return (
    <ButtonGestion
      color="green"
      icon="LockOpen"
      title="Débloquer"
      {...props}
    />
  )
}

export default ButtonGestionUnblock
