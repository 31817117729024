import {
  check_token
} from './token'
import {
  messages
} from '../Variables/Messages'
import {
  variables
} from '../Variables/Variables'

export async function api(action, body = undefined, bearer = true) {
  const check = (bearer) ? await check_token() : true
  if (check) {
    let headers = new Headers()
    headers.append('Accept', 'application/json')
    headers.append('Content-Type', 'application/json')
    if (bearer) {
      headers.append('Authorization', 'Bearer ' + localStorage.getItem('token'))
    }
    const config = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(body)
    }
    const target = (bearer)
      ? variables.page.private
      : variables.page.public
    const response = await fetch(variables.url.server + variables.path.api + target + '/' + action, config)
    return await response.json()
  } else {
    return messages.error.access
  }
}
