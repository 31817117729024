import {
  api
} from '../../API/api'
import {
  print_error
} from '../../Functions/Toast'

export const GET_LANDING = () => { // récupération des informations publiques (types d'entreprises, profils d'utilisateurs et abonnements)
  return (dispatch) => {
    api('get-landing', undefined, false)
      .then(data => {
        if (data.status === 'OK') {
          dispatch({
            type: 'SET_TYPES',
            value: data.response.types
          })
          dispatch({
            type: 'SET_PROFILS',
            value: data.response.profils
          })
          dispatch({
            type: 'SET_ABONNEMENTS',
            value: data.response.abonnements
          })
        } else {
          print_error(data.response)
        }
      })
      .catch(() => {
        print_error()
      })
  }
}
