const initial = {
  disabled: false,
  offices: [],
  modal: {
    id: '',
    name: '',
    open: ''
  }
}

function offices(state = initial, action) {
  switch (action.type) {
    case 'INIT_OFFICES':
      return {
        ...state,
        offices: action.value
      }
    case 'SET_OFFICE_DISABLED':
      return {
        ...state,
        disabled: action.value
      }
    case 'DELETE_OFFICE':
      return {
        ...state,
        offices: state.offices.filter(item => item.id !== action.value)
      }
    case 'CREATE_OFFICE':
      return {
        ...state,
        offices: [
          ...state.offices,
          action.value
        ]
      }
    case 'MODAL_OFFICES':
      return {
        ...state,
        modal: {
          id: action.id,
          name: action.name,
          open: action.open
        }
      }
    default:
      return state
  }
}

export default offices
