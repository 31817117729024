const initial = {
  user: [],
  die: false,
  pending: false
}

function login(state = initial, action) {
  switch (action.type) {
    case 'SET_DIE':
      return {
        ...state,
        die: action.value
      }
    case 'SET_PENDING':
      return {
        ...state,
        pending: action.value
      }
    case 'SET_USER':
      return {
        ...state,
        user: {
          logged: true,
          ...action.value
        }
      }
    case 'USER_MAIL':
      return {
        ...state,
        user: {
          ...state.user,
          mail: action.value
        }
      }
    case 'FIRST_LOGIN':
      return {
        ...state,
        user: {
          ...state.user,
          first: false
        }
      }
    default:
      return state
  }
}

export default login
