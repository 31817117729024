import React from 'react'
import Search from './Search'
import Checkbox from '../Misc/Checkbox'
import styles from './SearchMobile.module.css'
import {
  Toolbar
} from '@mui/material'

const SearchMobile = ({
  data,
  search,
  onChange,
  selected,
  setSearch
}) => {
  return (
    <Toolbar className={styles.toolbar}>
      <Search
        search={search}
        setSearch={setSearch}
        full={(data.length === 0)}
      />
      {
        (data.length > 0)
          ? <Checkbox
              onChange={onChange}
              className={styles.checkbox}
              checked={(
                (data.length > 0) &&
                (selected.length === data.length)
              )}
              indeterminate={(
                (selected.length > 0) &&
                (selected.length < data.length)
              )}
            />
          : null
      }
    </Toolbar>
  )
}

export default SearchMobile
