import clsx from 'clsx'
import React from 'react'
import styles from './Empty.module.css'
import {
  messages
} from '../../Variables/Messages'

const Empty = ({ margin }) => (
  <div
    className={
      clsx({
        [styles.error]: true,
        [styles.margin]: (margin !== false)
      })
    }
  >
    {messages.error.die}
  </div>
)

export default Empty
