import {
  USER_LOGOUT
} from './Login'
import {
  api
} from '../../API/api'
import {
  update_toast,
  print_loading
} from '../../Functions/Toast'
import {
  get_id
} from '../../Functions/Functions'

export const UPDATE_SETTING = (value) => { // mise à jour du paramètre de l'entreprise
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    api(value.action, {
      value: value.value
    })
      .then(data => {
        if (data.status === 'OK') {
          dispatch({
            value: value,
            type: 'UPDATE_SETTING'
          })
          if (value.id === 'survol') {
            dispatch({
              value: value.value,
              type: 'SET_SURVOL'
            })
          }
          if (value.id === 'companie') {
            dispatch({
              value: value.value,
              type: 'SET_COMPANIE'
            })
          }
          update_toast(toast, data.response, 'success')
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}
