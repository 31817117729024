import React from 'react'
import dayjs from './Libraries/dayjs'
import Routes from './Navigation/Routes/Routes'
import Maintenance from './Components/Navigation/Maintenance'
import {
  useSelector
} from 'react-redux'
import {
  config
} from './Variables/Variables'

const Content = () => {
  const device = useSelector(state => state.device)
  return (
    <div
      className={
        (device.mobile)
          ? 'root-mobile'
          : 'root-desktop'
      }
    >
      {
        (
          dayjs().isSameOrAfter(dayjs(config.debut, 'DD/MM/YYYY HH:mm')) &&
          dayjs().isSameOrBefore(dayjs(config.fin, 'DD/MM/YYYY HH:mm'))
        )
          ? <Maintenance/>
          : <Routes/>
      }
    </div>
  )
}

export default Content
