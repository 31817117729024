import Holidays from 'date-holidays'
import dayjs from '../Libraries/dayjs'
import store from '../Redux/Store/Store'
import {
  variables
} from '../Variables/Variables'

export const holiday = new Holidays('FR')

export function is_day(date, number) {
  return (dayjs(date, 'DD/MM/YYYY').weekday() === number)
}

export function is_holiday(date) {
  return (holiday.isHoliday(dayjs(date, 'DD/MM/YYYY')) !== false)
}

export function get_days_business(dates) {
  return dates.filter(item =>
    (!is_holiday(item)) &&
    (!is_day(item, 6))
  )
}

export function get_conges_number_length(debut, fin, duree) {
  const nombre = get_days_business(get_days(debut, fin)).length
  return (duree !== 2)
    ? nombre * 0.5
    : nombre
}

export function get_days(start, end) {
  let dates = []
  while (start.isSameOrBefore(end)) {
    dates.push(start.format('DD/MM/YYYY'))
    start = start.add(1, 'days')
  }
  return dates
}

export function get_array_holidays(date, length = 'week') {
  let array = []
  let end = dayjs(date).endOf(length)
  let start = dayjs(date).startOf(length)
  while (start.isSameOrBefore(end)) {
    array.push(is_holiday(start))
    start = start.add(1, 'days')
  }
  return array
}

export function get_debut(date, duree, plus = false) {
  const value = (plus)
    ? dayjs(date)
    : dayjs.unix(date)
  switch (duree) {
    case 0:
      return value.startOf('day').unix()
    case 1:
      return value.startOf('day').unix() + variables.absence.duree[1]
    case 2:
      return value.startOf('day').unix()
    default:
      return
  }
}

export function get_fin(date, duree, plus = false) {
  const value = (plus)
    ? dayjs(date)
    : dayjs.unix(date)
  switch (duree) {
    case 0:
      return value.startOf('day').unix() + variables.absence.duree[0]
    case 1:
      return value.endOf('day').unix()
    case 2:
      return value.endOf('day').unix()
    default:
      return
  }
}

export function get_duree_absence(absence) {
  const end = variables.absence.fin
  const milieu = variables.absence.milieu
  const fin = (absence.fin.heures * 3600) + (absence.fin.minutes * 60)
  const debut = (absence.debut.heures * 3600) + (absence.debut.minutes * 60)
  if (
    (debut >= 0) &&
    (fin <= milieu)
  ) {
    return 0
  } else if (
    (debut > milieu) &&
    (fin <= end)
  ) {
    return 1
  } else {
    return 2
  }
}

export function get_range_conges() {
  const now = dayjs()
  const conges = store.getState().compte.conges
  const debut = dayjs('01-' + conges + '-' + now.year(), 'DD-MM-YYYY')
  if (now.isBefore(debut)) {
    return {
      done: {
        debut: debut.subtract(2, 'years'),
        fin: debut.subtract(1, 'years').subtract(1, 'days').endOf('day')
      },
      current: {
        debut: debut.subtract(1, 'years'),
        fin: debut.subtract(1, 'days').endOf('day')
      }
    }
  } else {
    return {
      done: {
        debut: debut.subtract(1, 'years'),
        fin: debut.subtract(1, 'days').endOf('day')
      },
      current: {
        debut: debut,
        fin: debut.add(1, 'years').subtract(1, 'days').endOf('day')
      }
    }
  }
}
