import Page from '../Includes/Wrapper/Page'
import Planning from '../Includes/Wrapper/Planning'
import PlanningScheduler from './PlanningScheduler'
import LegendePlanning from '../Includes/Legende/LegendePlanning'
import React, {
  useEffect
} from 'react'
import {
  DndProvider
} from 'react-dnd'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  first_day_format
} from '../../Functions/Dates'
import {
  HTML5Backend
} from 'react-dnd-html5-backend'
import {
  UPDATE_PLANNING
} from '../../Redux/Actions/Planning'

const PlanningContent = () => {
  const dispatch = useDispatch()
  const device = useSelector(state => state.device)
  const date = useSelector(state => state.planning.date)
  const event = useSelector(state => state.planning.event)
  const equipe = useSelector(state => state.equipe.equipe)
  const filter = useSelector(state => state.filters.salaries)
  const absences = useSelector(state => state.absences.absences)
  const planning = useSelector(state => state.planning.planning)
  const semaines = useSelector(state => state.semaines.semaines)
  const semaine = planning.filter(item => item.week === first_day_format(date))
  const checked = equipe.map(item => item.id).filter(item => filter.indexOf(item) < 0)
  const data = semaine.map(item => item.creneaux).flat().map(item => {
    return {
      ...item,
      empty: (item.equipe.length === 0),
      equipe: item.equipe.filter(item => checked.includes(item))
    }
  }).filter(item =>
    (item.empty) ||
    (item.equipe.length > 0)
  )
  useEffect(() => {
    if (
      (absences.length > 0) &&
      (planning.length > 0)
    ) {
      dispatch(UPDATE_PLANNING())
    }
    // eslint-disable-next-line
  }, [absences, planning])
  return (
    <DndProvider backend={HTML5Backend}>
      <Page>
        <Planning>
          <PlanningScheduler data={data}/>
        </Planning>
        {
          (
            (device.desktop) &&
            (
              (semaines.length > 0) ||
              (equipe.length > 0) ||
              (event.id !== '')
            )
          )
            ? <LegendePlanning vides={data.filter(item => item.empty)}/>
            : null
        }
      </Page>
    </DndProvider>
  )
}

export default PlanningContent
