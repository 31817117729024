const initial = {
  menu: false,
  ready: false
}

function menu(state = initial, action) {
  switch (action.type) {
    case 'MENU_OPEN':
      return {
        ...state,
        menu: action.value
      }
    case 'READY_PAGE':
      return {
        ...state,
        ready: action.value
      }
    default:
      return state
  }
}

export default menu
