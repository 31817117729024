import React from 'react'
import ButtonGestion from './ButtonGestion'

const ButtonGestionDelete = (props) => {
  return (
    <ButtonGestion
      color="red"
      title="Supprimer"
      icon="DeleteOutline"
      {...props}
    />
  )
}

export default ButtonGestionDelete
