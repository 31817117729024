import RoutesUser from './RoutesUser'
import RoutesAdmin from './RoutesAdmin'
import RoutesLogin from './RoutesLogin'
import RoutesOffice from './RoutesOffice'
import RoutesGestion from './RoutesGestion'
import Newsletter from '../../Components/Navigation/Newsletter'
import React, {
  useEffect
} from 'react'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  check_token
} from '../../API/token'
import {
  useLocation
} from 'react-router-dom'
import {
  READY_PAGE
} from '../../Redux/Actions/Menu'
import {
  USER_BACK
} from '../../Redux/Actions/User'

const Routes = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const user = useSelector(state => state.login.user)
  const ready = useSelector(state => state.menu.ready)
  const Login = async() => {
    const check = await check_token()
    if (check) {
      dispatch(USER_BACK())
    } else {
      dispatch(READY_PAGE(true))
    }
  }
  useEffect(() => {
    Login()
    // eslint-disable-next-line
  }, [])
  return (ready)
    ? (location.pathname.startsWith('/newsletter'))
      ? <Newsletter/>
      : (user.logged)
        ? (user.profil === 'gestionnaire')
          ? <RoutesGestion/>
          : (user.profil === 'administrateur')
            ? <RoutesAdmin/>
            : (user.profil === 'utilisateur')
              ? <RoutesUser/>
              : (user.profil === 'office')
                ? <RoutesOffice/>
                : null
        : <RoutesLogin/>
    : null
}

export default Routes
