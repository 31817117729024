import React from 'react'
import Bar from '../Bar/Bar'
import Aide from '../../Components/Aide/Aide'
import About from '../../Components/About/About'
import Compte from '../../Components/Compte/Compte'
import Equipe from '../../Components/Equipe/Equipe'
import Heures from '../../Components/Heures/Heures'
import Empty from '../../Components/Navigation/Empty'
import Error from '../../Components/Navigation/Error'
import Offices from '../../Components/Offices/Offices'
import Accueil from '../../Components/Accueil/Accueil'
import HamburgerAdmin from '../Hamburger/HamburgerAdmin'
import Absences from '../../Components/Absences/Absences'
import Planning from '../../Components/Planning/Planning'
import Semaines from '../../Components/Semaines/Semaines'
import Settings from '../../Components/Settings/Settings'
import Fonctions from '../../Components/Fonctions/Fonctions'
import FirstLogin from '../../Components/Navigation/FirstLogin'
import Indemnites from '../../Components/Indemnites/Indemnites'
import Comptabilite from '../../Components/Comptabilite/Comptabilite'
import Notifications from '../../Components/Notifications/Notifications'
import AbsencesPlanning from '../../Components/AbsencesPlanning/AbsencesPlanning'
import {
  useSelector
} from 'react-redux'
import {
  Route,
  Switch,
  Redirect,
  useLocation
} from 'react-router-dom'
import {
  routes
} from '../../Variables/Routes'

const RoutesAdmin = () => {
  const location = useLocation()
  const die = useSelector(state => state.login.die)
  const device = useSelector(state => state.device)
  const Check = (path) => (
    routes.filter(item =>
      (item.admin) &&
      (
        (
          (device.mobile) &&
          (item.mobile)
        ) ||
        (
          (device.desktop) &&
          (item.desktop)
        )
      )
    ).find(item => item.path === path)
  )
  return (location.pathname === '/error')
    ? <Error/>
    : (die)
      ? <>
          <Bar/>
          <Switch>
            <Route exact path="/compte">
              <div className="flex-container">
                <Compte/>
              </div>
            </Route>
            <Route path="*">
              <Redirect to="/compte"/>
            </Route>
          </Switch>
        </>
      : <>
          <Bar/>
          <Switch>
            <Route exact path="/">
              <div className="flex-container">
                <HamburgerAdmin active="accueil"/>
                {
                  (Check('accueil'))
                    ? <Accueil/>
                    : <Empty/>
                }
              </div>
            </Route>
            <Route exact path="/accueil">
              <div className="flex-container">
                <HamburgerAdmin active="accueil"/>
                {
                  (Check('accueil'))
                    ? <Accueil/>
                    : <Empty/>
                }
              </div>
            </Route>
            <Route exact path="/planning">
              <div className="flex-container">
                <HamburgerAdmin active="planning"/>
                {
                  (Check('planning'))
                    ? <Planning/>
                    : <Empty/>
                }
              </div>
            </Route>
            <Route exact path="/semaines">
              <div className="flex-container">
                <HamburgerAdmin active="semaines"/>
                {
                  (Check('semaines'))
                    ? <Semaines/>
                    : <Empty/>
                }
              </div>
            </Route>
            <Route exact path="/comptabilite">
              <div className="flex-container">
                <HamburgerAdmin active="comptabilite"/>
                {
                  (Check('comptabilite'))
                    ? <Comptabilite/>
                    : <Empty/>
                }
              </div>
            </Route>
            <Route exact path="/absences">
              <div className="flex-container">
                <HamburgerAdmin active="absences"/>
                {
                  (Check('absences'))
                    ? <Absences/>
                    : <Empty/>
                }
              </div>
            </Route>
            <Route exact path="/absences-planning">
              <div className="flex-container">
                <HamburgerAdmin active="absences-planning"/>
                {
                  (Check('absences-planning'))
                    ? <AbsencesPlanning/>
                    : <Empty/>
                }
              </div>
            </Route>
            <Route exact path="/heures">
              <div className="flex-container">
                <HamburgerAdmin active="heures"/>
                {
                  (Check('heures'))
                    ? <Heures/>
                    : <Empty/>
                }
              </div>
            </Route>
            <Route exact path="/indemnites">
              <div className="flex-container">
                <HamburgerAdmin active="indemnites"/>
                {
                  (Check('indemnites'))
                    ? <Indemnites/>
                    : <Empty/>
                }
              </div>
            </Route>
            <Route exact path="/fonctions">
              <div className="flex-container">
                <HamburgerAdmin active="fonctions"/>
                {
                  (Check('fonctions'))
                    ? <Fonctions/>
                    : <Empty/>
                }
              </div>
            </Route>
            <Route exact path="/equipe">
              <div className="flex-container">
                <HamburgerAdmin active="equipe"/>
                {
                  (Check('equipe'))
                    ? <Equipe/>
                    : <Empty/>
                }
              </div>
            </Route>
            <Route exact path="/offices">
              <div className="flex-container">
                <HamburgerAdmin active="offices"/>
                {
                  (Check('offices'))
                    ? <Offices/>
                    : <Empty/>
                }
              </div>
            </Route>
            <Route exact path="/parametres">
              <div className="flex-container">
                <HamburgerAdmin active="parametres"/>
                {
                  (Check('parametres'))
                    ? <Settings/>
                    : <Empty/>
                }
              </div>
            </Route>
            <Route exact path="/aide">
              <div className="flex-container">
                <HamburgerAdmin active="aide"/>
                {
                  (Check('aide'))
                    ? <Aide/>
                    : <Empty/>
                }
              </div>
            </Route>
            <Route exact path="/about">
              <div className="flex-container">
                <HamburgerAdmin active="about"/>
                {
                  (Check('about'))
                    ? <About/>
                    : <Empty/>
                }
              </div>
            </Route>
            <Route exact path="/notifications">
              <div className="flex-container">
                <HamburgerAdmin active="notifications"/>
                {
                  (Check('notifications'))
                    ? <Notifications/>
                    : <Empty/>
                }
              </div>
            </Route>
            <Route exact path="/compte">
              <div className="flex-container">
                <HamburgerAdmin active="compte"/>
                {
                  (Check('compte'))
                    ? <Compte/>
                    : <Empty/>
                }
              </div>
            </Route>
            <Route path="*">
              <Redirect to="/error"/>
            </Route>
          </Switch>
          <FirstLogin/>
        </>
}

export default RoutesAdmin
